import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Card, Typography, Button, Row } from 'antd';
import {
  UnorderedListOutlined,
  DownloadOutlined,
  CustomerServiceOutlined
} from '@ant-design/icons';

import CommonTable from '../../components/CommonTable';

import { formattedColumns } from '../../utils/commonUtils';
import { fileColumns } from '../../constants';

import * as fileListActions from '../../actions/fileListActions';
import AssignFile from '../../components/AssignFile';

const { Text } = Typography;

const FileList = ({
  user,
  getAllFiles,
  getUserFiles,
  handleDownload,
  files,
  handleSupport,
  isDetailPage,
  currentUserId
}) => {
  useEffect(() => {
    async function loadFiles() {
      if (isDetailPage) {
        getUserFiles(currentUserId);
      } else {
        getAllFiles();
      }
    }
    loadFiles();
  }, [getAllFiles, getUserFiles]);

  const renderColumnActions = () => {
    return {
      render: (text, record) => (
        <>
          <Row style={{ marginTop: '1vh' }}>
            <Button
              block
              type='primary'
              icon={<CustomerServiceOutlined />}
              onClick={() => handleSupport(record)}
            >
              Support
            </Button>
          </Row>
          <Row style={{ marginTop: '1vh' }}>
            <Button
              block
              type='primary'
              icon={<DownloadOutlined />}
              onClick={() => handleDownload(record)}
            >
              Download
            </Button>
          </Row>
          {user.role === 'user' ? null : (
            <Row style={{ marginTop: '1vh' }}>
              <AssignFile record={record} />
            </Row>
          )}
        </>
      )
    };
  };
  return (
    <Card
      title={
        <Text strong>
          <UnorderedListOutlined /> List Files
        </Text>
      }
      style={{ minHeight: '77vh' }}
    >
      <CommonTable
        dataSource={files}
        columns={formattedColumns(fileColumns, { ...renderColumnActions() })}
        rowSelection={null}
      />
    </Card>
  );
};

const mapStateToProps = state => {
  return {
    user: state.auth.user,
    files: state.fileList.files
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getAllFiles: () => dispatch(fileListActions.getAllFiles()),
    getUserFiles: currentUserId =>
      dispatch(fileListActions.getUserFiles(currentUserId)),
    handleDownload: record => dispatch(fileListActions.handleDownload(record)),
    handleSupport: record => dispatch(fileListActions.handleSupport(record))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(FileList);
