import { useEffect } from 'react';
import { Route, Switch } from 'react-router-dom';

import ProtectedRoute from '../../views/ProtectedRoute';
import Account from '../../views/Account';
import Home from '../../views/Home';
import Login from '../../views/Login';
import Register from '../../views/Register';

import * as authActions from '../../actions/authActions';
import { connect } from 'react-redux';

const AppContent = ({ isAuthenticated, checkUserLogin, pathname, logout }) => {
  useEffect(() => {
    checkUserLogin(pathname);
  }, []);
  return (
    <Switch>
      <Route path='/' exact component={Home} />
      <Route path='/login' component={Login} />
      <Route path='/register' component={Register} />
      <ProtectedRoute
        isAuthenticated={isAuthenticated}
        path={pathname}
        logout={logout}
        component={Account}
      />
      <Route path='*'>
        <div>404 Not found </div>
      </Route>
    </Switch>
  );
};

const mapStateToPorops = state => {
  return {
    isAuthenticated: state.auth.isAuthenticated,
    pathname: state.router.location.pathname,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    logout: () => dispatch(authActions.logout()),
    checkUserLogin: pathname => dispatch(authActions.checkUserLogin(pathname)),
  };
};

export default connect(mapStateToPorops, mapDispatchToProps)(AppContent);
