import finalConfig from '../../config/config';
import { postRequest, getRequest, putRequest } from '../client/client';
import { refreshTokenService } from './commonService';

export const createCredit = async credit => {
  const url = `${finalConfig.SHOP_ITEMS}`;
  const data = credit;
  let result;
  result = await postRequest(url, data);
  if (result.status === 401) {
    await refreshTokenService();
    result = await postRequest(url, data);
  }
  return result;
};

export const getAllCredits = async () => {
  const url = `${finalConfig.SHOP_ITEMS}`;
  let result;
  result = await getRequest(url);
  if (result.status === 401) {
    await refreshTokenService();
    result = await getRequest(url);
  }
  return result;
};

export const buyCredit = async (shopItemId, ip) => {
  const url = `${finalConfig.CREDIT}`;
  const data = { shopItemId, ip };
  let result;
  result = await postRequest(url, data);
  if (result.status === 401) {
    await refreshTokenService();
    result = await postRequest(url, data);
  }
  return result;
};

export const getCredits = async () => {
  const url = `${finalConfig.SHOP_ITEMS}`;
  let result;
  result = await getRequest(url);
  if (result.status === 401) {
    await refreshTokenService();
    result = await getRequest(url);
  }
  return result;
};

export const checkUserDiscount = async () => {
  const url = `${finalConfig.CHECK_USER_DISCOUNT}`;
  let result;
  result = await getRequest(url);
  if (result.status === 401) {
    await refreshTokenService();
    result = await getRequest(url);
  }
  return result;
};

export const update = async (credit, id) => {
  const url = `${finalConfig.SHOP_ITEMS}/${id}`;
  let result;
  result = await putRequest(url, credit);
  if (result.status === 401) {
    await refreshTokenService();
    result = await putRequest(url, credit);
  }
  return result;
};
