import { insert, update } from '../api/service/vehicleService';
import { setSelectedVehicle } from './vehicleListActions';
import { setSelectedMenuKey } from './sidebarActions';
import { logout } from './authActions';

export const ADD_VEHICLE_SUCCESS = 'ADD_VEHICLE_SUCCESS';
export const ADD_VEHICLE_FAIL = 'ADD_VEHICLE_FAIL';

export const addVehicleSucess = () => {
  return { type: ADD_VEHICLE_SUCCESS };
};
export const updateVehicleSucess = () => {
  return { type: ADD_VEHICLE_SUCCESS };
};

export const addVehicleFail = errMessage => {
  return { type: ADD_VEHICLE_FAIL, payload: { errMessage } };
};

export const handleCancel = () => {
  return dispatch => {
    dispatch(setSelectedVehicle(null));
    dispatch(setSelectedMenuKey('1'));
  };
};

export const addVehicle = vehicle => {
  return async dispatch => {
    const result = await insert(vehicle);
    if (result.status === 401) {
      dispatch(logout());
    } else if (result.status < 400) {
      dispatch(addVehicleSucess());
      dispatch(setSelectedMenuKey('1'));
    } else {
      dispatch(addVehicleFail(result?.data?.message));
    }
  };
};

export const updateVehicle = (vehicle, id) => {
  return async dispatch => {
    const result = await update(vehicle, id);
    if (result.status === 401) {
      dispatch(logout());
    } else {
      dispatch(updateVehicleSucess());
      dispatch(setSelectedMenuKey('1'));
    }
  };
};
