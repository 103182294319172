import React from 'react';
import { Select } from 'antd';

const { Option } = Select;

const ChangeMapperPriority = ({ onChangeMapperPriority }) => {
  return (
    <Select
      style={{ width: '100%' }}
      onChange={value => onChangeMapperPriority(value)}
      placeholder='Status'
    >
      <Option key={'NORMAL'} value={'NORMAL'}>
        NORMAL
      </Option>
      <Option key={'PRIOR'} value={'PRIOR'}>
        PRIOR
      </Option>
    </Select>
  );
};

export default ChangeMapperPriority;
