import * as notifService from '../api/service/notifService';
import { logout } from './authActions';
import * as fileListActions from './fileListActions';
import * as userActions from './userActions';

export const SET_NOTIFICATIONS = 'SET_NOTIFICATIONS';
export const REMOVE_SEEN_NOTIFICATION = 'REMOVE_SEEN_NOTIFICATION';

export const setNotifications = notifs => {
  return { type: SET_NOTIFICATIONS, payload: { notifs } };
};

export const removeNotif = id => {
  return { type: REMOVE_SEEN_NOTIFICATION, payload: { id } };
};

export const getUserNotifications = () => {
  return async dispatch => {
    try {
      const result = await notifService.getUserNotifications();
      if (result.status === 401) {
        dispatch(logout());
      } else if (result.status < 400) {
        dispatch(setNotifications(result.data));
      } else {
        console.log('hata');
      }
    } catch (error) {
      console.log('hata');
    }
  };
};

export const handleNotif = (notif, files) => {
  return async dispatch => {
    await notifService.handleNotifSeen(notif.id);
    dispatch(removeNotif(notif.id));
    if (notif.type === 'File') {
      const selectedFile = files.find(f => f.id === notif.fileServiceId);
      dispatch(fileListActions.handleSupport(selectedFile));
    } else {
      dispatch(userActions.handleUserNotif(notif.userId));
    }
  };
};
