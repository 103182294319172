import React from 'react';
import { connect } from 'react-redux';
import { Button, Card, Typography } from 'antd';
import { EditOutlined, UnorderedListOutlined } from '@ant-design/icons';

import CommonTable from '../../components/CommonTable';

import { formattedColumns } from '../../utils/commonUtils';
import { tuningColumns } from '../../constants';

import * as servicesActions from '../../actions/servicesActions';

const { Text } = Typography;

class Services extends React.Component {
  constructor(props) {
    super(props);
    this.state = { disabled: true };
  }
  componentDidMount() {
    this.props.getTunings();
  }

  rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      this.setState(() => {
        if (selectedRowKeys.length < 1) {
          return { disabled: true };
        } else {
          return { disabled: false };
        }
      });
      this.setState({ selectedRowKeys });
    },
    getCheckboxProps: record => ({
      disabled: record.name === 'Disabled User', // Column configuration not to be checked
      name: record.name,
    }),
  };

  handleDelete = () => {
    this.props.handleDelete(this.state.selectedRowKeys);
  };
  renderColumnActions = () => {
    return {
      render: (text, record) => (
        <Button
          record={record}
          type='primary'
          block
          icon={<EditOutlined />}
          onClick={() => this.props.handleEdit(record)}
        >
          Edit
        </Button>
      ),
    };
  };

  render() {
    return (
      <Card
        title={
          <Text strong>
            <UnorderedListOutlined /> List Services
          </Text>
        }
        style={{ minHeight: '77vh' }}
      >
        <CommonTable
          dataSource={this.props.tunings}
          columns={formattedColumns(tuningColumns, {
            ...this.renderColumnActions(),
          })}
          rowSelection={this.rowSelection}
        />
        <Button
          style={{ marginTop: '1vh' }}
          type='primary'
          disabled={this.state.disabled}
          onClick={this.handleDelete}
        >
          Delete
        </Button>
      </Card>
    );
  }
}

const mapStateToProps = state => {
  return {
    tunings: state.tunings.tunings,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getTunings: () => dispatch(servicesActions.getTunings()),
    handleDelete: selectedRowKeys =>
      dispatch(servicesActions.handleDelete(selectedRowKeys)),
    handleEdit: record => dispatch(servicesActions.handleEdit(record)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Services);
