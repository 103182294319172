import { Row, Typography } from 'antd';

import RegisterForm from './RegisterForm';

const { Title } = Typography;

const Register = () => {
  return (
    <div>
      <Row
        type='flex'
        justify='center'
        align='middle'
        style={{ minHeight: '5vh' }}
      >
        <Title level={2}>Kayit Ol</Title>
      </Row>
      <Row
        type='flex'
        justify='center'
        align='middle'
        style={{ minHeight: '87.5vh' }}
      >
        <RegisterForm />
      </Row>
    </div>
  );
};

export default Register;
