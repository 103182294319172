import * as userActions from '../../actions/userActions';

const defaultState = {
  users: [],
  roles: [],
  message: null,
  isErr: false,
  selectedUser: null
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = defaultState, action) => {
  switch (action.type) {
    case userActions.CLEAR_MESSAGE:
      return { ...state, message: null };
    case userActions.SET_SELECTED_USER:
      return { ...state, ...action.payload };
    case userActions.SET_SELECTED_USER_BY_ID:
      return {
        ...state,
        selectedUser: state.users.find(f => f.id === action.payload.id)
      };
    case userActions.UPDATE_USER_STATUS_SUCCESS:
      return {
        ...state,
        isErr: false,
        message: 'User Status Updated Succesfully',
        users: [
          ...state.users.map(user =>
            user.id === action.payload.userId
              ? { ...user, status: action.payload.status }
              : { ...user }
          )
        ]
      };
    case userActions.UPDATE_USER_STATUS_FAIL:
      return {
        ...state,
        isErr: true,
        message: 'User Status not Updated'
      };
    case userActions.SET_USERS:
      return { ...state, ...action.payload };
    case userActions.UPDATE_USER_DISCOUNT:
      return {
        ...state,
        isErr: false,
        message: 'User Discount Updated',
        users: [
          ...state.users.map(u => {
            if (u.id === action.payload.userId) {
              return {
                ...u,
                discount: { ...u.discount, discount: action.payload.discount }
              };
            } else {
              return { ...u };
            }
          })
        ]
      };
    case userActions.UPDATE_USER_CREDIT:
      return {
        ...state,
        isErr: false,
        message: 'User Credit Updated Succesfully',
        users: [
          ...state.users.map(item => {
            if (item.id === action.payload.id) {
              return {
                ...item,
                credit: {
                  ...item.credit,
                  credit: item.credit.credit + action.payload.refundAmount
                }
              };
            } else {
              return { ...item };
            }
          })
        ]
      };
    case userActions.GET_ROLES_SUCCESS:
      return { ...state, ...action.payload };
    case userActions.GET_ROLES_FAIL:
      return { ...state, isErr: true, message: action.payload.msg };
    case userActions.UPDATE_USER_ROLE_SUCCESS:
      return {
        ...state,
        isErr: false,
        message: 'User Updated Succesfully',
        users: [
          ...state.users.map(user =>
            user.id === action.payload.userId
              ? { ...user, role: { ...action.payload.role } }
              : { ...user }
          )
        ]
      };
    case userActions.UPDATE_USER_ROLE_FAIL:
      return { ...state, isErr: true, message: action.payload.msg };
    default:
      return state;
  }
};
