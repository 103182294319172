import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Button, Card, Typography } from 'antd';
import { UnorderedListOutlined, EditOutlined } from '@ant-design/icons';

import CommonTable from '../../components/CommonTable';

import { formattedColumns } from '../../utils/commonUtils';
import { listCreditsColumns } from '../../constants';
import * as creditsActions from '../../actions/creditsActions';

const { Text } = Typography;

const ListCredits = ({ credits, getAllCredits, handleEdit }) => {
  useEffect(() => {
    async function loadCredits() {
      getAllCredits();
    }
    loadCredits();
  }, [getAllCredits]);

  const renderColumnActions = () => {
    return {
      render: (text, record) => (
        <Button
          record={record}
          type='primary'
          block
          icon={<EditOutlined />}
          onClick={() => handleEdit(record)}
        >
          Edit
        </Button>
      ),
    };
  };

  return (
    <Card
      title={
        <Text strong>
          <UnorderedListOutlined /> List Credits
        </Text>
      }
      style={{ minHeight: '77vh' }}
    >
      <CommonTable
        dataSource={credits}
        columns={formattedColumns(listCreditsColumns, {
          ...renderColumnActions(),
        })}
        rowSelection={null}
      />
    </Card>
  );
};

const mapStateToProps = state => {
  return {
    credits: state.credits.credits,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getAllCredits: () => dispatch(creditsActions.getAllCredits()),
    handleEdit: record => dispatch(creditsActions.handleEdit(record)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ListCredits);
