import React from 'react';
import { Select } from 'antd';
import { userStatus } from '../../constants';

const { Option } = Select;

const ChangeUserStatus = ({ changeStatus }) => {
    return <Select style={{ width: '100%' }} onChange={(value) => changeStatus(value)} placeholder="Status">
        {userStatus.map(item =>
            <Option key={item.id} value={item.status}>
                {item.status}
            </Option>)}
    </Select>;
};

export default ChangeUserStatus;