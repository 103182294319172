import * as creditService from '../api/service/creditService';
import { logout } from './authActions';
import { normalizeData } from '../utils/commonUtils';
import { setSelectedMenuKey } from './sidebarActions';
import Axios from 'axios';

export const SET_CREDITS_FOR_BUY = 'SET_CREDITS_FOR_BUY';
export const BUY_FAILED = 'BUY_FAILED';
export const SET_USER_DISCOUNT = 'SET_USER_DISCOUNT';
export const SET_CREDITS = 'SET_CREDITS';
export const SET_PAYMENT_CONTENT = 'SET_PAYMENT_CONTENT';
export const BUY_CREDIT_LOADING = 'BUY_CREDIT_LOADING';
export const SET_SELECTED_CREDIT = 'SET_SELECTED_CREDIT';
export const UPDATE_CREDIT_SUCCESS = 'UPDATE_CREDIT_SUCCESS';

export const setCredits = credits => {
  return { type: SET_CREDITS, payload: { credits } };
};

export const setCreditsForBuy = credits => {
  return { type: SET_CREDITS_FOR_BUY, payload: { credits } };
};

export const setBuyFailes = failMessage => {
  return { type: BUY_FAILED, payload: { failMessage } };
};

export const setUserDiscount = discount => {
  return { type: SET_USER_DISCOUNT, payload: { discount } };
};

export const setPaymentContent = (checkoutFormContent, div) => {
  return {
    type: SET_PAYMENT_CONTENT,
    payload: { checkoutFormContent, div },
  };
};

export const buyCreditLoading = state => {
  return { type: BUY_CREDIT_LOADING, payload: { state } };
};

const setSelectedCredit = selectedCredit => {
  return { type: SET_SELECTED_CREDIT, payload: { selectedCredit } };
};

export const updateCreditSucess = () => {
  return { type: UPDATE_CREDIT_SUCCESS };
};

export const handleBuyCredit = selectedRowKeys => {
  return async dispatch => {
    try {
      dispatch(buyCreditLoading(true));
      const ipRes = await Axios.get(
        'https://cors-anywhere.herokuapp.com/http://api.ipify.org/?format=text',
      );
      const result = await creditService.buyCredit(
        selectedRowKeys[0],
        ipRes.data,
      );
      if (result.status === 401) {
        dispatch(logout());
      } else if (result.status === 500) {
        dispatch(setBuyFailes('Something went wrong!!'));
      } else if (result.status < 400) {
        dispatch(setBuyFailes(''));
        dispatch(
          setPaymentContent(result.data.checkoutFormContent, result.data.div),
        );
        dispatch(setSelectedMenuKey('payment'));
      } else {
        dispatch(setBuyFailes('Something went wrong!!'));
      }
      dispatch(buyCreditLoading(false));
    } catch (error) {
      console.log(error);
      dispatch(setBuyFailes('Something went wrong!!'));
      dispatch(buyCreditLoading(false));
    } finally {
      dispatch(buyCreditLoading(false));
    }
  };
};

export const getCredits = () => {
  return async dispatch => {
    const result = await creditService.getCredits();
    if (result.status === 401) {
      dispatch(logout());
    } else if (result.status < 400) {
      dispatch(setCreditsForBuy(normalizeData(result.data)));
      const discountResult = await creditService.checkUserDiscount();
      if (discountResult.status === 401) {
        dispatch(logout());
      } else if (discountResult.status < 400) {
        dispatch(setUserDiscount(discountResult.data.discount));
      }
    }
  };
};

export const createCredit = values => {
  return async dispatch => {
    const result = await creditService.createCredit(values);
    if (result.status === 401) {
      dispatch(logout());
    } else if (result.status < 400) {
      dispatch(setSelectedMenuKey('listCredit'));
    } else {
      console.log('hata');
    }
  };
};

export const getAllCredits = () => {
  return async dispatch => {
    const result = await creditService.getAllCredits();
    if (result.status === 401) {
      dispatch(logout());
    } else if (result.status < 400) {
      dispatch(setCredits(result.data));
      dispatch(setSelectedMenuKey('listCredit'));
    }
  };
};

export const handleEdit = record => {
  return dispatch => {
    dispatch(setSelectedCredit(record));
    dispatch(setSelectedMenuKey('editCredit'));
  };
};

export const updateCredit = (tuning, id) => {
  return async dispatch => {
    const result = await creditService.update(tuning, id);
    if (result.status === 401) {
      dispatch(logout());
    } else {
      dispatch(updateCreditSucess());
      dispatch(setSelectedMenuKey('listCredit'));
    }
  };
};
