import React, { useEffect, useState } from 'react';
import { Form, Select, Typography, Input, Card, Row, Col } from 'antd';
import { connect } from 'react-redux';

import * as searchVehicleActions from '../../actions/searchVehicleActions';

const { Text } = Typography;
const { Option } = Select;

const SearchVehicle = ({
  vehicleNames,
  models,
  years,
  engineTypes,
  motorTypes,
  getVehicleNames,
  getVehicleModels,
  getVehicleYears,
  getVehicleEngineTypes,
  getVehicleMotorTypes,
  getSelectedVehicle
}) => {
  useEffect(() => {
    getVehicleNames();
  }, [getVehicleNames]);

  const [form] = Form.useForm();
  const [selectedName, setSelectedName] = useState();
  const [selectedModel, setSelectedModel] = useState();
  const [selectedYear, setSelectedYear] = useState();
  const [selectedEngineType, setSelectedEngineType] = useState();
  const [selectedMotorType, setSelectedMotorType] = useState();

  const onNameSelect = value => {
    getVehicleModels(value);
    setSelectedName(value);
    setSelectedModel(null);
    setSelectedYear(null);
    setSelectedEngineType(null);
    setSelectedMotorType(null);
  };

  const onModelSelect = value => {
    getVehicleYears(selectedName, value);
    form.setFieldsValue({ model: value });
    setSelectedModel(value);
    setSelectedYear(null);
    setSelectedEngineType(null);
    setSelectedMotorType(null);
  };

  const onYearSelect = value => {
    getVehicleEngineTypes(
      form.getFieldsValue(['name'])['name'],
      form.getFieldsValue(['model'])['model'],
      value
    );
    setSelectedYear(value);
    setSelectedEngineType(null);
    setSelectedMotorType(null);
  };

  const onEngineTypeSelect = value => {
    getVehicleMotorTypes(selectedName, selectedModel, selectedYear, value);
    setSelectedEngineType(value);
    setSelectedMotorType(null);
  };

  const onMotorTypeSelect = value => {
    setSelectedMotorType(value);
    getSelectedVehicle({
      selectedName,
      selectedModel,
      selectedYear,
      selectedEngineType,
      selectedMotorType: value
    });
  };

  return (
    <Card
      title={<Text>Araç Performansı Sorgula</Text>}
      style={{ marginBottom: '2vh' }}
    >
      <Row>
        <Col xs={24} xl={5}>
          <Select
            showSearch
            size='large'
            style={{ width: '100%' }}
            placeholder='Marka Seçiniz'
            value={selectedName}
            onChange={onNameSelect}
          >
            {vehicleNames.map(name => (
              <Option key={name} value={name}>
                {name}
              </Option>
            ))}
          </Select>
        </Col>
        <Col xs={24} xl={5}>
          <Select
            showSearch
            size='large'
            style={{ width: '100%' }}
            placeholder='Model Seçiniz'
            value={selectedModel}
            onChange={onModelSelect}
          >
            {models.map(model => (
              <Option key={model} value={model}>
                {model}
              </Option>
            ))}
          </Select>
        </Col>
        <Col xs={24} xl={4}>
          <Select
            showSearch
            size='large'
            style={{ width: '100%' }}
            placeholder='Yıl Seçiniz'
            value={selectedYear}
            onChange={onYearSelect}
          >
            {years.map(year => (
              <Option key={year} value={year}>
                {year}
              </Option>
            ))}
          </Select>
        </Col>
        <Col xs={24} xl={5}>
          <Select
            showSearch
            size='large'
            style={{ width: '100%' }}
            placeholder='Yakıt Seçiniz'
            value={selectedEngineType}
            onChange={onEngineTypeSelect}
          >
            {engineTypes.map(engineType => (
              <Option key={engineType} value={engineType}>
                {engineType}
              </Option>
            ))}
          </Select>
        </Col>
        <Col xs={24} xl={5}>
          <Select
            showSearch
            size='large'
            style={{ width: '100%' }}
            placeholder='Motor Tipi Seçiniz'
            value={selectedMotorType}
            onChange={onMotorTypeSelect}
          >
            {motorTypes.map(motorType => (
              <Option key={motorType} value={motorType}>
                {motorType}
              </Option>
            ))}
          </Select>
        </Col>
      </Row>
    </Card>
  );
};

const mapStateToProps = state => {
  return {
    vehicleNames: state.searchVehicle.vehicleNames,
    models: state.searchVehicle.models,
    years: state.searchVehicle.years,
    engineTypes: state.searchVehicle.engineTypes,
    motorTypes: state.searchVehicle.motorTypes,
    selectedVehicle: state.searchVehicle.selectedVehicle
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getVehicleNames: () => dispatch(searchVehicleActions.getVehicleNames()),
    getVehicleModels: name =>
      dispatch(searchVehicleActions.getVehicleModels(name)),
    getVehicleYears: (name, model) =>
      dispatch(searchVehicleActions.getVehicleYears(name, model)),
    getVehicleEngineTypes: (name, model, year) =>
      dispatch(searchVehicleActions.getVehicleEngineTypes(name, model, year)),
    getVehicleMotorTypes: (name, model, year, engineType) =>
      dispatch(
        searchVehicleActions.getVehicleMotorTypes(name, model, year, engineType)
      ),
    getSelectedVehicle: param =>
      dispatch(searchVehicleActions.getSelectedVehicle(param))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SearchVehicle);
