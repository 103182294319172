import React from 'react';
import { connect } from 'react-redux';
import { Layout, Breadcrumb, Menu, Typography } from 'antd';
import {
  LeftOutlined,
  LogoutOutlined,
  ExclamationCircleOutlined,
} from '@ant-design/icons';
import {
  contentManagerRoutes,
  fileServiceAdminRoutes,
  fileServiceRoutes,
  myCreditsRoutes,
  userManagementRoutes,
  transactionsRoutes,
  userProfileRoutes,
  dashboardRoutes,
} from '../../accountRoutes';

import * as accountContentActions from '../../actions/accountContentActions';
import * as notificationActions from '../../actions/notificationActions';
import * as authActions from '../../actions/authActions';
import NotificationBadge from '../NotificationBadge';
import NotifModal from '../NotifModal';

const { Header, Content, Footer } = Layout;
const { SubMenu } = Menu;

const AccountContent = ({
  user,
  selectedMenuKey,
  toHome,
  notifs,
  handleNotif,
  files,
  logout,
}) => {
  const renderContent = () => {
    let routes = [];
    if (user.role !== '') {
      if (user.role === 'user') {
        routes = [
          ...dashboardRoutes,
          ...fileServiceRoutes,
          ...myCreditsRoutes,
          ...userProfileRoutes,
          ...transactionsRoutes,
        ];
      } else if (user.role === 'admin') {
        routes = [
          ...dashboardRoutes,
          ...contentManagerRoutes,
          ...fileServiceAdminRoutes,
          ...userProfileRoutes,
          ...transactionsRoutes,
        ];
      } else if (user.role === 'mapper') {
        routes = [
          ...dashboardRoutes,
          ...fileServiceAdminRoutes,
          ...userProfileRoutes,
          ...transactionsRoutes,
          ...myCreditsRoutes,
        ];
      } else if (user.role === 'sysAdmin') {
        routes = [
          ...dashboardRoutes,
          ...contentManagerRoutes,
          ...fileServiceAdminRoutes,
          ...userManagementRoutes,
          ...userProfileRoutes,
          ...transactionsRoutes,
        ];
      }
      const selectedRoute = routes.find(route => route.key === selectedMenuKey);
      return (
        <React.Fragment>
          <Breadcrumb style={{ margin: '16px 0' }}>
            <Breadcrumb.Item style={{ fontSize: 30 }}>
              {selectedRoute.name}
            </Breadcrumb.Item>
          </Breadcrumb>
          {selectedRoute.component}
        </React.Fragment>
      );
    } else {
      return null;
    }
  };
  return (
    <Layout
      className='site-layout'
      style={{ minHeight: '100vh', overflowY: 'scroll' }}
    >
      <Header className='site-layout-background' style={{ padding: 0 }}>
        <Menu mode='horizontal'>
          <div>
            <Menu.Item key='back' onClick={() => toHome()} hoti>
              <LeftOutlined />
              Back To Site
            </Menu.Item>
          </div>
          <div style={{ display: 'flex', marginLeft: 'auto' }}>
            <Menu.Item key='credit'>{`Credit: ${user.credit}`}</Menu.Item>
            <Menu.Item key='welcome'>Welcome {user.name}</Menu.Item>
            <SubMenu title={<NotificationBadge />}>
              {notifs.map(item => (
                <Menu.Item
                  style={{
                    color: item.status === 'Seen' ? '#00000040' : '#000000a6',
                  }}
                  key={item.id}
                  onClick={() => handleNotif(item, files)}
                >
                  <ExclamationCircleOutlined />
                  {item.message}
                </Menu.Item>
              ))}
            </SubMenu>
            <Menu.Item key='logout' onClick={() => logout()}>
              <LogoutOutlined />
              Logout
            </Menu.Item>
          </div>
        </Menu>
      </Header>
      <Content style={{ margin: '0 16px' }}>{renderContent()}</Content>
      <NotifModal />
      <Footer style={{ textAlign: 'center' }}>
        ©2020 CARTECH CHIP TUNING All Rights Reserved
      </Footer>
    </Layout>
  );
};

const mapStateToProps = state => {
  return {
    selectedMenuKey: state.sidebar.selectedMenuKey,
    user: state.auth.user,
    notifs: state.notif.notifs,
    files: state.fileList.files,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    toHome: () => dispatch(accountContentActions.toHome()),
    logout: () => dispatch(authActions.logout()),
    handleNotif: (notif, files) =>
      dispatch(notificationActions.handleNotif(notif, files)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AccountContent);
