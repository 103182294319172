import * as overviewService from '../api/service/overviewService';

export const OVERVIEW_SUCCESS = 'OVERVIEW_SUCCESS';
export const OVERVIEW_FAIL = 'OVERVIEW_FAIL';

export const getOverviewSuccess = overview => {
  return { type: OVERVIEW_SUCCESS, payload: { overview } };
};

export const getOverviewFail = err => {
  return { type: OVERVIEW_FAIL, payload: { err } };
};

export const getOverview = () => {
  return async dispatch => {
    try {
      const result = await overviewService.getOverview();
      dispatch(getOverviewSuccess(result.data));
    } catch (error) {
      dispatch(getOverviewFail(error));
    }
  };
};
