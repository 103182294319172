import * as tuningTypeActions from '../../actions/servicesActions';

const defaultState = {
  tunings: [],
  selectedRowKeys: [],
  selectedTuning: null,
  tuningType: null,
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = defaultState, action) => {
  switch (action.type) {
    case tuningTypeActions.GET_TUNINGS_SUCCESS:
      return { ...state, ...action.payload };
    case tuningTypeActions.DELETE_TUNING_SUCCESS:
      const { selectedRowKeys } = action.payload;

      selectedRowKeys.map(key => {
        state.tunings.splice(
          state.tunings.findIndex(item => item.id === key),
          1
        );
        return key;
      });

      return { ...state, tunings: [...state.tunings] };
    case tuningTypeActions.SET_SELECTED_TUNING:
      return { ...state, ...action.payload };
    case tuningTypeActions.SET_TUNING_TYPE:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};
