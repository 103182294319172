import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';

import register from '../modules/registerReducer';
import auth from '../modules/authReducer';
import sidebar from '../modules/sidebarReducer';
import listVehicle from '../modules/listVehicleReducer';
import tunings from '../modules/tuningReducer';
import fileList from '../modules/fileListReducer';
import searchVehicle from '../modules/searchVehicleReducer';
import fileService from '../modules/fileServiceReducer';
import tuningInfo from '../modules/tuningInfoReducer';
import credits from '../modules/creditsReducer';
import user from '../modules/userReducer';
import notif from '../modules/notifReducer';
import transactions from '../modules/transactionsReducer';
import overview from '../modules/overviewReducer';
import modal from '../modules/modalReducer';

const createRootReducer = history =>
  combineReducers({
    router: connectRouter(history),
    register,
    auth,
    sidebar,
    listVehicle,
    tunings,
    fileList,
    searchVehicle,
    fileService,
    tuningInfo,
    credits,
    user,
    notif,
    transactions,
    overview,
    modal
  });

export default createRootReducer;
