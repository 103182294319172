import * as fileServiceActions from '../../actions/fileServiceActions';

const defaultState = {
  loading: false,
  message: '',
  readmethods: [],
  selectedReadmethod: null,
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = defaultState, action) => {
  switch (action.type) {
    case fileServiceActions.SET_SUBMIT_BUTTON_LOADING:
      return { ...state, loading: action.payload.status };
    case fileServiceActions.SUBMIT_FAIL:
      return { ...state, ...action.payload };
    case fileServiceActions.SUBMIT_SUCCESS:
      return { ...state, message: '' };
    case fileServiceActions.CREATE_READMETHOD_SUCCESS:
      return { ...state, message: '' };
    case fileServiceActions.CREATE_READMETHOD_FAIL:
      return { ...state, message: action.payload.message };
    case fileServiceActions.GET_METHODS_SUCCESS:
      return { ...state, ...action.payload };
    case fileServiceActions.SET_SELECTED_READ_METHOD:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};
