import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Card, Typography, Input, InputNumber, Button, Form } from 'antd';
import { WalletOutlined } from '@ant-design/icons';

import * as creditsActions from '../../actions/creditsActions';

const { Text } = Typography;

const layout = {
  labelCol: { span: 3 },
  wrapperCol: { span: 18 },
};

const validateMessages = (label, min, max) => ({
  required: `${label} is required!`,
  number: {
    range: `${label} must be between ${min} and ${max}`,
  },
});

const CreateCredit = ({ createCredit, selectedCredit, updateCredit }) => {
  const [isEdit] = useState(selectedCredit !== null ? true : false);

  const [form] = Form.useForm();

  useEffect(() => {
    if (isEdit) {
      form.setFieldsValue({ ...selectedCredit });
    }
  });

  const onFinish = values => {
    if (isEdit) {
      updateCredit(values, selectedCredit.id);
    } else {
      createCredit(values);
    }
    form.resetFields();
  };

  return (
    <Card
      title={
        <Text strong>
          <WalletOutlined /> Create Credits
        </Text>
      }
      style={{ minHeight: '77vh' }}
    >
      <Form
        form={form}
        {...layout}
        name='create-credit'
        onFinish={onFinish}
        validateMessages={validateMessages}
      >
        <Form.Item
          name={['amount']}
          label='Credit Amount'
          rules={[{ type: 'number', min: 1, max: 1000, required: true }]}
        >
          <InputNumber style={{ width: '100%' }} />
        </Form.Item>
        <Form.Item
          name={['description']}
          label='Credit Description'
          rules={[{ required: true }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name={['price']}
          label='Credit Price'
          rules={[{ type: 'number', min: 1, required: true }]}
        >
          <InputNumber style={{ width: '100%' }} />
        </Form.Item>
        <Form.Item wrapperCol={{ ...layout.wrapperCol, offset: 3 }}>
          <Button type='primary' htmlType='submit'>
            Submit
          </Button>
        </Form.Item>
      </Form>
    </Card>
  );
};

const mapStateToProps = state => {
  return {
    selectedCredit: state.credits.selectedCredit,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    createCredit: values => dispatch(creditsActions.createCredit(values)),
    updateCredit: (credit, id) =>
      dispatch(creditsActions.updateCredit(credit, id)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateCredit);
