import { push } from 'connected-react-router';
import { refreshTokenService } from '../api/service/commonService';
import * as loginService from '../api/service/loginService';
import * as userService from '../api/service/userService';
import { getAllFiles } from './fileListActions';
import { setSelectedMenuKey } from './sidebarActions';

export const LOGIN_FAIL = 'LOGIN_FAIL';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const SET_USER = 'SET_USER';
export const UPDATE_CREDIT = 'UPDATE_CREDIT';
export const UPDATE_USER_INFO_SUCCESS = 'UPDATE_USER_INFO_SUCCESS';
export const UPDATE_USER_INFO_FAIL = 'UPDATE_USER_INFO_FAIL';
export const UPDATE_PASSWORD_SUCCESS = 'UPDATE_PASSWORD_SUCCESS';
export const UPDATE_PASSWORD_FAIL = 'UPDATE_PASSWORD_FAIL';
export const SET_UPDATE_PASS_LOADING = 'SET_UPDATE_PASS_LOADING';

export const loginFail = message => {
  return { type: LOGIN_FAIL, payload: { message } };
};

export const loginSuccess = () => {
  return { type: LOGIN_SUCCESS };
};

export const logout = () => {
  return async dispatch => {
    try {
      await loginService.rejectToken();
    } catch (error) {
    } finally {
      localStorage.clear();
      dispatch({ type: LOGOUT_SUCCESS });
    }
    window.location.reload();
  };
};

export const setUser = user => {
  return { type: SET_USER, payload: { user } };
};

export const updateCredit = credit => {
  return { type: UPDATE_CREDIT, payload: { credit } };
};

export const updateUserInfoSucccess = data => {
  return { type: UPDATE_USER_INFO_SUCCESS, payload: { data } };
};

export const updateUserInfoFail = error => {
  return { type: UPDATE_USER_INFO_FAIL, payload: { error } };
};

export const updatePasswordSuccess = () => {
  return { type: UPDATE_PASSWORD_SUCCESS };
};

export const updatePasswordFail = message => {
  return { type: UPDATE_PASSWORD_FAIL, payload: { message } };
};

export const setUpdatePassLoading = status => {
  return { type: SET_UPDATE_PASS_LOADING, payload: { status } };
};

export const login = params => {
  return async dispatch => {
    try {
      const result = await loginService.login(params);
      if (result.status === 401) {
        dispatch(loginFail('email veya şifre uyuşmuyor!!'));
      } else if (result.status < 400) {
        dispatch(setUser(result.data));
        dispatch(loginSuccess());
        dispatch(setSelectedMenuKey('dashboard'));
        dispatch(push('/account'));
        dispatch(getAllFiles());
      } else {
        dispatch(loginFail(result.data.message));
      }
    } catch (error) {
      dispatch(loginFail('Su anda giris yapilamiyor.Tekrar deneyiniz'));
    }
  };
};

export const checkUserLogin = pathname => {
  return async dispatch => {
    if (localStorage.getItem('token')) {
      const result = await refreshTokenService();
      if (result.status === 401) {
        dispatch(loginFail('Oturum Süreniz Bitmiştir'));
      } else if (result.status < 400) {
        dispatch(setUser(result.data));
        if (pathname === '/account/success') {
          dispatch(setSelectedMenuKey('paymentSuccess'));
        } else if (pathname === '/account/fail') {
          dispatch(setSelectedMenuKey('paymentFail'));
          // dispatch(push('/account'));
        } else {
          dispatch(setSelectedMenuKey('fileList'));
          // dispatch(push('/account'));
        }
        dispatch(loginSuccess());
        dispatch(push('/account'));
        dispatch(getAllFiles());
      } else {
        dispatch(loginFail('Su anda giris yapilamiyor.Tekrar deneyiniz'));
      }
    }
  };
};

export const updateUserInfo = data => {
  return async dispatch => {
    try {
      const result = await userService.updateUserInfo(data);
      if (result.status === 401) {
        dispatch(logout());
      } else if (result.status < 400) {
        dispatch(updateUserInfoSucccess(data));
      }
    } catch (error) {
      dispatch(updateUserInfoFail(error));
    }
  };
};

export const changePassword = values => {
  return async dispatch => {
    dispatch(setUpdatePassLoading(true));
    try {
      const result = await userService.changePassword(values);
      if (result.status === 401) {
        dispatch(logout());
      } else if (result.status < 400) {
        dispatch(updatePasswordSuccess());
      } else {
        dispatch(updatePasswordFail(result.data.message));
      }
    } catch (error) {
      dispatch(updatePasswordFail(error));
    } finally {
      dispatch(setUpdatePassLoading(false));
    }
  };
};
