import React from 'react';
import { Card, Typography, Upload, message } from 'antd';
import { InboxOutlined } from '@ant-design/icons';

const { Text } = Typography;

const mimeTypes = [
  'application/x-rar-compressed',
  'application/octet-stream',
  'application/zip',
  'application/octet-stream',
  'application/x-zip-compressed',
  'multipart/x-zip'
];

class FileUpload extends React.Component {
  constructor(props) {
    super(props);
    this.state = { fileList: [] };
  }

  beforeUpload = file => {
    if (mimeTypes.includes(file.type)) {
      this.props.onUpload(file);
      this.setState(state => ({
        fileList: [file]
      }));
    } else {
      this.props.onUpload(null);
      this.setState({ fileList: [] });
      message.error(`You should upload as .rar or .zip file`);
    }
    return false;
  };

  onRemove = () => {
    this.setState({ fileList: [] });
  };

  render() {
    return (
      <Card
        title={<Text strong>File Upload</Text>}
        style={{ marginBottom: '2vh' }}
      >
        <Upload.Dragger
          maxCount={1}
          listType='picture'
          name='files'
          beforeUpload={file => this.beforeUpload(file)}
          onRemove={this.onRemove}
        >
          <p className='ant-upload-drag-icon'>
            <InboxOutlined />
          </p>
          <p className='ant-upload-text'>
            Click or drag file to this area to upload
          </p>
          <p className='ant-upload-hint'>Upload Your File as .rar or .zip</p>
        </Upload.Dragger>
      </Card>
    );
  }
}

export default FileUpload;
