import * as authActions from '../../actions/authActions';

const defaultState = {
  message: '',
  isAuthenticated: false,
  user: null,
  updated: false,
  updatePassMessage: null,
  updatePassLoading: false
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = defaultState, action) => {
  switch (action.type) {
    case authActions.LOGIN_FAIL:
      return { ...state, ...action.payload };
    case authActions.LOGIN_SUCCESS:
      return { ...state, message: '', isAuthenticated: true };
    case authActions.LOGOUT_SUCCESS:
      return { ...state, message: '', isAuthenticated: false };
    case authActions.SET_USER:
      return { ...state, ...action.payload };
    case authActions.UPDATE_CREDIT:
      return {
        ...state,
        user: { ...state.user, credit: action.payload.credit },
      };
    case authActions.UPDATE_USER_INFO_SUCCESS:
      return { ...state, user: { ...state.user, ...action.payload.data } };
    case authActions.UPDATE_PASSWORD_SUCCESS:
      return { ...state, updated: true, updatePassMessage: 'Updated' };
    case authActions.UPDATE_PASSWORD_FAIL:
      return { ...state, updatePassMessage: action.payload.message, updated: false };
    case authActions.SET_UPDATE_PASS_LOADING:
      return { ...state, updatePassLoading: action.payload.status };
    default:
      return state;
  }
};
