import React from 'react';

import Logo from '../../assets/img/cartechchiptuning.png';

export const Nav00DataSource = (isAuthenticated, user) => {
  let menuItems = [];
  const fMenuItem = {
    name: 'item1',
    className: 'header0-item',
    children: {
      href: `${isAuthenticated ? 'account' : 'login'}`,
      children: [{ children: `${isAuthenticated ? user.name : 'Giriş Yap'}`, name: 'text' }],
    },
  };
  const sMenuItem = {
    name: 'item2',
    className: 'header0-item',
    children: {
      href: 'register',
      children: [{ children: 'Kayıt Ol', name: 'text' }],
    },
  }
  menuItems.push(fMenuItem)
  if (!isAuthenticated) {
    menuItems.push(sMenuItem)
  }
  return {
    wrapper: { className: 'header0 home-page-wrapper' },
    page: { className: 'home-page' },
    logo: {
      className: 'header0-logo',
      children: {
        img: Logo,
      },
    },
    Menu: {
      className: 'header0-menu',
      children: [...menuItems],
    },
    mobileMenu: { className: 'header0-mobile-menu' },
  }
};
export const Banner10DataSource = {
  wrapper: { className: 'banner1' },
  BannerAnim: {
    children: [
      {
        name: 'elem0',
        BannerElement: { className: 'banner-user-elem' },
        textWrapper: { className: 'banner1-text-wrapper' },
        bg: { className: 'bg bg0 k72c4ntyo1d-editor_css' },
        title: {
          className: 'elementor-slide-heading',
          children: 'CHIP  TUNING',
        },
      },
      {
        name: 'elem1',
        BannerElement: { className: 'banner-user-elem' },
        textWrapper: { className: 'banner1-text-wrapper' },
        bg: { className: 'bg bg1' },
        title: {
          className: 'elementor-slide-heading',
          children: 'HIZ LİMİTÖRÜ İPTALİ',
        },
      },
      {
        name: 'elem2',
        BannerElement: { className: 'banner-user-elem' },
        textWrapper: { className: 'banner1-text-wrapper' },
        bg: { className: 'bg bg2' },
        title: {
          className: 'elementor-slide-heading',
          children: 'DUSUK YAKIT TUKETIMI',
        },
      },
    ],
  },
};

export const Content00DataSource = {
  wrapper: { className: 'home-page-wrapper content0-wrapper' },
  page: { className: 'home-page content0' },
  OverPack: { playScale: 0.3, className: '' },
  titleWrapper: {
    className: 'title-wrapper',
    children: [{ name: 'title', children: 'Misyonumuz' }],
  },
  childWrapper: {
    className: 'content0-block-wrapper',
    children: [
      {
        name: 'block0',
        className: 'content0-block',
        md: 8,
        xs: 24,
        children: {
          className: 'content0-block-item',
          children: [
            {
              name: 'image',
              className: 'content0-block-icon',
              children:
                'https://zos.alipayobjects.com/rmsportal/WBnVOjtIlGWbzyQivuyq.png',
            },
            {
              name: 'title',
              className: 'content0-block-title',
              children: 'Guvenilir',
            },
            {
              name: 'content',
              children:
                '10.000 den fazla musterimizden guvenilirlik konusunda tam not aldik.',
            },
          ],
        },
      },
      {
        name: 'block1',
        className: 'content0-block',
        md: 8,
        xs: 24,
        children: {
          className: 'content0-block-item',
          children: [
            {
              name: 'image',
              className: 'content0-block-icon',
              children:
                'https://zos.alipayobjects.com/rmsportal/YPMsLQuCEXtuEkmXTTdk.png',
            },
            {
              name: 'title',
              className: 'content0-block-title',
              children: 'Hizli',
            },
            {
              name: 'content',
              children: 'En hizli is yapma garantisi.',
            },
          ],
        },
      },
      {
        name: 'block2',
        className: 'content0-block',
        md: 8,
        xs: 24,
        children: {
          className: 'content0-block-item',
          children: [
            {
              name: 'image',
              className: 'content0-block-icon',
              children:
                'https://zos.alipayobjects.com/rmsportal/EkXWVvAaFJKCzhMmQYiX.png',
            },
            {
              name: 'title',
              className: 'content0-block-title',
              children: 'Hesapli',
            },
            {
              name: 'content',
              children: 'En uygun fiyatlar.',
            },
          ],
        },
      },
    ],
  },
};
export const Content10DataSource = {
  wrapper: { className: 'home-page-wrapper content1-wrapper' },
  OverPack: { className: 'home-page content1', playScale: 0.3 },
  imgWrapper: { className: 'content1-img', md: 10, xs: 24 },
  img: {
    children: 'https://zos.alipayobjects.com/rmsportal/nLzbeGQLPyBJoli.png',
  },
  textWrapper: { className: 'content1-text', md: 14, xs: 24 },
  title: {
    className: 'content1-title',
    children: 'Kisiye Ozel Modifikasyonlar',
  },
  content: {
    className: 'content1-content',
    children:
      'Bir cok farkli marka ve model aracta kisiye ozel modifikasyonlar yaparak araclarin performanslarini artiriyoruz.',
  },
};
export const Content30DataSource = {
  wrapper: { className: 'home-page-wrapper content3-wrapper' },
  page: { className: 'home-page content3' },
  OverPack: { playScale: 0.3 },
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      {
        name: 'title',
        children: 'CarTech',
        className: 'title-h1',
      },
      {
        name: 'content',
        className: 'title-content',
        children: 'Aracını Geliştir',
      },
    ],
  },
  block: {
    className: 'content3-block-wrapper',
    children: [
      {
        name: 'block0',
        className: 'content3-block',
        md: 8,
        xs: 24,
        children: {
          icon: {
            className: 'content3-icon',
            children:
              'https://zos.alipayobjects.com/rmsportal/ScHBSdwpTkAHZkJ.png',
          },
          textWrapper: { className: 'content3-text' },
          title: {
            className: 'content3-title',
            children: 'Gizli Ozellik Acma',
          },
          content: {
            className: 'content3-content',
            children:
              'Donanimi bulunan ancak aktif edilmemis gizli ozellikleri acilir.',
          },
        },
      },
      {
        name: 'block1',
        className: 'content3-block',
        md: 8,
        xs: 24,
        children: {
          icon: {
            className: 'content3-icon',
            children:
              'https://zos.alipayobjects.com/rmsportal/NKBELAOuuKbofDD.png',
          },
          textWrapper: { className: 'content3-text' },
          title: { className: 'content3-title', children: 'Chip Tuning' },
          content: {
            className: 'content3-content',
            children: 'Arac performansi acmak icin chip tuning',
          },
        },
      },
      {
        name: 'block2',
        className: 'content3-block',
        md: 8,
        xs: 24,
        children: {
          icon: {
            className: 'content3-icon',
            children:
              'https://zos.alipayobjects.com/rmsportal/xMSBjgxBhKfyMWX.png',
          },
          textWrapper: { className: 'content3-text' },
          title: { className: 'content3-title', children: 'DPF-EGR Iptali' },
          content: {
            className: 'content3-content',
            children: 'DPF-EGR Iptali',
          },
        },
      },
      {
        name: 'block3',
        className: 'content3-block',
        md: 8,
        xs: 24,
        children: {
          icon: {
            className: 'content3-icon',
            children:
              'https://zos.alipayobjects.com/rmsportal/MNdlBNhmDBLuzqp.png',
          },
          textWrapper: { className: 'content3-text' },
          title: { className: 'content3-title', children: 'Adblue Iptali' },
          content: {
            className: 'content3-content',
            children: 'Aracinizin Adblue ozelligin iptal edilir.',
          },
        },
      },
      {
        name: 'block4',
        className: 'content3-block',
        md: 8,
        xs: 24,
        children: {
          icon: {
            className: 'content3-icon',
            children:
              'https://zos.alipayobjects.com/rmsportal/UsUmoBRyLvkIQeO.png',
          },
          textWrapper: { className: 'content3-text' },
          title: {
            className: 'content3-title',
            children: 'Hiz Limitor Iptali',
          },
          content: {
            className: 'content3-content',
            children: 'Hiz Limitor Iptali',
          },
        },
      },
      {
        name: 'block5',
        className: 'content3-block',
        md: 8,
        xs: 24,
        children: {
          icon: {
            className: 'content3-icon',
            children:
              'https://zos.alipayobjects.com/rmsportal/ipwaQLBLflRfUrg.png',
          },
          textWrapper: { className: 'content3-text' },
          title: {
            className: 'content3-title',
            children: 'Dusuk yakit tuketimi',
          },
          content: {
            className: 'content3-content',
            children: 'Aracinizin dusuk yakit tuketmesini saglar.',
          },
        },
      },
    ],
  },
};
export const Footer00DataSource = {
  wrapper: { className: 'home-page-wrapper footer0-wrapper' },
  OverPack: { className: 'home-page footer0', playScale: 0.05 },
  copyright: {
    className: 'copyright',
    children: <span>©2020 CARTECH CHIP TUNING All Rights Reserved</span>,
  },
};
