import React from 'react';
import { Card, Typography, Descriptions, Button } from 'antd';
import { CustomerServiceOutlined, DownloadOutlined } from '@ant-design/icons';

const { Text } = Typography;

const DescriptionCard = ({
  selectedFile,
  handleDownload,
  handleDownloadTuned,
}) => {
  return (
    <Card
      style={{ marginBottom: '2vh' }}
      title={
        <Text strong>
          <CustomerServiceOutlined /> File Support
        </Text>
      }
    >
      <Descriptions title='File Info'>
        <Descriptions.Item label='File Number'>
          {selectedFile.id}
        </Descriptions.Item>
        <Descriptions.Item label='Vehicle Name'>
          {`${selectedFile.vehicle.name} ${selectedFile.vehicle.model} ${selectedFile.vehicle.year} ${selectedFile.vehicle.engineType} ${selectedFile.vehicle.motorType}`}
        </Descriptions.Item>
        <Descriptions.Item label='File Owner'>
          {selectedFile.user.name}
        </Descriptions.Item>
        <Descriptions.Item label='Service Type'>
          {selectedFile.service.name}
        </Descriptions.Item>
        <Descriptions.Item label='Hardware Number'>
          {selectedFile.hardwareNumber}
        </Descriptions.Item>
        <Descriptions.Item label='Software Number'>
          {selectedFile.softwareNumber}
        </Descriptions.Item>
        <Descriptions.Item label='Readmethod'>
          {selectedFile.readmethod.name}
        </Descriptions.Item>
        <Descriptions.Item label='File'>
          <Button
            type='primary'
            icon={<DownloadOutlined />}
            onClick={() => handleDownload(selectedFile)}
          >
            Download
          </Button>
        </Descriptions.Item>
        <Descriptions.Item label='Tuned File'>
          {selectedFile.tunedFileName === null ? (
            'Not Tuned Yet'
          ) : (
            <Button
              type='primary'
              icon={<DownloadOutlined />}
              onClick={() => handleDownloadTuned(selectedFile)}
            >
              Download Tuned File
            </Button>
          )}
        </Descriptions.Item>
        <Descriptions.Item label='Note'>
          {selectedFile.description}
        </Descriptions.Item>
      </Descriptions>
    </Card>
  );
};

export default DescriptionCard;
