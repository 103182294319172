import { connect } from 'react-redux';
import { Form, Input, Button, Typography } from 'antd';

import * as authActions from '../../actions/authActions';

const { Text } = Typography;

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 }
};
const tailLayout = {
  wrapperCol: { offset: 8 }
};

const LoginForm = props => {
  const onFinish = values => {
    props.login(values);
  };

  return (
    <Form {...layout} onFinish={onFinish}>
      <Form.Item
        label='Email'
        name='email'
        rules={[{ required: true, message: 'Please input your email!' }]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label='Password'
        name='password'
        rules={[{ required: true, message: 'Please input your password!' }]}
      >
        <Input.Password />
      </Form.Item>

      <Form.Item {...tailLayout}>
        <Button type='primary' htmlType='submit'>
          Login
        </Button>
      </Form.Item>
      {props.message.length > 0 ? (
        <Form.Item>
          <Text type='danger'>{props.message}</Text>
        </Form.Item>
      ) : null}
    </Form>
  );
};

const mapStateToPorops = state => {
  return {
    message: state.auth.message
  };
};

const mapDispatchToProps = dispatch => {
  return {
    login: params => dispatch(authActions.login(params))
  };
};

export default connect(mapStateToPorops, mapDispatchToProps)(LoginForm);
