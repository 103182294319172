export const formattedColumns = (columns, extras) => {
  return columns.map(col => {
    if (col.isAction === false) {
      return { ...col };
    }
    return {
      ...col,
      ...extras,
    };
  });
};

export const normalizeData = data => {
  return data.map(datum => {
    return { ...datum, key: datum.id, selected: false };
  });
};
