import * as notificationActions from '../../actions/notificationActions';

const defaultState = {
  notifs: [],
  notifCount: 0
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = defaultState, action) => {
  switch (action.type) {
    case notificationActions.SET_NOTIFICATIONS:
      return {
        ...state,
        ...action.payload,
        notifCount: action.payload.notifs.filter(f => f.status === 'Created')
          .length
      };
    case notificationActions.REMOVE_SEEN_NOTIFICATION:
      const selectedNotif = state.notifs.find(f => f.id === action.payload.id);
      return {
        ...state,
        notifs: [
          ...state.notifs.map(item =>
            item.id === action.payload.id
              ? { ...item, status: 'Seen' }
              : { ...item }
          )
        ],
        notifCount:
          selectedNotif.status === 'Created'
            ? state.notifCount - 1
            : state.notifCount
      };
    default:
      return state;
  }
};
