import React from 'react';
import { Result, Button } from 'antd';

const PaymentSuccess = () => {
  return (
    <Result
      status='success'
      title='Successfully Purchased Credit!'
      subTitle='Press OK Button to Turn Back'
      extra={[
        <Button type='primary' key='console' href='/account'>
          OK
        </Button>,
      ]}
    />
  );
};

export default PaymentSuccess;
