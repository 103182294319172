export const SET_SELECTED_MENU_KEY = 'SET_SELECTED_MENU_KEY';

export const selectedMenuKey = key => {
  return { type: SET_SELECTED_MENU_KEY, payload: { key } };
};

export const setSelectedMenuKey = key => {
  return dispatch => {
    dispatch(selectedMenuKey(key));
  };
};
