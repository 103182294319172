import React, { useEffect } from 'react';
import { Card, Col, Divider, Row, Typography } from 'antd';
import { connect } from 'react-redux';

import { getLatestFiles } from '../../actions/fileListActions';

const { Text, Title } = Typography;

const LatestFiles = ({ latestFiles, getLatestFiles }) => {
  useEffect(() => {
    getLatestFiles();
  }, []);
  return (
    <div className='home-page-wrapper content0-wrapper'>
      <div className='home-page content0'>
        <Card title='Son Yapılan Dosyalar'>
          <Row gutter={[24, 24]} style={{ backgroundColor: '#001529' }}>
            <Col span={8}>
              <Title level={4} style={{ color: 'white' }}>
                Tarih
              </Title>
            </Col>
            <Col span={8}>
              <Title level={4} style={{ color: 'white' }}>
                Araç
              </Title>
            </Col>
            <Col span={8}>
              <Title level={4} style={{ color: 'white' }}>
                Servis
              </Title>
            </Col>
          </Row>
          <Divider />
          {latestFiles ? (
            latestFiles.map(item => (
              <>
                <Row gutter={[24, 24]}>
                  <Col span={8}>
                    <Title level={5}>{`${new Date(
                      item.createdAt
                    ).toLocaleDateString('TR')}`}</Title>
                  </Col>
                  <Col span={8}>
                    <Title
                      level={5}
                    >{`${item.vehicle.name} ${item.vehicle.model}`}</Title>
                  </Col>
                  <Col span={8}>
                    <Title level={5}>{`${item.service.name}`}</Title>
                  </Col>
                </Row>
                <Divider />
              </>
            ))
          ) : (
            <Text>Empty</Text>
          )}
        </Card>
      </div>
    </div>
  );
};

const mapStateToProps = state => {
  return {
    latestFiles: state.fileList.latestFiles
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getLatestFiles: () => dispatch(getLatestFiles())
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LatestFiles);
