import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Card, Form, Input, Typography, Button, InputNumber } from 'antd';

import * as servicesActions from '../../actions/servicesActions';

const { Text } = Typography;
const { TextArea } = Input;

const AddService = props => {
  const [form] = Form.useForm();
  const [isEdit] = useState(props.selectedTuning !== null ? true : false);

  useEffect(() => {
    const { selectedTuning } = props;
    if (isEdit) {
      form.setFieldsValue({ ...selectedTuning });
    }
  });

  const onFinish = values => {
    if (isEdit) {
      props.updateTuning(values, props.selectedTuning.id);
    } else {
      props.saveTuningType(values);
    }
  };

  return (
    <Card
      title={
        <Text strong>{isEdit === false ? 'Add Service' : 'Edit Service'}</Text>
      }
      style={{ marginBottom: '2vh' }}
    >
      <Form
        form={form}
        layout='horizontal'
        size='large'
        onFinish={onFinish}
        wrapperCol={{ span: 18 }}
        labelCol={{ span: 3 }}
      >
        <Form.Item
          label='Tuning Name'
          name='name'
          rules={[
            {
              required: true,
              message: 'Please enter name!',
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label='Tuning Description'
          name='description'
          rules={[
            {
              required: true,
              message: 'Please enter description!',
            },
          ]}
        >
          <TextArea />
        </Form.Item>
        <Form.Item
          label='Price'
          name='credit'
          rules={[
            {
              required: true,
              message: 'Please enter price!',
            },
          ]}
        >
          <InputNumber style={{ width: '100%' }} min={0} />
        </Form.Item>
        <Form.Item>
          <Button type='primary' htmlType='submit'>
            Submit
          </Button>
          {isEdit ? (
            <Button
              style={{ marginLeft: '1vh' }}
              type='danger'
              onClick={() => props.handleCancel()}
            >
              Cancel
            </Button>
          ) : null}
        </Form.Item>
      </Form>
    </Card>
  );
};

const mapStateToProps = state => {
  return {
    selectedTuning: state.tunings.selectedTuning,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    saveTuningType: tuning => dispatch(servicesActions.saveTuningType(tuning)),
    updateTuning: (tuning, id) =>
      dispatch(servicesActions.updateTuning(tuning, id)),
    handleCancel: () => dispatch(servicesActions.handleCancel()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddService);
