import { connect } from 'react-redux';
import { isMobile } from 'react-device-detect';

import Nav0 from '../../views/Home/Nav0';

import { Nav00DataSource } from '../../views/Home/data.source';

const AppBar = ({ isAuthenticated, user, pathname }) => {
  return (
    <div
      style={{ display: isAuthenticated && pathname !== '/' ? 'none' : 'flex' }}
    >
      <Nav0
        id='Nav0_0'
        key='Nav0_0'
        dataSource={Nav00DataSource(isAuthenticated, user)}
        isMobile={isMobile ? true : false}
      />
    </div>
  );
};

const mapStateToPorops = state => {
  return {
    user: state.auth.user,
    pathname: state.router.location.pathname,
    isAuthenticated: state.auth.isAuthenticated
  };
};

const mapDispatchToProps = dispatch => {
  return {};
};

export default connect(mapStateToPorops, mapDispatchToProps)(AppBar);
