import React from 'react';
import { connect } from 'react-redux';
import { Card, Typography, Radio, Tooltip } from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';

import * as tuningTypeActions from '../../actions/tuningTypeActions';

const { Text } = Typography;

const radioStyle = {
  display: 'block',
  height: '30px',
  lineHeight: '30px',
};

class TuningType extends React.Component {
  state = {
    value: '',
  };

  componentDidMount() {
    this.props.getTunings();
  }

  onChange = e => {
    this.setState({ value: e.target.value });
    this.props.setTuningType(e.target.value);
  };

  render() {
    return (
      <Card
        title={<Text strong>Tuning Type</Text>}
        style={{ marginBottom: '2vh' }}
      >
        <Radio.Group onChange={this.onChange} value={this.state.value}>
          {this.props.tunings.map(tuning => (
            <Radio style={radioStyle} value={tuning.id} key={tuning.name}>
              <span style={{ marginRight: '1vh' }}>{tuning.name}</span>
              <Tooltip title={tuning.description}>
                <QuestionCircleOutlined />
              </Tooltip>
            </Radio>
          ))}
        </Radio.Group>
      </Card>
    );
  }
}


const mapStateToProps = state => {
  return {
    tunings: state.tunings.tunings,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getTunings: () => dispatch(tuningTypeActions.getTunings()),
    setTuningType: tuningType =>
      dispatch(tuningTypeActions.setTuningType(tuningType)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TuningType);
