import * as vehicleService from '../api/service/vehicleService';
import { normalizeData } from '../utils/commonUtils';
import { setSelectedMenuKey } from './sidebarActions';
import { logout } from './authActions';

export const GET_VEHICLES_SUCCESS = 'GET_VEHICLES_SUCCESS';
export const DELETE_VEHICLE_SUCCESS = 'DELETE_VEHICLE_SUCCESS';
export const SET_SELECTED_VEHICLE = 'SET_SELECTED_VEHICLE';

export const getVehiclesSuccess = vehicles => {
  return { type: GET_VEHICLES_SUCCESS, payload: { vehicles } };
};

export const deleteVehicleSuccess = selectedRowKeys => {
  return { type: DELETE_VEHICLE_SUCCESS, payload: { selectedRowKeys } };
};

export const setSelectedVehicle = selectedVehicle => {
  return { type: SET_SELECTED_VEHICLE, payload: { selectedVehicle } };
};

export const handleDelete = selectedRowKeys => {
  return async dispatch => {
    const result = await vehicleService.remove({ ids: selectedRowKeys });
    if (result.status === 401) {
      dispatch(logout());
    } else if (result.status === 200) {
      dispatch(deleteVehicleSuccess(selectedRowKeys));
    }
  };
};

export const handleEdit = record => {
  return dispatch => {
    dispatch(setSelectedVehicle(record));
    dispatch(setSelectedMenuKey('3'));
  };
};

export const getVehicles = () => {
  return async dispatch => {
    const result = await vehicleService.list();
    if (result.status === 401) {
      dispatch(logout());
    } else {
      const vehicles = normalizeData(result.data);
      dispatch(getVehiclesSuccess(vehicles));
    }
  };
};
