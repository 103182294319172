import React from 'react';
import {
  PlusOutlined,
  UnorderedListOutlined,
  SettingOutlined,
  FileTextOutlined,
  WalletOutlined,
  UserOutlined,
  TransactionOutlined,
  SwapOutlined,
  SolutionOutlined,
  AppstoreOutlined,
  HomeOutlined,
} from '@ant-design/icons';
import VehicleList from './views/VehicleList';
import AddVehicle from './views/AddVehicle';
import Services from './views/Services';
import AddService from './views/AddService';
import FileList from './views/FileList';
import FileService from './views/FileService';
import FileSupport from './views/FileSupport';
import ListCredits from './views/ListCredits';
import CreateCredit from './views/CreateCredit';
import UserManagement from './views/UserManagement';
import BuyCredit from './views/BuyCredit';
import PaymentPage from './components/PaymentPage';
import PaymentSuccess from './components/PaymentSuccess';
import PaymentFail from './components/PaymentFail';
import Transactions from './views/Transactions';
import UserProfile from './views/UserProfile';
import CreateReadmethod from './views/CreateReadmethod';
import ListReadmethod from './views/ListReadmethod';
import UserDetails from './views/UserDetails';
import MapperList from './views/MapperList';
import Dashboard from './views/Dashboard';

export const contentManagerRoutes = [
  {
    key: '1',
    name: 'List Vehicle',
    parentMenuName: 'ContentManagement',
    icon: <UnorderedListOutlined />,
    subMenuIcon: <AppstoreOutlined />,
    to: '/list',
    component: <VehicleList />,
    isMenuItem: true,
  },
  {
    key: '2',
    name: 'Add Vehicle',
    parentMenuName: 'ContentManagement',
    icon: <PlusOutlined />,
    to: '/add',
    component: <AddVehicle />,
    isMenuItem: true,
  },
  {
    key: '3',
    name: 'Edit Vehicle',
    parentMenuName: 'ContentManagement',
    icon: <UnorderedListOutlined />,
    to: '/edit',
    component: <AddVehicle />,
    isMenuItem: false,
  },
  {
    key: '4',
    name: 'List Tuning Type',
    parentMenuName: 'ContentManagement',
    icon: <UnorderedListOutlined />,
    subMenuIcon: <UnorderedListOutlined />,
    to: '/listTuningType',
    component: <Services />,
    isMenuItem: true,
  },
  {
    key: '5',
    name: 'Add Tuning Type',
    parentMenuName: 'ContentManagement',
    icon: <PlusOutlined />,
    to: '/addTuningType',
    component: <AddService />,
    isMenuItem: true,
  },
  {
    key: '6',
    name: 'Edit Tuning Type',
    parentMenuName: 'ContentManagement',
    icon: <SettingOutlined />,
    to: '/editTuningType',
    component: <AddService />,
    isMenuItem: false,
  },
  {
    key: 'listReadmethod',
    name: 'List Readmethod',
    parentMenuName: 'ContentManagement',
    icon: <UnorderedListOutlined />,
    to: '/listReadmethod',
    component: <ListReadmethod />,
    isMenuItem: true,
  },
  {
    key: 'createReadmethod',
    name: 'Create Readmethod',
    parentMenuName: 'ContentManagement',
    icon: <PlusOutlined />,
    to: '/createReadmethod',
    component: <CreateReadmethod />,
    isMenuItem: false,
  },
  {
    key: 'editReadmethod',
    name: 'Edit Readmethod',
    parentMenuName: 'ContentManagement',
    icon: <PlusOutlined />,
    to: '/editReadmethod',
    component: <CreateReadmethod />,
    isMenuItem: true,
  },
  {
    key: 'listCredit',
    name: 'List Credit',
    parentMenuName: 'ContentManagement',
    icon: <UnorderedListOutlined />,
    to: '/list-credits',
    component: <ListCredits />,
    isMenuItem: true,
  },
  {
    key: 'createCredit',
    name: 'Create Credit',
    parentMenuName: 'ContentManagement',
    icon: <PlusOutlined />,
    subMenuIcon: <PlusOutlined />,
    to: '/create-credits',
    component: <CreateCredit />,
    isMenuItem: true,
  },
  {
    key: 'editCredit',
    name: 'Edit Credit',
    parentMenuName: 'ContentManagement',
    icon: <PlusOutlined />,
    subMenuIcon: <PlusOutlined />,
    to: '/edit-credits',
    component: <CreateCredit />,
    isMenuItem: false,
  },
];

export const fileServiceAdminRoutes = [
  {
    key: 'fileList',
    name: 'File List',
    parentMenuName: 'FileService',
    icon: <FileTextOutlined />,
    subMenuIcon: <FileTextOutlined />,
    to: '/fileList',
    component: <FileList />,
    isMenuItem: true,
  },
  {
    key: 'fileService',
    name: 'File Service',
    parentMenuName: 'FileService',
    icon: <FileTextOutlined />,
    subMenuIcon: <FileTextOutlined />,
    to: '/fileService',
    component: <FileService />,
    isMenuItem: true,
  },
  {
    key: 'fileSupport',
    name: 'File Support',
    parentMenuName: 'FileService',
    icon: <FileTextOutlined />,
    to: '/fileSupport',
    component: <FileSupport />,
    isMenuItem: false,
  },
];

export const fileServiceRoutes = [
  {
    key: 'fileList',
    name: 'File List',
    parentMenuName: 'FileService',
    icon: <FileTextOutlined />,
    to: '/fileList',
    component: <FileList />,
    isMenuItem: true,
  },
  {
    key: 'fileService',
    name: 'File Service',
    parentMenuName: 'FileService',
    icon: <FileTextOutlined />,
    subMenuIcon: <FileTextOutlined />,
    to: '/fileService',
    component: <FileService />,
    isMenuItem: true,
  },
  {
    key: 'fileSupport',
    name: 'File Support',
    parentMenuName: 'FileService',
    icon: <FileTextOutlined />,
    to: '/fileSupport',
    component: <FileSupport />,
    isMenuItem: false,
  },
];

export const myCreditsRoutes = [
  {
    key: 'credit',
    name: 'Buy Credit',
    parentMenuName: 'MyCredits',
    icon: <WalletOutlined />,
    subMenuIcon: <WalletOutlined />,
    to: '/buy-credits',
    component: <BuyCredit />,
    isMenuItem: true,
  },
  {
    key: 'payment',
    name: 'Payment',
    parentMenuName: 'Payment',
    to: '/payment',
    component: <PaymentPage />,
    isMenuItem: false,
  },
  {
    key: 'paymentSuccess',
    name: 'Payment Success',
    parentMenuName: 'Payment',
    to: '/paymentSuccess',
    component: <PaymentSuccess />,
    isMenuItem: false,
  },
  {
    key: 'paymentFail',
    name: 'Payment Fail',
    parentMenuName: 'Payment',
    to: '/paymentFail',
    component: <PaymentFail />,
    isMenuItem: false,
  },
];

export const userManagementRoutes = [
  {
    key: 'userManagement',
    name: 'User Management',
    parentMenuName: 'UserManagement',
    icon: <UserOutlined />,
    subMenuIcon: <SolutionOutlined />,
    to: '/user-management',
    component: <UserManagement />,
    isMenuItem: true,
  },
  {
    key: 'mapperManagement',
    name: 'Mapper Management',
    parentMenuName: 'UserManagement',
    icon: <UserOutlined />,
    subMenuIcon: <SolutionOutlined />,
    to: '/mapper-management',
    component: <MapperList />,
    isMenuItem: true,
  },
  {
    key: 'userDetails',
    name: 'User Details',
    parentMenuName: 'UserManagement',
    icon: <UserOutlined />,
    subMenuIcon: <UserOutlined />,
    to: '/user-management',
    component: <UserDetails />,
    isMenuItem: false,
  },
];

export const transactionsRoutes = [
  {
    key: 'transactions',
    name: 'Transactions',
    parentMenuName: 'Transactions',
    icon: <TransactionOutlined />,
    subMenuIcon: <SwapOutlined />,
    to: '/transactions',
    component: <Transactions />,
    isMenuItem: true,
  },
];

export const userProfileRoutes = [
  {
    key: 'userProfile',
    name: 'User Profile',
    parentMenuName: 'UserProfile',
    icon: <UserOutlined />,
    subMenuIcon: <UserOutlined />,
    to: '/user-profile',
    component: <UserProfile />,
    isMenuItem: true,
  },
];

export const dashboardRoutes = [
  {
    key: 'dashboard',
    name: 'Dashboard',
    parentMenuName: 'Dashboard',
    icon: <HomeOutlined />,
    subMenuIcon: <HomeOutlined />,
    to: '/dashboard',
    component: <Dashboard />,
    isMenuItem: true,
  },
];
