import finalConfig from '../../config/config';
import {
  postRequest,
  getRequest,
  downloadRequest,
  putRequest,
} from '../client/client';
import { refreshTokenService } from './commonService';

export const submitFile = async (
  selectedVehicle,
  tuningInfo,
  tuningType,
  file,
) => {
  const url = `${finalConfig.FILE_SERVICES}`;

  const formData = new FormData();
  formData.append('file', file);
  formData.append('vehicleId', selectedVehicle.id);
  formData.append('serviceId', tuningType);
  formData.append('readmethodId', tuningInfo.readmethodId);
  formData.append('hardwareNumber', tuningInfo.hardwareNumber);
  formData.append('softwareNumber', tuningInfo.softwareNumber);
  formData.append('description', tuningInfo.description);
  formData.append('masterOrSlave', tuningInfo.masterSlave);

  const headers = { 'Content-Type': 'multipart/form-data' };
  let result;
  result = await postRequest(url, formData, { ...headers });
  if (result.status === 401) {
    await refreshTokenService();
    result = await postRequest(url, formData, { ...headers });
  }
  return result;
};

// export const getUserFiles = async () => {
//   const url = `${finalConfig.GET_USER_FILES}`;
//   let result;
//   result = await getRequest(url);
//   return result;
// };

export const listAll = async () => {
  const url = `${finalConfig.FILE_SERVICES}`;
  let result;
  result = await getRequest(url);
  if (result.status === 401) {
    await refreshTokenService();
    result = await getRequest(url);
  }
  return result;
};

export const download = async fileName => {
  const url = `${finalConfig.DOWNLOAD}`;
  let result;
  result = await downloadRequest(url, { fileName }, { responseType: 'blob' });
  if (result.status === 401) {
    await refreshTokenService();
    result = await downloadRequest(url, { fileName }, { responseType: 'blob' });
  }
  return result;
};

export const getUserComments = async id => {
  const url = `${finalConfig.COMMENTS}/${id}`;
  let result;
  result = await getRequest(url);
  if (result.status === 401) {
    await refreshTokenService();
    result = await getRequest(url);
  }
  return result;
};

export const sendComment = async comment => {
  const url = `${finalConfig.COMMENTS}`;
  let result;
  result = await postRequest(url, comment);
  if (result.status === 401) {
    await refreshTokenService();
    result = await postRequest(url, comment);
  }
  return result;
};

export const getLatestFiles = async () => {
  const url = `${finalConfig.GET_LATEST_FILES}`;
  let result;
  result = await getRequest(url);
  if (result.status === 401) {
    await refreshTokenService();
    result = await getRequest(url);
  }
  return result;
};

export const getAllHandlers = async () => {
  const url = `${finalConfig.GET_HANDLERS}`;
  let result;
  result = await getRequest(url);
  if (result.status === 401) {
    await refreshTokenService();
    result = await getRequest(url);
  }
  return result;
};

export const assignFile = async (handlerId, fileServiceId) => {
  const url = `${finalConfig.ASSIGN_HANDLER}`;
  const data = { handlerId, fileServiceId };
  let result;
  result = await postRequest(url, data);
  if (result.status === 401) {
    await refreshTokenService();
    result = await postRequest(url);
  }
  return result;
};

export const createReadmethod = async data => {
  const url = `${finalConfig.READMETHOD}`;
  let result;
  result = await postRequest(url, data);
  if (result.status === 401) {
    await refreshTokenService();
    result = await postRequest(url);
  }
  return result;
};

export const updateReadMethod = async (readmethod, id) => {
  const url = `${finalConfig.READMETHOD}/${id}`;
  let result;
  result = await putRequest(url, readmethod);
  if (result.status === 401) {
    await refreshTokenService();
    result = await putRequest(url, readmethod);
  }
  return result;
};

export const getReadmethods = async () => {
  const url = `${finalConfig.READMETHOD}`;
  let result;
  result = await getRequest(url);
  if (result.status === 401) {
    await refreshTokenService();
    result = await getRequest(url);
  }
  return result;
};

export const uploadTunedFile = async (id, file) => {
  const url = `${finalConfig.FILE_SERVICES}`;

  const formData = new FormData();
  formData.append('file', file);
  formData.append('id', id);

  const headers = { 'Content-Type': 'multipart/form-data' };
  let result;
  result = await putRequest(url, formData, { ...headers });
  if (result.status === 401) {
    await refreshTokenService();
    result = await putRequest(url, formData, { ...headers });
  }
  return result;
};

export const closeFile = async fileId => {
  const url = `${finalConfig.FILE_SERVICES}/closeFile`;
  const data = { fileId };
  let result;
  result = await postRequest(url, data);
  if (result.status === 401) {
    await refreshTokenService();
    result = await postRequest(url, data);
  }
  return result;
};
