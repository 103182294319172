import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Button, Card, Typography } from 'antd';
import { EditOutlined, UnorderedListOutlined } from '@ant-design/icons';

import CommonTable from '../../components/CommonTable';

import { formattedColumns } from '../../utils/commonUtils';
import { listReadmethodsColumns } from '../../constants';
import * as fileServiceActions from '../../actions/fileServiceActions';

const { Text } = Typography;

const ListReadmethod = ({ readmethods, getReadmethods, handleEdit }) => {
  useEffect(() => {
    async function loadCredits() {
      getReadmethods();
    }
    loadCredits();
  }, [getReadmethods]);

  const renderColumnActions = () => {
    return {
      render: (text, record) => (
        <Button
          record={record}
          type='primary'
          block
          icon={<EditOutlined />}
          onClick={() => handleEdit(record)}
        >
          Edit
        </Button>
      ),
    };
  };

  return (
    <Card
      title={
        <Text strong>
          <UnorderedListOutlined /> List Readmethods
        </Text>
      }
      style={{ minHeight: '77vh' }}
    >
      <CommonTable
        dataSource={readmethods}
        columns={formattedColumns(listReadmethodsColumns, {
          ...renderColumnActions(),
        })}
        rowSelection={null}
      />
    </Card>
  );
};

const mapStateToProps = state => {
  return {
    readmethods: state.fileService.readmethods,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getReadmethods: () => dispatch(fileServiceActions.getReadmethods()),
    handleEdit: record => dispatch(fileServiceActions.handleEdit(record)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ListReadmethod);
