import React from 'react';
import { connect } from 'react-redux';
import PerformanceChartContainer from '../../components/PerformanceChartContainer';
import SearchVehicle from '../../components/SearchVehicle';

const VSHomeComponent = ({ selectedVehicle }) => (
  <div className='home-page-wrapper content0-wrapper'>
    <div className='home-page content0'>
      <SearchVehicle />
      {selectedVehicle ? (
        <PerformanceChartContainer data={selectedVehicle} />
      ) : null}
    </div>
  </div>
);

const mapStateToProps = state => {
  return {
    selectedVehicle: state.searchVehicle.selectedVehicle,
  };
};

const mapDispatchToProps = () => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(VSHomeComponent);
