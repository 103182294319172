import React, { useState } from 'react';
import { Card, Row, Typography } from 'antd';
import { ClockCircleOutlined } from '@ant-design/icons';
import Clock from 'react-live-clock';

const { Text } = Typography;

let openTime = new Date();
openTime.setHours(8, 0, 0, 0);
let closeTime = new Date();
closeTime.setHours(18, 0, 0, 0);

const CurrentTime = () => {
  const [outOfDate, setOutOfDate] = useState(false);

  const onClockChange = (output, previousOutput, moment) => {
    if (output < closeTime.getTime() && output > openTime.getTime()) {
      setOutOfDate(false);
    } else {
      setOutOfDate(true);
    }
  };

  return (
    <Card style={{ height: '500px' }} title='Current Time'>
      <Row align='middle' justify='center'>
        <ClockCircleOutlined style={{ fontSize: '50px' }} />
      </Row>
      <Row align='middle' justify='center'>
        <Text>Current Time in Turkey is:</Text>
      </Row>
      <Row align='middle' justify='center'>
        <Clock
          format={'HH:mm:ss'}
          ticking={true}
          timezone={'Turkey'}
          onChange={onClockChange}
          style={{ fontSize: '3em', fontWeight: 600 }}
        />
      </Row>
      <Row align='middle' justify='center'>
        {outOfDate ? (
          <Text type='danger'>Closed Now</Text>
        ) : (
          <Text type='success'>Open</Text>
        )}
      </Row>
    </Card>
  );
};

export default CurrentTime;
