import finalConfig from '../../config/config';
import {
  postRequest,
  getRequest,
  loginRequest,
  putRequest
} from '../client/client';
import { refreshTokenService } from './commonService';

export const insert = async params => {
  const url = `${finalConfig.REGISTER}`;
  let result;
  result = await postRequest(url, params);
  // if (result.status === 401) {
  //   await refreshTokenService();
  //   result = await postRequest(url, params);
  // }
  return result;
};

export const getRole = async () => {
  const url = `${finalConfig.GET_ROLE}`;
  let result;
  result = await getRequest(url);
  return result;
};

export const login = async params => {
  const url = `${finalConfig.LOGIN}`;
  const result = await loginRequest(url, { ...params });
  return result;
};

export const update = async id => {
  const url = `${finalConfig.USER}/${id}`;
  let result;
  result = await getRequest(url);
  // if (result.status === 401) {
  //   await refreshTokenService();
  //   result = await getRequest(url);
  // }
  return result;
};

export const getUsers = async () => {
  const url = `${finalConfig.USERS}`;
  let result;
  result = await getRequest(url);
  if (result.status === 401) {
    await refreshTokenService();
    result = await getRequest(url);
  }
  return result;
};

export const setDiscountAmount = async data => {
  const url = `${finalConfig.SET_DISCOUNT}`;
  let result;
  result = await putRequest(url, data);
  if (result.status === 401) {
    await refreshTokenService();
    result = await putRequest(url, data);
  }
  return result;
};

export const getTransactions = async () => {
  const url = `${finalConfig.GET_TRANSACTIONS}`;
  let result;
  result = await getRequest(url);
  if (result.status === 401) {
    await refreshTokenService();
    result = await getRequest(url);
  }
  return result;
};

export const getTransactionsByUser = async () => {
  const url = `${finalConfig.GET_TRANSACTIONS}`;
  let result;
  result = await postRequest(url);
  if (result.status === 401) {
    await refreshTokenService();
    result = await postRequest(url);
  }
  return result;
};

export const getTransactionsByUserId = async id => {
  const url = `${finalConfig.GET_TRANSACTIONS}/${id}`;
  let result;
  result = await getRequest(url);
  if (result.status === 401) {
    await refreshTokenService();
    result = await getRequest(url);
  }
  return result;
};

export const refundUserCredit = async data => {
  const url = `${finalConfig.REFUND_USER_CREDIT}`;
  let result;
  result = await postRequest(url, data);
  if (result.status === 401) {
    await refreshTokenService();
    result = await postRequest(url, data);
  }
  return result;
};

export const claimUserCredit = async data => {
  const url = `${finalConfig.CLAIM_USER_CREDIT}`;
  let result;
  result = await postRequest(url, data);
  if (result.status === 401) {
    await refreshTokenService();
    result = await postRequest(url, data);
  }
  return result;
};

export const updateUserInfo = async data => {
  const url = `${finalConfig.UPDATE_USER_INFO}`;
  let result;
  result = await postRequest(url, data);
  if (result.status === 401) {
    await refreshTokenService();
    result = await postRequest(url, data);
  }
  return result;
};

export const changePassword = async data => {
  const url = `${finalConfig.UPDATE_USER_PASS}`;
  let result;
  result = await postRequest(url, data);
  if (result.status === 401) {
    await refreshTokenService();
    result = await postRequest(url, data);
  }
  return result;
};

export const getAllRoles = async () => {
  const url = `${finalConfig.ROLES}`;
  let result;
  result = await getRequest(url);
  if (result.status === 401) {
    await refreshTokenService();
    result = await getRequest(url);
  }
  return result;
};

export const assignRole = async (userId, roleId) => {
  const url = `${finalConfig.USERS}`;
  const data = { userId, roleId };
  let result;
  result = await putRequest(url, data);
  if (result.status === 401) {
    await refreshTokenService();
    result = await putRequest(url, data);
  }
  return result;
};

export const changeStatus = async (userId, status) => {
  const url = `${finalConfig.CHANGE_STATUS}`;
  const data = { userId, status };
  let result;
  result = await putRequest(url, data);
  if (result.status === 401) {
    await refreshTokenService();
    result = await putRequest(url, data);
  }
  return result;
};

export const updatePriority = async data => {
  const url = `${finalConfig.MAPPER_PRIORITY}`;
  let result;
  result = await postRequest(url, data);
  if (result.status === 401) {
    await refreshTokenService();
    result = await putRequest(url, data);
  }
  return result;
};
