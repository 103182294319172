import finalConfig from '../../config/config';
import { getRequest } from '../client/client';
import { refreshTokenService } from './commonService';

export const getOverview = async () => {
  const url = `${finalConfig.OVERVIEW}`;
  let result;
  result = await getRequest(url);
  if (result.status === 401) {
    await refreshTokenService();
    result = await getRequest(url);
  }
  return result;
};
