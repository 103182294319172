import * as modalActions from '../../actions/modalActions';

const defaultState = {
  isModalVisible: false,
  children: null
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = defaultState, action) => {
  switch (action.type) {
    case modalActions.OPEN_MODAL:
      return { ...state, ...action.payload };
    case modalActions.HANDLE_OK:
      return { ...state, isModalVisible: false, children: null };
    case modalActions.HANDLE_CANCEL:
      return { ...state, isModalVisible: false, children: null };
    default:
      return state;
  }
};
