import React from 'react';
import { connect } from 'react-redux';
import { Button, Modal } from 'antd';

import * as modalActions from '../../actions/modalActions';

const NotifModal = ({ isModalVisible, handleOk, handleCancel, children }) => {
  return (
    <>
      <Modal
        title='Notification'
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={[
          <Button key='ok' type='primary' onClick={handleOk}>
            OK
          </Button>
        ]}
      >
        {children}
      </Modal>
    </>
  );
};

const mapStateToProps = state => {
  return {
    isModalVisible: state.modal.isModalVisible,
    children: state.modal.children
  };
};

const mapDispatchToProps = dispatch => {
  return {
    handleOk: () => dispatch(modalActions.handleOk()),
    handleCancel: () => dispatch(modalActions.handleCancel())
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(NotifModal);
