export const HANDLE_OK = 'HANDLE_OK';
export const HANDLE_CANCEL = 'HANDLE_CANCEL';
export const OPEN_MODAL = 'OPEN_MODAL';

export const openModal = (isModalVisible, children) => {
  return { type: OPEN_MODAL, payload: { isModalVisible, children } };
};

export const handleOk = () => {
  return { type: HANDLE_OK };
};

export const handleCancel = () => {
  return { type: HANDLE_CANCEL };
};
