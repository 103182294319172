import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Button, Typography } from 'antd';

import DescriptionCard from './DescriptionCard';
import CommentForm from './CommentForm';
import CommentList from './CommentList';

import * as fileListActions from '../../actions/fileListActions';
import FileUpload from '../../components/FileUpload';

const { Text } = Typography;

const FileSupport = props => {
  const [file, setFile] = useState(null);
  const [submitting, setSubmitting] = useState(false);

  const onUploadFile = file => {
    setFile(file);
  };

  const sendComment = values => {
    setSubmitting(true);
    props.sendComment(values, props.selectedFile.id);
    setSubmitting(false);
  };

  const onUploadClick = () => {
    props.uploadTunedFile(props.selectedFile.id, file);
  };

  return (
    <React.Fragment>
      <DescriptionCard
        selectedFile={props.selectedFile}
        submitting={submitting}
        handleDownload={selectedFile => props.handleDownload(selectedFile)}
        handleDownloadTuned={selectedFile => props.handleDownloadTuned(selectedFile)}
      />
      {props.user.role === 'user' ? null : (
        <>
          <FileUpload onUpload={onUploadFile} />
          <Button
            loading={props.loading}
            type='primary'
            style={{ marginBottom: '2vh' }}
            onClick={onUploadClick}
          >
            Upload Requested File
          </Button>
          <Button
            type='primary'
            onClick={() => props.closeFile(props.selectedFile.id)}
            loading={props.closeFileLoading}
            style={{ marginLeft: '1vw' }}
          >
            Close File
          </Button>
          {props.fileCloseMsg && (
            <Text type='danger'>{props.fileCloseMsg}</Text>
          )}
          <Text
            type={props.isErr ? 'danget' : 'success'}
            style={{ marginLeft: '1vw' }}
          >
            {props.msg}
          </Text>
        </>
      )}

      <CommentList comments={props.comments} />
      <CommentForm sendComment={sendComment} />
    </React.Fragment>
  );
};

const mapStateToProps = state => {
  return {
    selectedFile: state.fileList.selectedFile,
    comments: state.fileList.comments,
    user: state.auth.user,
    loading: state.fileList.loading,
    msg: state.fileList.msg,
    isErr: state.fileList.isErr,
    fileCloseMsg: state.fileList.fileCloseMsg,
    closeFileLoading: state.fileList.closeFileLoading
  };
};

const mapDispatchToProps = dispatch => {
  return {
    sendComment: (values, fileServiceId) =>
      dispatch(fileListActions.sendComment(values, fileServiceId)),
    handleDownload: record => dispatch(fileListActions.handleDownload(record)),
    handleDownloadTuned: record => dispatch(fileListActions.handleDownloadTuned(record)),
    uploadTunedFile: (id, file) =>
      dispatch(fileListActions.uploadTunedFile(id, file)),
    closeFile: fileId => dispatch(fileListActions.closeFile(fileId))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(FileSupport);
