import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Card, Typography } from 'antd';

import ShortListItem from './ShortListItem';

import * as overviewActions from '../../actions/overviewActions';
import * as fileListActions from '../../actions/fileListActions';

const { Text } = Typography;

const UserFiles = ({ overview, getOverview, goToSupportPage }) => {
  useEffect(() => {
    getOverview();
  }, []);

  const handleGoToFile = file => {
    goToSupportPage(file);
  };

  return (
    <Card style={{ minHeight: '500px' }} title='User Files'>
      {overview ? (
        overview.files.map(file => (
          <ShortListItem file={file} goToFile={handleGoToFile} />
        ))
      ) : (
        <Text>No files Yet</Text>
      )}
    </Card>
  );
};

const mapStateToProps = state => {
  return {
    overview: state.overview.overview
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getOverview: () => dispatch(overviewActions.getOverview()),
    goToSupportPage: record => dispatch(fileListActions.handleSupport(record))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UserFiles);
