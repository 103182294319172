import { push } from 'connected-react-router';
import * as registerService from '../api/service/registerService';

export const REGISTER_FAIL = 'REGISTER_FAIL';

const registerFail = message => {
  return { type: REGISTER_FAIL, payload: { message } };
};

export const register = user => {
  return async dispatch => {
    try {
      const result = await registerService.register(user);
      if (result.status < 400) {
        dispatch(registerFail(''));
        dispatch(push('/login'));
      } else {
        dispatch(registerFail(result?.data?.message));
      }
    } catch (error) {
      dispatch(registerFail(error));
    }
  };
};
