import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Form, Select, Typography, Input, Card } from 'antd';

import * as tuningInfoActions from '../../actions/tuningInfoActions';
import * as fileServiceActions from '../../actions/fileServiceActions';

const { Text } = Typography;
const { Option } = Select;

const TuningInformation = props => {
  useEffect(() => {
    props.getReadmethods();
  }, []);
  return (
    <Card
      title={<Text strong>Tuning Information</Text>}
      style={{ marginBottom: '2vh' }}
    >
      <Form layout='vertical' size='large'>
        <Input.Group compact>
          <Form.Item
            label='Readmethod'
            name='readmethod'
            style={{ width: '48%' }}
          >
            <Select
              placeholder='Readmethod'
              onChange={value => props.setReadmethod(value)}
            >
              {props.readmethods.map(method => (
                <Option key={method.id} value={method.id}>
                  {method.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Input.Group>
        <Input.Group compact>
          <Form.Item
            label='Hardware Number'
            name='hardwareNumber'
            style={{ width: '48%', marginRight: '1vh' }}
          >
            <Input
              placeholder='Hardware Number'
              onChange={e => props.setHadnwareNumber(e.target.value)}
            />
          </Form.Item>
          <Form.Item
            label='Software Number'
            name='softwareNumber'
            style={{ width: '49%', marginRight: '1vh' }}
          >
            <Input
              placeholder='Software Number'
              onChange={e => props.setSoftwareNumber(e.target.value)}
            />
          </Form.Item>
        </Input.Group>
        <Input.Group compact>
          <Form.Item
            name='description'
            label='Note'
            style={{ width: '98%', marginRight: '1vh' }}
          >
            <Input.TextArea
              onChange={e => props.setDescription(e.target.value)}
            />
          </Form.Item>
        </Input.Group>
      </Form>
    </Card>
  );
};

const mapStateToProps = state => {
  return {
    tunings: state.tunings.tunings,
    readmethods: state.fileService.readmethods
  };
};

const mapDispatchToProps = dispatch => {
  return {
    // setMasterSlave: value => dispatch(tuningInfoActions.setMasterSlave(value)),
    getReadmethods: () => dispatch(fileServiceActions.getReadmethods()),
    setReadmethod: value => dispatch(tuningInfoActions.setReadmethod(value)),
    setHadnwareNumber: value =>
      dispatch(tuningInfoActions.setHadnwareNumber(value)),
    setSoftwareNumber: value =>
      dispatch(tuningInfoActions.setSoftwareNumber(value)),
    setDescription: value => dispatch(tuningInfoActions.setDescription(value))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TuningInformation);
