import React from 'react';
import { Typography } from 'antd';

const { Text } = Typography;

export const vehicleColumns = [
  {
    title: 'Name',
    dataIndex: 'name',
    sorter: (a, b) => columnSorter(a.name.toLowerCase(), b.name.toLowerCase()),
    sortDirections: ['descend', 'ascend'],
    key: 'name',
    width: '15%',
    search: true,
    isAction: false,
  },
  {
    title: 'Model',
    dataIndex: 'model',
    sorter: (a, b) =>
      columnSorter(a.model.toLowerCase(), b.model.toLowerCase()),
    sortDirections: ['descend', 'ascend'],
    key: 'model',
    search: true,
    isAction: false,
  },
  {
    title: 'Year',
    dataIndex: 'year',
    sorter: (a, b) => columnSorter(a.year.toLowerCase(), b.year.toLowerCase()),
    sortDirections: ['descend', 'ascend'],
    key: 'year',
    search: true,
    isAction: false,
  },
  {
    title: 'Engine Type',
    dataIndex: 'engineType',
    sorter: (a, b) =>
      columnSorter(a.engineType.toLowerCase(), b.engineType.toLowerCase()),
    sortDirections: ['descend', 'ascend'],
    key: 'engineType',
    search: true,
    isAction: false,
  },
  {
    title: 'Motor Type',
    dataIndex: 'motorType',
    sorter: (a, b) =>
      columnSorter(a.motorType.toLowerCase(), b.motorType.toLowerCase()),
    sortDirections: ['descend', 'ascend'],
    key: 'motorType',
    search: true,
    isAction: false,
  },
  {
    title: 'Standard Power',
    dataIndex: 'sPower',
    sorter: (a, b) => a.sPower - b.sPower,
    sortDirections: ['descend', 'ascend'],
    key: 'sPower',
    search: true,
    isAction: false,
    responsive: ['lg'],
  },
  {
    title: 'Tuned Power',
    dataIndex: 'tPower',
    sorter: (a, b) => a.tPower - b.tPower,
    sortDirections: ['descend', 'ascend'],
    key: 'tPower',
    search: true,
    isAction: false,
    responsive: ['lg'],
  },
  {
    title: 'Standard Torque',
    dataIndex: 'sTorque',
    sorter: (a, b) => a.sTorque - b.sTorque,
    sortDirections: ['descend', 'ascend'],
    key: 'sTorque',
    search: true,
    isAction: false,
    responsive: ['lg'],
  },
  {
    title: 'Tuned Torque',
    dataIndex: 'tTorque',
    sorter: (a, b) => a.tTorque - b.tTorque,
    sortDirections: ['descend', 'ascend'],
    key: 'tTorque',
    search: true,
    isAction: false,
    responsive: ['lg'],
  },
  {
    title: 'Edit',
    dataIndex: 'edit',
    search: false,
    isAction: true,
  },
];

export const tuningColumns = [
  {
    title: 'Name',
    dataIndex: 'name',
    sorter: (a, b) =>
      columnSorter(a.title.toLowerCase(), b.title.toLowerCase()),
    sortDirections: ['descend', 'ascend'],
    key: 'name',
    search: true,
    isAction: false,
  },
  {
    title: 'Description',
    dataIndex: 'description',
    sorter: (a, b) => a.description.toLowerCase() < b.description.toLowerCase(),
    sortDirections: ['descend', 'ascend'],
    key: 'description',
    search: true,
    isAction: false,
  },
  {
    title: 'Price',
    dataIndex: 'credit',
    sorter: (a, b) => a.credit - b.credit,
    sortDirections: ['descend', 'ascend'],
    key: 'price',
    search: true,
    isAction: false,
  },
  {
    title: 'Edit',
    dataIndex: 'edit',
    search: false,
    isAction: true,
  },
];

export const fileColumns = [
  {
    title: 'File No',
    dataIndex: 'id',
    key: 'id',
    search: true,
    width: '6%',
    render: (text, record) => `${record?.id}`,
    isAction: false,
  },
  {
    title: 'Vehicle',
    dataIndex: 'vehicle',
    sorter: (a, b) =>
      columnSorter(a.vehicle.name.toLowerCase(), b.vehicle.name.toLowerCase()),
    sortDirections: ['descend', 'ascend'],
    key: 'name',
    search: true,
    render: (text, record) => `${record?.vehicle?.name}`,
    isAction: false,
  },
  {
    title: 'User',
    dataIndex: 'user',
    sorter: (a, b) =>
      columnSorter(a.user?.name?.toLowerCase(), b.user?.name?.toLowerCase()),
    sortDirections: ['descend', 'ascend'],
    key: 'name',
    search: true,
    render: (text, record) => `${record?.user?.name} ${record?.user?.surname}`,
    isAction: false,
  },
  {
    title: 'Service Type',
    dataIndex: 'service',
    sorter: (a, b) =>
      columnSorter(
        a.service?.name.toLowerCase(),
        b.service?.name.toLowerCase(),
      ),
    sortDirections: ['descend', 'ascend'],
    key: 'name',
    search: true,
    render: (text, record) => `${record.service?.name}`,
    isAction: false,
  },
  {
    title: 'Credit',
    dataIndex: 'service',
    sorter: (a, b) =>
      columnSorter(
        a.service?.credit.toLowerCase(),
        b.service?.credit.toLowerCase(),
      ),
    sortDirections: ['descend', 'ascend'],
    key: 'credit',
    search: true,
    render: (text, record) => `${record.service?.credit}`,
    isAction: false,
  },
  {
    title: 'Hardware Number',
    dataIndex: 'hardwareNumber',
    sorter: (a, b) =>
      columnSorter(
        a.hardwareNumber.toLowerCase(),
        b.hardwareNumber.toLowerCase(),
      ),
    sortDirections: ['descend', 'ascend'],
    key: 'hardwareNumber',
    search: true,
    isAction: false,
  },
  {
    title: 'Software Number',
    dataIndex: 'softwareNumber',
    sorter: (a, b) =>
      columnSorter(
        a.softwareNumber.toLowerCase(),
        b.softwareNumber.toLowerCase(),
      ),
    sortDirections: ['descend', 'ascend'],
    key: 'softwareNumber',
    search: true,
    isAction: false,
  },
  {
    title: 'Status',
    dataIndex: 'status',
    sorter: (a, b) =>
      columnSorter(a.status.toLowerCase(), b.status.toLowerCase()),
    sortDirections: ['descend', 'ascend'],
    key: 'status',
    search: true,
    render: (text, record) => (
      <Text
        type={`${
          record.status === 'Created' || 'Tuned' ? 'success' : 'warning'
        }`}
      >
        {record.status}
      </Text>
    ),
    isAction: false,
  },
  {
    title: 'Handler',
    dataIndex: 'handler',
    sorter: (a, b) =>
      columnSorter(
        a.handler?.name?.toLowerCase(),
        b.handler?.name?.toLowerCase(),
      ),
    sortDirections: ['descend', 'ascend'],
    key: 'name',
    search: true,
    render: (text, record) => `${record.handler?.name}`,
    isAction: false,
  },
  {
    title: 'Create Date',
    dataIndex: 'createdAt',
    sorter: (a, b) =>
      columnSorter(
        new Date(a.createdAt).toLocaleDateString('tr'),
        new Date(b.createdAt).toLocaleDateString('tr'),
      ),
    sortDirections: ['descend', 'ascend'],
    key: 'createdAt',
    search: true,
    render: (text, record) =>
      `${new Date(record.createdAt).toLocaleDateString('tr')} ${new Date(
        record.createdAt,
      ).toLocaleTimeString('tr')}`,
    isAction: false,
  },
  {
    title: 'Operations',
    dataIndex: 'operations',
    width: '5%',
    search: false,
    isAction: true,
  },
];

export const creditTypesColumns = discount => [
  {
    title: 'Choose Your Package',
    dataIndex: 'amount',
    key: 'amount',
    search: false,
    isAction: false,
    render: (text, record) => `${record.amount} ${record.description}`,
  },
  {
    title: 'Price',
    dataIndex: 'price',
    key: 'price',
    search: false,
    isAction: false,
    render: (text, record) => {
      if (discount === 0) {
        return `${record.price} TL`;
      } else {
        return (
          <div>
            <Text delete code>
              {record.price} TL
            </Text>{' '}
            <Text code>
              {record.price - (record.price * discount) / 100} TL
            </Text>
          </div>
        );
      }
    },
  },
];

export const listCreditsColumns = [
  {
    title: 'Credit',
    dataIndex: 'amount',
    key: 'amount',
    search: false,
    isAction: false,
    render: (text, record) => `${record.amount} ${record.description}`,
  },
  {
    title: 'Price',
    dataIndex: 'price',
    key: 'price',
    search: false,
    isAction: false,
    render: (text, record) => `${record.price} TL`,
  },
  {
    title: 'Edit',
    dataIndex: 'edit',
    search: false,
    isAction: true,
  },
];

export const userManagementColumns = [
  {
    title: 'Name',
    dataIndex: 'name',
    sorter: (a, b) => columnSorter(a.name.toLowerCase(), b.name.toLowerCase()),
    sortDirections: ['descend', 'ascend'],
    key: 'name',
    search: true,
    isAction: false,
  },
  {
    title: 'Surname',
    dataIndex: 'surname',
    sorter: (a, b) =>
      columnSorter(a.surname.toLowerCase(), b.surname.toLowerCase()),
    sortDirections: ['descend', 'ascend'],
    key: 'surname',
    search: true,
    isAction: false,
    responsive: ['lg'],
  },
  {
    title: 'Email',
    dataIndex: 'email',
    sorter: (a, b) =>
      columnSorter(a.email.toLowerCase(), b.email.toLowerCase()),
    sortDirections: ['descend', 'ascend'],
    key: 'email',
    search: true,
    isAction: false,
    responsive: ['lg'],
  },
  {
    title: 'Status',
    dataIndex: 'status',
    sorter: (a, b) =>
      columnSorter(a.status.toLowerCase(), b.status.toLowerCase()),
    sortDirections: ['descend', 'ascend'],
    key: 'status',
    search: true,
    render: (text, record) => (
      <Text type={`${record.status === 'ACTIVE' ? 'success' : 'danger'}`}>
        {record.status}
      </Text>
    ),
    isAction: false,
    responsive: ['lg'],
  },
  {
    title: 'Role',
    dataIndex: 'role',
    sorter: (a, b) =>
      columnSorter(a.role.name.toLowerCase(), b.role.name.toLowerCase()),
    sortDirections: ['descend', 'ascend'],
    key: 'name',
    search: true,
    render: (text, record) => `${record.role.name}`,
    isAction: false,
    responsive: ['lg'],
  },
  {
    title: 'Credit',
    dataIndex: 'credit',
    sorter: (a, b) => a.credit.credit - b.credit.credit,
    sortDirections: ['descend', 'ascend'],
    key: 'credit',
    search: true,
    render: (text, record) => `${record.credit.credit}`,
    isAction: false,
    responsive: ['lg'],
  },
  {
    title: 'Discount',
    dataIndex: 'discount',
    sorter: (a, b) => a.discount - b.discount,
    sortDirections: ['descend', 'ascend'],
    key: 'discount',
    search: true,
    render: (text, record) => `${record.discount.discount}`,
    isAction: false,
    responsive: ['lg'],
  },
  {
    title: 'Priority',
    dataIndex: 'priority',
    sorter: (a, b) => a.priority - b.priority,
    sortDirections: ['descend', 'ascend'],
    key: 'priority',
    search: true,
    render: (text, record) => (
      <Text type={`${record.priority === 'PRIOR' ? 'success' : 'warning'}`}>
        {record.priority}
      </Text>
    ),
    isAction: false,
    responsive: ['lg'],
  },
  {
    title: 'Operations',
    dataIndex: 'operations',
    search: false,
    isAction: true,
  },
];

export const transactionsColumns = [
  {
    title: 'Name',
    dataIndex: 'user',
    sorter: (a, b) =>
      columnSorter(a.user.name.toLowerCase(), b.user.name.toLowerCase()),
    sortDirections: ['descend', 'ascend'],
    key: 'name',
    search: true,
    render: (text, record) => `${record.user.name}`,
    isAction: false,
  },
  {
    title: 'Surname',
    dataIndex: 'user',
    sorter: (a, b) =>
      columnSorter(a.user.surname.toLowerCase(), b.user.surname.toLowerCase()),
    sortDirections: ['descend', 'ascend'],
    key: 'surname',
    search: true,
    render: (text, record) => `${record.user.surname}`,
    isAction: false,
  },
  {
    title: 'Credit',
    dataIndex: 'credit',
    sorter: (a, b) => a.credit - b.credit,
    sortDirections: ['descend', 'ascend'],
    key: 'credit',
    search: true,
    isAction: false,
  },
  {
    title: 'Price',
    dataIndex: 'price',
    sorter: (a, b) => a.price - b.price,
    sortDirections: ['descend', 'ascend'],
    key: 'price',
    search: true,
    isAction: false,
    render: (text, record) => `${record.price} TL`,
  },
  {
    title: 'Type',
    dataIndex: 'type',
    sorter: (a, b) => columnSorter(a.type.toLowerCase(), b.type.toLowerCase()),
    sortDirections: ['descend', 'ascend'],
    key: 'type',
    search: true,
    isAction: false,
  },
  {
    title: 'Description',
    dataIndex: 'description',
    sorter: (a, b) =>
      columnSorter(a.description.toLowerCase(), b.description.toLowerCase()),
    sortDirections: ['descend', 'ascend'],
    key: 'description',
    search: true,
    isAction: false,
  },
  {
    title: 'Transaction Date',
    dataIndex: 'createdAt',
    sorter: (a, b) =>
      columnSorter(
        new Date(a.createdAt).toLocaleDateString('tr'),
        new Date(b.createdAt).toLocaleDateString('tr'),
      ),
    sortDirections: ['descend', 'ascend'],
    key: 'createdAt',
    search: true,
    render: (text, record) =>
      `${new Date(record.createdAt).toLocaleDateString('tr')} ${new Date(
        record.createdAt,
      ).toLocaleTimeString('tr')}`,
    isAction: false,
  },
];

export const latestFilesColumns = [
  {
    title: 'Vehicle',
    dataIndex: 'vehicle',
    key: 'vehicle',
    search: false,
    isAction: false,
  },
  {
    title: 'Service',
    dataIndex: 'service',
    key: 'service',
    search: false,
    isAction: false,
  },
  {
    title: 'Date',
    dataIndex: 'createDate',
    key: 'createDate',
    search: false,
    isAction: false,
    render: (text, record) =>
      new Date(parseInt(record.createDate)).toLocaleDateString('tr'),
  },
];

export const listReadmethodsColumns = [
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
    search: true,
    isAction: false,
    render: (text, record) => `${record.name}`,
  },
  {
    title: 'Description',
    dataIndex: 'description',
    key: 'description',
    search: true,
    isAction: false,
    render: (text, record) => `${record.description}`,
  },
  {
    title: 'Edit',
    dataIndex: 'edit',
    search: false,
    isAction: true,
  },
];

export const columnSorter = (a, b) => {
  console.log('sorter : ' + a);
  return a.localeCompare(b);
};

export const userStatus = [
  {
    id: 1,
    status: 'ACTIVE',
  },
  {
    id: 2,
    status: 'BLOCKED',
  },
];

export const creditTypes = [
  { key: 1, package: '1 Master Chiptuning File Credit', price: '100.00 TL' },
  { key: 2, package: '5 Master Chiptuning File Credit', price: '450.00 TL' },
  { key: 3, package: '10 Master Chiptuning File Credit', price: '800.00 TL' },
  { key: 4, package: '50 Master Chiptuning File Credit', price: '3500.00 TL' },
  { key: 5, package: '100 Master Chiptuning File Credit', price: '7000.00 TL' },
];
