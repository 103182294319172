import AppBar from './components/AppBar';
import AppContent from './components/AppContent';

import './App.less';

const App = () => {
  return (
    <div>
      <AppBar />
      <AppContent />
    </div>
  );
};

export default App;
