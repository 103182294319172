import React, { useEffect } from 'react';
import { Select, Typography, Col, Row } from 'antd';
import { connect } from 'react-redux';

import * as fileListActions from '../../actions/fileListActions';

const { Option } = Select;
const { Text } = Typography;

const AssignFile = ({ handlers, record, getAllHandlers, assignFile }) => {
  useEffect(() => {
    getAllHandlers();
  }, []);
  return (
    <Select
      style={{ width: '100%' }}
      onChange={value => assignFile(value, record.id)}
      placeholder='Assign'
    >
      {handlers.map(item => (
        <Option key={item.id} value={item.id}>
          <Row>
            <Col span={12}>
              <Text>{item.name}</Text>
            </Col>
            <Col span={12}>
              <Text type={`${item.status === 'ACTIVE' ? 'success' : 'danger'}`}>
                {item.status}
              </Text>
            </Col>
          </Row>
        </Option>
      ))}
    </Select>
  );
};

const mapStateToProps = state => {
  return {
    handlers: state.fileList.handlers
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getAllHandlers: () => dispatch(fileListActions.getAllHandlers()),
    assignFile: (handlerId, fileServiceId) =>
      dispatch(fileListActions.assignFile(handlerId, fileServiceId))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AssignFile);
