import axios from 'axios';

export const getRequest = url =>
  axios
    .get(url, { headers: { Authorization: localStorage.getItem('token') } })
    .then(res => {
      return res;
    })
    .catch(err => {
      return err.response;
    });

export const postRequest = (url, data, config) =>
  axios
    .post(url, data, {
      maxContentLength: 5242880,
      maxBodyLength: 5242880,
      headers: { Authorization: localStorage.getItem('token'), ...config },
    })
    .then(res => {
      return res;
    })
    .catch(err => {
      return err.response;
    });

export const downloadRequest = (url, data, config) =>
  axios({
    method: 'post',
    url: url,
    data: data,
    headers: { Authorization: localStorage.getItem('token') },
    ...config,
  })
    .then(res => {
      return res;
    })
    .catch(err => {
      return err.response;
    });

export const loginRequest = (url, data, headers) => {
  return axios
    .post(url, data)
    .then(res => {
      return res;
    })
    .catch(err => {
      return err.response;
    });
};

export const putRequest = (url, data, config) =>
  axios
    .put(url, data, {
      headers: { Authorization: localStorage.getItem('token'), ...config },
    })
    .then(res => {
      return res;
    })
    .catch(err => {
      return err.response;
    });

export const deleteRequest = (url, data, headers) => {
  return axios
    .delete(url, {
      headers: { Authorization: localStorage.getItem('token') },
      data,
    })
    .then(res => {
      return res;
    })
    .catch(err => {
      return err.response;
    });
};

export const refreshTokenRequest = url =>
  axios
    .get(url, {
      headers: { Authorization: localStorage.getItem('refreshToken') },
    })
    .then(res => {
      return res;
    })
    .catch(err => {
      return err;
    });
