import React from 'react';
import { Result, Button } from 'antd';

const PaymentFail = () => {
  return (
    <Result
      status='error'
      title='Payment Failed'
      subTitle='Please check your card balance and resubmit. If you think there is a problem please contact by email.'
      extra={[
        <Button
          key='buy'
          onClick={() => {
            window.location.href = '/app';
          }}
        >
          OK
        </Button>,
      ]}
    />
  );
};

export default PaymentFail;
