import * as sidebarActions from '../../actions/sidebarActions';

const defaultState = {
  selectedMenuKey: '1',
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = defaultState, action) => {
  switch (action.type) {
    case sidebarActions.SET_SELECTED_MENU_KEY:
      return { ...state, selectedMenuKey: action.payload.key };
    default:
      return state;
  }
};
