import React from 'react';
import { PieChart, Pie, Cell, Legend } from 'recharts';

const PerformanceChart = ({ data, type }) => {
  const COLORS = ['#faad14', '#43a4ce', '#52c41a', '#f5222d'];
  const pieData = [{ name: `Standart ${type}`, value: data[`s${type}`] }, { name: `Tuned ${type}`, value: data[`t${type}`] }];
  const percentage = Math.round(data[`t${type}`] * 100 / data[`s${type}`] - 100);
  const differance = data[`t${type}`] - data[`s${type}`];
  const unit = type === 'Power' ? 'hp' : 'Nm';
  return (
    <PieChart width={400} height={400}>
      <Pie
        data={pieData}
        dataKey="value"
        cx={200}
        cy={200}
        innerRadius={60}
        outerRadius={90}
        fill="#82ca9d"
        label={({ value }) => `${value} ${unit}`}
      >
        {
          pieData.map((item, index) => <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />)
        }
      </Pie>
      <text x={205} y={210} textAnchor="middle" dominantBaseline="middle" fontSize={24}>
        {percentage} %
      </text>
      <Legend
        layout="vertical"
        payload={
          [...pieData.map((item, index) =>
          ({
            type: "square",
            value: `${item.name}: ${item.value} ${unit}`,
            color: COLORS[index % COLORS.length]
          })), { type: 'square', value: `Difference: ${differance} ${unit}`, color: COLORS[2] }]} />
    </PieChart>
  );
};

export default PerformanceChart;
