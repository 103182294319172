import * as userService from '../api/service/userService';
import { logout } from './authActions';
import { normalizeData } from '../utils/commonUtils';
import { setSelectedMenuKey } from './sidebarActions';

export const SET_USERS = 'SET_USERS';
export const UPDATE_USER_DISCOUNT = 'UPDATE_USER_DISCOUNT';
export const SET_TRANSACTIONS = 'SET_TRANSACTIONS';
export const REFUND = 'REFUND';
export const UPDATE_USER_CREDIT = 'UPDATE_USER_CREDIT';
export const UPDATE_USER_ROLE_SUCCESS = 'UPDATE_USER_ROLE_SUCCESS';
export const UPDATE_USER_ROLE_FAIL = 'UPDATE_USER_ROLE_FAIL';
export const GET_ROLES_SUCCESS = 'GET_ROLES_SUCCESS';
export const GET_ROLES_FAIL = 'GET_ROLES_FAIL';
export const CLEAR_MESSAGE = 'CLEAR_MESSAGE';
export const UPDATE_USER_STATUS_SUCCESS = 'UPDATE_USER_STATUS_SUCCESS';
export const UPDATE_USER_STATUS_FAIL = 'UPDATE_USER_STATUS_FAIL';
export const SET_SELECTED_USER = 'SET_SELECTED_USER';
export const SET_SELECTED_USER_BY_ID = 'SET_SELECTED_USER_BY_ID';

export const setUsers = users => {
  return { type: SET_USERS, payload: { users } };
};

export const updateUserDiscount = (userId, discount) => {
  return { type: UPDATE_USER_DISCOUNT, payload: { userId, discount } };
};

export const setTransactions = transactions => {
  return { type: SET_TRANSACTIONS, payload: { transactions } };
};

export const refund = message => {
  return { type: REFUND, payload: { message } };
};

export const updateUserCredit = (id, refundAmount) => {
  return { type: UPDATE_USER_CREDIT, payload: { id, refundAmount } };
};

export const updateUserRoleSuccess = (userId, role) => {
  return { type: UPDATE_USER_ROLE_SUCCESS, payload: { userId, role } };
};

export const updateUserRoleFail = msg => {
  return { type: UPDATE_USER_ROLE_FAIL, payload: { msg } };
};

export const getRoleSuccess = roles => {
  return { type: GET_ROLES_SUCCESS, payload: { roles } };
};

export const getRolesFail = msg => {
  return { type: GET_ROLES_FAIL, payload: { msg } };
};

export const clearMessage = () => {
  return { type: CLEAR_MESSAGE };
};

export const updateUserStatusSuccess = (userId, status) => {
  return { type: UPDATE_USER_STATUS_SUCCESS, payload: { userId, status } };
};

export const updateUserStatusFail = msg => {
  return { type: UPDATE_USER_STATUS_FAIL, payload: { msg } };
};

export const setSelectedUser = selectedUser => {
  return { type: SET_SELECTED_USER, payload: { selectedUser } };
};

export const setSelectedUserById = id => {
  return { type: SET_SELECTED_USER_BY_ID, payload: { id } };
};

export const getUsers = () => {
  return async dispatch => {
    dispatch(clearMessage());
    const result = await userService.getUsers();
    if (result.status === 401) {
      dispatch(logout());
    } else if (result.status < 400) {
      dispatch(setUsers(normalizeData(result.data)));
    } else {
      console.log('hata');
    }
  };
};

export const getMappers = () => {
  return async dispatch => {
    dispatch(clearMessage());
    const result = await userService.getUsers();
    if (result.status === 401) {
      dispatch(logout());
    } else if (result.status < 400) {
      dispatch(
        setUsers(normalizeData(result.data.filter(f => f.role.id === 4)))
      );
    } else {
      console.log('hata');
    }
  };
};

export const setDiscount = data => {
  return async dispatch => {
    dispatch(clearMessage());
    const result = await userService.setDiscountAmount(data);
    if (result.status === 401) {
      dispatch(logout());
    } else if (result.status < 400) {
      dispatch(updateUserDiscount(data.id, data.discountAmount));
      dispatch(setSelectedMenuKey('userManagement'));
    } else {
      console.log('refund error');
    }
  };
};

export const getTransactions = (user, selectedUser) => {
  return async dispatch => {
    dispatch(clearMessage());
    let result;
    if (selectedUser) {
      result = await userService.getTransactionsByUserId(selectedUser.id);
    } else {
      if (user.role === 'sysAdmin') {
        result = await userService.getTransactions();
      } else {
        result = await userService.getTransactionsByUser();
      }
    }
    if (result.status === 401) {
      dispatch(logout());
    } else if (result.status < 400) {
      dispatch(setTransactions(normalizeData(result.data)));
    } else {
      dispatch(setTransactions([]));
    }
  };
};

export const handleRefund = data => {
  return async dispatch => {
    dispatch(clearMessage());
    const result = await userService.refundUserCredit(data);
    if (result.status === 401) {
      dispatch(logout());
    } else if (result.status < 400) {
      dispatch(refund(result.data));
      dispatch(updateUserCredit(data.id));
      dispatch(setSelectedMenuKey('userManagement'));
    } else {
      dispatch(refund(result.data));
    }
  };
};

export const handleClaim = data => {
  return async dispatch => {
    dispatch(clearMessage());
    const result = await userService.claimUserCredit(data);
    if (result.status === 401) {
      dispatch(logout());
    } else if (result.status < 400) {
      dispatch(updateUserCredit(data.id, data.claimAmount * -1));
      dispatch(setSelectedMenuKey('userManagement'));
    } else {
      dispatch(refund(result.data));
    }
  };
};

export const getAllRoles = () => {
  return async dispatch => {
    dispatch(clearMessage());
    const result = await userService.getAllRoles();
    if (result.status === 401) {
      dispatch(logout());
    } else if (result.status < 400) {
      dispatch(getRoleSuccess(result.data));
    } else {
      dispatch(getRolesFail(result.data?.message));
    }
  };
};

export const assignRole = (userId, roleId) => {
  return async dispatch => {
    dispatch(clearMessage());
    const result = await userService.assignRole(userId, roleId);
    if (result.status === 401) {
      dispatch(logout());
    } else if (result.status < 400) {
      dispatch(updateUserRoleSuccess(userId, result.data));
      dispatch(setSelectedMenuKey('userManagement'));
    } else {
      dispatch(updateUserRoleFail(result.data?.message));
    }
  };
};

export const changeStatus = (userId, status) => {
  return async dispatch => {
    dispatch(clearMessage());
    const result = await userService.changeStatus(userId, status);
    if (result.status === 401) {
      dispatch(logout());
    } else if (result.status < 400) {
      dispatch(updateUserStatusSuccess(userId, status));
      dispatch(setSelectedMenuKey('userManagement'));
    } else {
      dispatch(updateUserStatusFail(result.data?.message));
    }
  };
};

export const onDetailsClick = record => {
  return dispatch => {
    dispatch(setSelectedUser(record));
    dispatch(setSelectedMenuKey('userDetails'));
  };
};

export const handleUserNotif = id => {
  return async dispatch => {
    const result = await userService.getUsers();
    if (result.status === 401) {
      dispatch(logout());
    } else if (result.status < 400) {
      dispatch(setUsers(normalizeData(result.data)));
    } else {
      console.log('hata');
    }
    dispatch(setSelectedUser(result.data.find(f => f.id === id)));
    dispatch(setSelectedMenuKey('userDetails'));
  };
};

export const handleChangeMapperPriority = data => {
  return async dispatch => {
    const result = await userService.updatePriority(data);
    if (result.status === 401) {
      dispatch(logout());
    } else if (result.status < 400) {
      // dispatch(updateUserStatusSuccess(userId, status));
      dispatch(setSelectedMenuKey('userManagement'));
    } else {
      // dispatch(updateUserStatusFail(result.data?.message));
    }
  };
};
