import finalConfig from '../../config/config';
import { getRequest, postRequest } from '../client/client';
import { refreshTokenService } from './commonService';

export const login = async params => {
  const url = `${finalConfig.LOGIN}`;
  let result;
  result = await postRequest(url, params);
  if (result.status === 401) {
    await refreshTokenService();
    result = await postRequest(url, params);
  }
  localStorage.setItem('token', result.data.token);
  localStorage.setItem('refreshToken', result.data.refreshToken);
  return result;
};

export const rejectToken = async () => {
  const url = `${finalConfig.REJECT_TOKEN}`;
  let result;
  result = await getRequest(url);
  return result;
};
