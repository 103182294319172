import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Form, Input, Button, Card, Typography, InputNumber } from 'antd';
import { UnorderedListOutlined } from '@ant-design/icons';

import * as addVehicleActions from '../../actions/addVehicleActions';

const { Text } = Typography;

const AddVehicle = props => {
  const [form] = Form.useForm();
  const [isEdit] = useState(props.selectedVehicle !== null ? true : false);

  useEffect(() => {
    const { selectedVehicle } = props;
    if (isEdit) {
      form.setFieldsValue({ ...selectedVehicle });
    }
  });

  const onFinish = values => {
    if (isEdit) {
      props.updateVehicle(values, props.selectedVehicle.id);
    } else {
      props.addVehicle(values);
    }
  };

  return (
    <Card
      title={
        <Text strong>
          <UnorderedListOutlined />
          {isEdit === false ? 'Add Vehicle' : 'Edit Vehicle'}
        </Text>
      }
      style={{ minHeight: '77vh' }}
    >
      <Form
        form={form}
        onFinish={onFinish}
        size='large'
        wrapperCol={{ span: 18 }}
        labelCol={{ span: 3 }}
        hideRequiredMark
      >
        <Form.Item label='Vehicle Model'>
          <Input.Group compact>
            <Form.Item
              style={{ width: '33%' }}
              name='name'
              rules={[
                {
                  required: true,
                  message: 'Vehicle Name is Required',
                },
              ]}
            >
              <Input placeholder='Vehicle Name' style={{ width: '100%' }} />
            </Form.Item>
            <Form.Item
              name='model'
              style={{ width: '33%' }}
              rules={[
                {
                  required: true,
                  message: 'Vehicle Model is Required',
                },
              ]}
            >
              <Input placeholder='Vehice Model' style={{ width: '100%' }} />
            </Form.Item>
            <Form.Item
              style={{ width: '34%' }}
              name='year'
              rules={[
                {
                  required: true,
                  message: 'Vehicle Year is Required',
                },
              ]}
            >
              <Input placeholder='Vehicle Year' style={{ width: '100%' }} />
            </Form.Item>
          </Input.Group>
        </Form.Item>
        <Form.Item
          name='engineType'
          label='Engine Type'
          rules={[
            {
              required: true,
              message: 'Engine Type is Required',
            },
          ]}
        >
          <Input placeholder='Engine Type' />
        </Form.Item>
        <Form.Item
          name='motorType'
          label='Motor Type'
          rules={[
            {
              required: true,
              message: 'Motor Type is Required',
            },
          ]}
        >
          <Input placeholder='Motor Type' />
        </Form.Item>
        <Form.Item label='Power'>
          <Input.Group compact>
            <Form.Item
              style={{ width: '50%' }}
              name='sPower'
              rules={[
                {
                  type: 'number',
                  required: true,
                  message: 'Standart Power is Required',
                },
              ]}
            >
              <InputNumber
                style={{ width: '100%' }}
                placeholder='Standard Power'
              />
            </Form.Item>
            <Form.Item
              style={{ width: '50%' }}
              name='tPower'
              rules={[
                {
                  type: 'number',
                  required: true,
                  message: 'Tuned Power is Required',
                },
              ]}
            >
              <InputNumber
                style={{ width: '100%' }}
                placeholder='Tuned Power'
              />
            </Form.Item>
          </Input.Group>
        </Form.Item>
        <Form.Item label='Torque'>
          <Input.Group compact>
            <Form.Item
              style={{ width: '50%' }}
              name='sTorque'
              rules={[
                {
                  type: 'number',
                  required: true,
                  message: 'Standard Torque is Required',
                },
              ]}
            >
              <InputNumber
                style={{ width: '100%' }}
                placeholder='Standard Torque'
              />
            </Form.Item>
            <Form.Item
              style={{ width: '50%' }}
              name='tTorque'
              rules={[
                {
                  type: 'number',
                  required: true,
                  message: 'Tuned Torque is Required',
                },
              ]}
            >
              <InputNumber
                style={{ width: '100%' }}
                placeholder='Tuned Torque'
              />
            </Form.Item>
          </Input.Group>
        </Form.Item>
        <Form.Item label='Specifications'>
          <Input.Group>
            <Form.Item
              name='cylinderCapacity'
              rules={[
                {
                  required: true,
                  message: 'Cylinder Capacity is Required',
                },
              ]}
            >
              <Input
                style={{ width: '100%' }}
                placeholder='Cylinder capacity'
              />
            </Form.Item>
            <Form.Item
              name='compression'
              rules={[
                {
                  required: true,
                  message: 'Compression is Required',
                },
              ]}
            >
              <Input style={{ width: '100%' }} placeholder='Compression' />
            </Form.Item>
            <Form.Item
              name='typeEcu'
              rules={[
                {
                  required: true,
                  message: 'Type Ecu is Required',
                },
              ]}
            >
              <Input style={{ width: '100%' }} placeholder='Type ecu' />
            </Form.Item>
            <Form.Item
              name='boreXStroke'
              rules={[
                {
                  required: true,
                  message: 'BoreXStroke is Required',
                },
              ]}
            >
              <Input style={{ width: '100%' }} placeholder='Bore X Stroke' />
            </Form.Item>
            <Form.Item
              name='engineCode'
              rules={[
                {
                  required: true,
                  message: 'Engine Code is Required',
                },
              ]}
            >
              <Input style={{ width: '100%' }} placeholder='Engine Code' />
            </Form.Item>
          </Input.Group>
        </Form.Item>
        <Form.Item
          wrapperCol={{
            offset: 3,
            span: 18,
          }}
        >
          <Button type='primary' htmlType='submit'>
            Submit
          </Button>
          {isEdit ? (
            <Button
              style={{ marginLeft: '1vh' }}
              type='danger'
              onClick={() => props.handleCancel()}
            >
              Cancel
            </Button>
          ) : null}
          {props.errMessage ? <Text color='danger'></Text> : null}
        </Form.Item>
      </Form>
    </Card>
  );
};

const mapStateToProps = state => {
  return {
    selectedVehicle: state.listVehicle.selectedVehicle,
    errMessage: state.listVehicle.errMessage,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    addVehicle: vehicle => dispatch(addVehicleActions.addVehicle(vehicle)),
    updateVehicle: (vehicle, id) =>
      dispatch(addVehicleActions.updateVehicle(vehicle, id)),
    handleCancel: () => dispatch(addVehicleActions.handleCancel()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddVehicle);
