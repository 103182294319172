import * as fileListActions from '../../actions/fileListActions';

const defaultState = {
  files: [],
  comments: [],
  selectedFile: null,
  handlers: [],
  isErr: false,
  msg: '',
  loading: false,
  fileCloseMsg: null,
  closeFileLoading: false,
  latestFiles: null
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = defaultState, action) => {
  switch (action.type) {
    case fileListActions.SET_USER_FILES:
      return { ...state, ...action.payload };
    case fileListActions.SET_SELECTED_FILE:
      return { ...state, selectedFile: action.payload.file };
    case fileListActions.SET_USER_COMMENTS:
      return { ...state, ...action.payload };
    case fileListActions.ADD_COMMENT:
      return {
        ...state,
        comments: [...state.comments, action.payload.comment]
      };
    case fileListActions.GET_HANDLERS_SUCCESS:
      return { ...state, ...action.payload };
    case fileListActions.GET_HANDLERS_FAIL:
      return { ...state, ...action.payload };
    case fileListActions.ASSIGN_FILE_SUCCESS:
      return {
        ...state,
        files: [
          ...state.files.map(item =>
            item.id === action.payload.file.id
              ? { ...item, handler: { ...action.payload.file.handler } }
              : { ...item }
          )
        ]
      };
    case fileListActions.UPLOAD_TUNED_FILE_SUCCESS:
      return {
        ...state,
        isErr: false,
        msg: 'Tuned File Uploaded Succesfully',
        selectedFile: {
          ...state.selectedFile,
          tunedFileName: action.payload.file?.tunedFileName
        }
      };
    case fileListActions.UPLOAD_TUNED_FILE_FAIL:
      return { ...state, ...action.payload, isErr: true };
    case fileListActions.SET_LOADING_STATUS:
      return { ...state, loading: action.payload.status };
    case fileListActions.FILE_CLOSE_SUCCESS:
      return { ...state, fileCloseMsg: null };
    case fileListActions.FILE_CLOSE_FAIL:
      return { ...state, fileCloseMsg: action.payload.msg };
    case fileListActions.CLOSE_FILE_LOADING:
      return { ...state, closeFileLoading: action.payload.status };
    case fileListActions.GET_LATEST_FILE_SUCCESS:
      return { ...state, ...action.payload };
    case fileListActions.GET_LATEST_FILES_FAIL:
      return { ...state, latestFiles: null };
    default:
      return state;
  }
};
