import finalConfig from '../../config/config';
import { refreshTokenRequest } from '../client/client';

export const refreshTokenService = async () => {
  const url = `${finalConfig.REFRESH_TOKEN}`;
  try {
    const result = await refreshTokenRequest(url);
    localStorage.setItem('token', result.data.token);
    localStorage.setItem('refreshToken', result.data.refreshToken);
    return result;
  } catch (error) {
    return { status: 401 };
  }
};
