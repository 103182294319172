import * as tuningService from '../api/service/tuningService';
import { setSelectedMenuKey } from './sidebarActions';
import { normalizeData } from '../utils/commonUtils';
import { logout } from './authActions';

export const ADD_TUNING_SUCCESS = 'ADD_TUNING_SUCCESS';
export const GET_TUNINGS_SUCCESS = 'GET_TUNINGS_SUCCESS';
export const DELETE_TUNING_SUCCESS = 'DELETE_TUNING_SUCCE';
export const SET_SELECTED_TUNING = 'SET_SELECTED_TUNING';
export const SET_TUNING_TYPE = 'SET_TUNING_TYPE';
export const addTuningSuccess = () => {
  return { type: ADD_TUNING_SUCCESS };
};

export const getTuningsSuccess = tunings => {
  return { type: GET_TUNINGS_SUCCESS, payload: { tunings } };
};

export const deleteTuningSuccess = selectedRowKeys => {
  return { type: DELETE_TUNING_SUCCESS, payload: { selectedRowKeys } };
};

export const setSelectedTuning = selectedTuning => {
  return { type: SET_SELECTED_TUNING, payload: { selectedTuning } };
};

export const updateTuningSucess = () => {
  return { type: ADD_TUNING_SUCCESS };
};

export const setTuningType = tuningType => {
  return { type: SET_TUNING_TYPE, payload: { tuningType } };
};

export const handleDelete = selectedRowKeys => {
  return async dispatch => {
    const result = await tuningService.remove(selectedRowKeys);
    if (result.status === 401) {
      dispatch(logout());
    } else if (result.status === 200) {
      dispatch(deleteTuningSuccess(selectedRowKeys));
    }
  };
};

export const handleCancel = () => {
  return dispatch => {
    dispatch(setSelectedTuning(null));
    dispatch(setSelectedMenuKey('4'));
  };
};

export const handleEdit = record => {
  return dispatch => {
    dispatch(setSelectedTuning(record));
    dispatch(setSelectedMenuKey('6'));
  };
};

export const updateTuning = (tuning, id) => {
  return async dispatch => {
    const result = await tuningService.update(tuning, id);
    if (result.status === 401) {
      dispatch(logout());
    } else {
      dispatch(updateTuningSucess());
      dispatch(setSelectedMenuKey('4'));
    }
  };
};

export const saveTuningType = tuning => {
  return async dispatch => {
    const result = await tuningService.insert(tuning);
    if (result.status === 401) {
      dispatch(logout());
    } else {
      dispatch(addTuningSuccess());
      dispatch(setSelectedMenuKey('4'));
    }
  };
};

export const getTunings = () => {
  return async dispatch => {
    const result = await tuningService.list();
    if (result.status === 401) {
      dispatch(logout());
    } else {
      const tunings = normalizeData(result.data);
      dispatch(getTuningsSuccess(tunings));
    }
  };
};
