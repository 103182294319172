import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Badge } from 'antd';
import { NotificationOutlined } from '@ant-design/icons';

import * as notificationActions from '../../actions/notificationActions';

const NotificationBadge = ({ notifCount, getUserNotifications }) => {
  useEffect(() => {
    getUserNotifications();
    const interval = setInterval(() => {
      getUserNotifications();
    }, 60000);
    return () => {
      clearInterval(interval);
    };
  }, [getUserNotifications]);
  return (
    <div>
      <Badge count={notifCount}>
        <NotificationOutlined />
      </Badge>
    </div>
  );
};

const mapStateToProps = state => {
  return {
    notifCount: state.notif.notifCount,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getUserNotifications: () =>
      dispatch(notificationActions.getUserNotifications()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(NotificationBadge);
