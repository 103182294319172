import finalConfig from '../../config/config';
import { postRequest } from '../client/client';
import { refreshTokenService } from './commonService';

export const register = async user => {
  const url = `${finalConfig.REGISTER}`;
  let result;
  result = await postRequest(url, user);
  if (result.status === 401) {
    await refreshTokenService();
    result = await postRequest(url, user);
  }
  return result;
};
