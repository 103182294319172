import * as fileService from '../api/service/fileService';
import { logout, updateCredit } from './authActions';
import { setSelectedVehicle } from './vehicleListActions';
import { clearTuningInfo } from './tuningInfoActions';
import { setTuningType } from './tuningTypeActions';
import { setSelectedMenuKey } from './sidebarActions';
import * as modalActions from './modalActions';

export const SET_SUBMIT_BUTTON_LOADING = 'SET_SUBMIT_BUTTON_LOADING';
export const SUBMIT_FAIL = 'SUBMIT_FAIL';
export const SUBMIT_SUCCESS = 'SUBMIT_SUCCESS';
export const CREATE_READMETHOD_SUCCESS = 'CREATE_READMETHOD_SUCCESS';
export const CREATE_READMETHOD_FAIL = 'CREATE_READMETHOD_FAIL';
export const GET_METHODS_SUCCESS = 'GET_METHODS_SUCCESS';
export const GET_METHODS_FAIL = 'GET_METHODS_FAIL';
export const SET_SELECTED_READ_METHOD = 'SET_SELECTED_READ_METHOD';
export const UPDATE_READMETHOD_SUCCESS = 'UPDATE_READMETHOD_SUCCESS';
export const UPDATE_READMETHOD_FAIL = 'UPDATE_READMETHOD_FAIL';

export const setLoading = status => {
  return { type: SET_SUBMIT_BUTTON_LOADING, payload: { status } };
};

export const submitFail = message => {
  return { type: SUBMIT_FAIL, payload: { message } };
};

export const submitSuccess = () => {
  return { type: SUBMIT_SUCCESS };
};

export const createReadmethodSucces = () => {
  return { type: CREATE_READMETHOD_SUCCESS };
};

export const createReadmethodFail = message => {
  return { type: CREATE_READMETHOD_FAIL, payload: { message } };
};

export const getMethodsSuccess = readmethods => {
  return { type: GET_METHODS_SUCCESS, payload: { readmethods } };
};

export const getMethodsfail = () => {
  return { type: GET_METHODS_FAIL };
};

export const setSelectedReadMethod = selectedReadmethod => {
  return { type: SET_SELECTED_READ_METHOD, payload: { selectedReadmethod } };
};

export const sumbitFile = (
  selectedVehicle,
  tuningInfo,
  tuningType,
  file,
  notifContent,
) => {
  return async dispatch => {
    dispatch(setLoading(true));
    const result = await fileService.submitFile(
      selectedVehicle,
      tuningInfo,
      tuningType,
      file,
    );
    if (result) {
      if (result.status === 401) {
        dispatch(logout());
      } else if (result.status < 400) {
        dispatch(setSelectedVehicle(null));
        dispatch(updateCredit(result.data.credit));
        dispatch(clearTuningInfo());
        dispatch(setTuningType(null));
        dispatch(setSelectedMenuKey('fileList'));
        dispatch(modalActions.openModal(true, notifContent));
        dispatch(submitSuccess());
      } else {
        dispatch(submitFail(result.data.message));
      }
    } else {
      dispatch(submitFail());
    }
    dispatch(setLoading(false));
  };
};

export const createReadmethod = values => {
  return async dispatch => {
    try {
      dispatch(setLoading(true));
      const result = await fileService.createReadmethod(values);
      if (result.status === 401) {
        dispatch(logout());
      } else if (result.status < 400) {
        dispatch(createReadmethodSucces());
      } else {
        dispatch(createReadmethodFail(result.data.message));
      }
    } catch (error) {
      dispatch(createReadmethodFail(error));
    } finally {
      dispatch(setLoading(false));
    }
  };
};

const updateReadMethodSucces = () => {
  return { type: UPDATE_READMETHOD_SUCCESS };
};

const updateReadMethodFail = message => {
  return { type: UPDATE_READMETHOD_FAIL, payload: { message } };
};

export const updateReadMethod = (readmethod, id) => {
  return async dispatch => {
    try {
      dispatch(setLoading(true));
      const result = await fileService.updateReadMethod(readmethod, id);
      if (result.status === 401) {
        dispatch(logout());
      } else if (result.status < 400) {
        dispatch(updateReadMethodSucces());
        dispatch(setSelectedMenuKey('listReadmethod'));
      } else {
        dispatch(updateReadMethodFail(result.data.message));
      }
    } catch (error) {
      dispatch(updateReadMethodFail(error));
    } finally {
      dispatch(setLoading(false));
    }
  };
};

export const getReadmethods = () => {
  return async dispatch => {
    try {
      const result = await fileService.getReadmethods();
      if (result.status === 401) {
        dispatch(logout());
      } else if (result.status < 400) {
        dispatch(getMethodsSuccess(result.data));
      } else {
        dispatch(getMethodsfail());
      }
    } catch (error) {
      dispatch(getMethodsfail());
    }
  };
};

export const handleEdit = record => {
  return dispatch => {
    dispatch(setSelectedReadMethod(record));
    dispatch(setSelectedMenuKey('editReadmethod'));
  };
};
