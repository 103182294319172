import React from 'react';
import { Input, Form, Button, Card, Typography } from 'antd';
import { CommentOutlined } from '@ant-design/icons';

const { TextArea } = Input;
const { Text } = Typography;

const CommentForm = props => {
  const [form] = Form.useForm();

  const onFinish = values => {
    props.sendComment(values);
    form.resetFields();
  };

  return (
    <Card
      style={{ marginBottom: '2vh' }}
      title={
        <Text strong>
          <CommentOutlined /> Add Comment
        </Text>
      }
    >
      <Form
        form={form}
        onFinish={onFinish}
        wrapperCol={{ span: 18 }}
        labelCol={{ span: 3 }}
      >
        <Form.Item
          label='Title'
          name='title'
          rules={[{ required: true, message: 'Please input title' }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label='Comment'
          name='comment'
          rules={[{ required: true, message: 'Please input title' }]}
        >
          <TextArea rows={4} />
        </Form.Item>
        <Form.Item wrapperCol={{ offset: 3, span: 18 }}>
          <Button htmlType='submit' loading={props.submitting} type='primary'>
            Add Comment
          </Button>
        </Form.Item>
      </Form>
    </Card>
  );
};

export default CommentForm;
