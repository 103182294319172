import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Card, Typography, Input, Button, Form } from 'antd';
import { WalletOutlined } from '@ant-design/icons';

import * as fileServiceActions from '../../actions/fileServiceActions';

const { Text } = Typography;

const layout = {
  labelCol: { span: 3 },
  wrapperCol: { span: 18 },
};

const CreateReadmethod = ({
  loading,
  createReadmethod,
  updateReadMethod,
  selectedReadmethod,
}) => {
  const [isEdit, setIsEdit] = useState(
    selectedReadmethod !== null ? true : false,
  );

  const [form] = Form.useForm();

  useEffect(() => {
    if (isEdit) {
      form.setFieldsValue({ ...selectedReadmethod });
    }
  });

  const onFinish = values => {
    if (isEdit) {
      updateReadMethod(values, selectedReadmethod.id);
    } else {
      createReadmethod(values);
    }
    form.resetFields();
  };

  return (
    <Card
      title={
        <Text strong>
          <WalletOutlined /> Create Readmethod
        </Text>
      }
      style={{ minHeight: '77vh' }}
    >
      <Form
        form={form}
        {...layout}
        name='create-readmethod'
        onFinish={onFinish}
      >
        <Form.Item
          name={['name']}
          label='Method Name'
          rules={[{ required: true, message: 'Method name is required' }]}
        >
          <Input style={{ width: '100%' }} />
        </Form.Item>
        <Form.Item
          name={['description']}
          label='Method Description'
          rules={[
            { required: true, message: 'Method description is required' },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item wrapperCol={{ ...layout.wrapperCol, offset: 3 }}>
          <Button type='primary' htmlType='submit' loading={loading}>
            Submit
          </Button>
        </Form.Item>
      </Form>
    </Card>
  );
};

const mapStateToProps = state => {
  return {
    loading: state.fileService.loading,
    selectedReadmethod: state.fileService.selectedReadmethod,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    createReadmethod: values =>
      dispatch(fileServiceActions.createReadmethod(values)),
    updateReadMethod: (readmethod, id) =>
      dispatch(fileServiceActions.updateReadMethod(readmethod, id)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateReadmethod);
