import React from 'react';
import { Card, Col, Row } from 'antd';

const Hours = () => {
  return (
    <Card style={{ height: '500px' }} title='Open Hours'>
      <Row>
        <Col span={12}>Monday</Col>
        <Col span={10}>08:00 AM - 06:00 PM</Col>
      </Row>
      <Row>
        <Col span={12}>Tuesday</Col>
        <Col span={10}>08:00 AM - 06:00 PM</Col>
      </Row>
      <Row>
        <Col span={12}>Wednesday</Col>
        <Col span={10}>08:00 AM - 06:00 PM</Col>
      </Row>
      <Row>
        <Col span={12}>Thursday</Col>
        <Col span={10}>08:00 AM - 06:00 PM</Col>
      </Row>
      <Row>
        <Col span={12}>Friday</Col>
        <Col span={10}>08:00 AM - 06:00 PM</Col>
      </Row>
      <Row>
        <Col span={12}>Saturday</Col>
        <Col span={10}>08:00 AM - 06:00 PM</Col>
      </Row>
      <Row>
        <Col span={12}>Sunday</Col>
        <Col span={10}>Closed</Col>
      </Row>
    </Card>
  );
};

export default Hours;
