import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Card, Typography, Button } from 'antd';
import { WalletOutlined } from '@ant-design/icons';

import CommonTable from '../../components/CommonTable';

import { formattedColumns } from '../../utils/commonUtils';
import { creditTypesColumns } from '../../constants';
import * as creditsActions from '../../actions/creditsActions';

const { Text } = Typography;

const BuyCredit = ({
  creditsForBuy,
  getCredits,
  handleBuyCredit,
  failMessage,
  discount,
  buyLoading
}) => {
  const [selectedRowKeys, setSelectedRowKeys] = useState('');

  useEffect(() => {
    async function loadCredits() {
      getCredits();
    }
    loadCredits();
  }, [getCredits]);

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedRowKeys(selectedRowKeys);
    },
    getCheckboxProps: record => ({
      disabled: record.name === 'Disabled User', // Column configuration not to be checked
      name: record.name,
    }),
  };
  return (
    <Card
      title={
        <Text strong>
          <WalletOutlined /> Buy Credits
        </Text>
      }
      style={{ minHeight: '77vh' }}
    >
      <CommonTable
        dataSource={creditsForBuy}
        columns={formattedColumns(creditTypesColumns(discount))}
        rowSelection={{
          type: 'radio',
          ...rowSelection,
        }}
      />
      <Button
        size='large'
        type='primary'
        style={{ marginTop: '2vh', float: 'right' }}
        loading={buyLoading}
        onClick={() => handleBuyCredit(selectedRowKeys)}
      >
        BUY
      </Button>
      {failMessage === '' ? null : (
        <Text type='danger' strong>
          {failMessage}
        </Text>
      )}
    </Card>
  );
};

const mapStateToProps = state => {
  return {
    creditsForBuy: state.credits.creditsForBuy,
    failMessage: state.credits.failMessage,
    discount: state.credits.discount,
    buyLoading: state.credits.buyLoading
  };
};

const mapDispatchToProps = dispatch => {
  return {
    handleBuyCredit: selectedRowKeys =>
      dispatch(creditsActions.handleBuyCredit(selectedRowKeys)),
    getCredits: () => dispatch(creditsActions.getCredits()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(BuyCredit);
