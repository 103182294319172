import { logout } from './authActions';

import * as vehicleService from '../api/service/vehicleService';
import { normalizeData } from '../utils/commonUtils';

export const SET_SELECTED = 'SET_SELECTED';
export const GET_VEHICLE_NAMES = 'GET_VEHICLE_NAMES';
export const GET_VEHICLE_MODELS = 'GET_VEHICLE_MODELS';
export const GET_VEHICLE_YEARS = 'GET_VEHICLE_YEARS';
export const GET_VEHICLE_ENGINE_TYPES = 'GET_VEHICLE_ENGINE_TYPES';
export const GET_VEHICLE_MOTOR_TYPES = 'GET_VEHICLE_MOTOR_TYPES';
export const SET_ALL_VEHICLES = 'SET_ALL_VEHICLES';

export const getVehicleNamesSuccess = vehicleNames => {
  return { type: GET_VEHICLE_NAMES, payload: { vehicleNames } };
};

export const getVehicleModelssSuccess = models => {
  return { type: GET_VEHICLE_MODELS, payload: { models } };
};

export const getVehicleYearsSuccess = years => {
  return { type: GET_VEHICLE_YEARS, payload: { years } };
};

export const getVehicleEngineTypesSuccess = engineTypes => {
  return { type: GET_VEHICLE_ENGINE_TYPES, payload: { engineTypes } };
};

export const getVehicleMotorTypesSuccess = motorTypes => {
  return { type: GET_VEHICLE_MOTOR_TYPES, payload: { motorTypes } };
};

export const getSelectedVehicleSuccess = selectedVehicle => {
  return { type: SET_SELECTED, payload: { selectedVehicle } };
};

export const setAllVehicles = allVehicles => {
  return { type: SET_ALL_VEHICLES, payload: { allVehicles } };
};

export const getVehicleNames = () => {
  return async dispatch => {
    const result = await vehicleService.getVehicleSearch();
    if (result.status === 401) {
      dispatch(logout());
    } else if (result.status < 400) {
      const vehicleNames = normalizeData(result.data);
      dispatch(setAllVehicles(vehicleNames));
      dispatch(
        getVehicleNamesSuccess([
          ...new Set(vehicleNames.map(item => item.name))
        ])
      );
    }
  };
};

export const getVehicleModels = name => {
  return async dispatch => {
    const result = await vehicleService.getVehicleSearch({ name });
    if (result.status === 401) {
      dispatch(logout());
    } else {
      const vehicleModels = normalizeData(result.data);
      dispatch(
        getVehicleModelssSuccess([
          ...new Set(vehicleModels.map(item => item.model))
        ])
      );
    }
  };
};

export const getVehicleYears = (name, model) => {
  return async dispatch => {
    const result = await vehicleService.getVehicleSearch({ name, model });
    if (result.status === 401) {
      dispatch(logout());
    } else {
      const vehicleYears = normalizeData(result.data);
      dispatch(
        getVehicleYearsSuccess([
          ...new Set(vehicleYears.map(item => item.year))
        ])
      );
    }
  };
};

export const getVehicleEngineTypes = (name, model, year) => {
  return async dispatch => {
    const result = await vehicleService.getVehicleSearch({ name, model, year });
    if (result.status === 401) {
      dispatch(logout());
    } else {
      const engineTypes = normalizeData(result.data);
      dispatch(
        getVehicleEngineTypesSuccess([
          ...new Set(engineTypes.map(item => item.engineType))
        ])
      );
    }
  };
};

export const getVehicleMotorTypes = (name, model, year, engineType) => {
  return async dispatch => {
    const result = await vehicleService.getVehicleSearch({
      name,
      model,
      year,
      engineType
    });
    if (result.status === 401) {
      dispatch(logout());
    } else {
      const motorTypes = normalizeData(result.data);
      dispatch(
        getVehicleMotorTypesSuccess([
          ...new Set(motorTypes.map(item => item.motorType))
        ])
      );
    }
  };
};

export const getSelectedVehicle = param => {
  return async dispatch => {
    const result = await vehicleService.getVehicleSearch({
      name: param.selectedName,
      model: param.selectedModel,
      year: param.selectedYear,
      engineType: param.selectedEngineType,
      motorType: param.selectedMotorType
    });
    if (result.status === 401) {
      dispatch(logout());
    } else {
      const selectedVehicle = normalizeData(result.data);
      dispatch(getSelectedVehicleSuccess(selectedVehicle[0]));
    }
  };
};
