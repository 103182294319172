import finalConfig from '../../config/config';
import {
  getRequest,
  postRequest,
  deleteRequest,
  putRequest,
} from '../client/client';
import { refreshTokenService } from './commonService';

export const list = async () => {
  const url = `${finalConfig.TUNING}`;
  let result;
  result = await getRequest(url);
  if (result.status === 401) {
    await refreshTokenService();
    result = await getRequest(url);
  }
  return result;
};

export const insert = async tuning => {
  const url = `${finalConfig.TUNING}`;
  let result;
  result = await postRequest(url, tuning);
  if (result.status === 401) {
    await refreshTokenService();
    result = await postRequest(url, tuning);
  }
  return result;
};

export const update = async (tuning, id) => {
  const url = `${finalConfig.TUNING}/${id}`;
  let result;
  result = await putRequest(url, tuning);
  if (result.status === 401) {
    await refreshTokenService();
    result = await putRequest(url, tuning);
  }
  return result;
};

export const remove = async selectedRowKeys => {
  const url = `${finalConfig.TUNING}`;
  let result;
  result = await deleteRequest(url, selectedRowKeys);
  if (result.status === 401) {
    await refreshTokenService();
    result = await deleteRequest(url, selectedRowKeys);
  }
  return result;
};
