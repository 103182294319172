import React from 'react';
import PerformanceChart from './PerformanceChart';
import { Row, Col, Typography, Form } from 'antd';

const { Title } = Typography;

const PerformanceChartContainer = props => {
  return (
    <React.Fragment>
      <Row justify='center' align='middle'>
        <Title
          level={3}
        >{`${props.data.name} ${props.data.model} ${props.data.year} ${props.data.engineType} ${props.data.motorType}`}</Title>
      </Row>
      <Row justify='center' align='middle'>
        <Col xs={24} xl={12}>
          <PerformanceChart data={props.data} type='Power' />
        </Col>
        <Col xs={24} xl={12}>
          <PerformanceChart data={props.data} type='Torque' />
        </Col>
      </Row>
      <Row justify='center' align='middle' style={{ marginTop: '2vh' }}>
        <Title level={3}>Ozellikler</Title>
      </Row>
      <Form.Item
        label='Silindir Kapasitesi'
        wrapperCol={{ span: 12 }}
        labelCol={{ span: 6, offset: 6 }}
      >
        <Title level={5}>{props.data.cylinderCapacity} cc</Title>
      </Form.Item>
      <Form.Item
        label='Sıkıştırma'
        wrapperCol={{ span: 12 }}
        labelCol={{ span: 6, offset: 6 }}
      >
        <Title level={5}>{props.data.compression}</Title>
      </Form.Item>
      <Form.Item
        label='TypeEcu'
        wrapperCol={{ span: 12 }}
        labelCol={{ span: 6, offset: 6 }}
      >
        <Title level={5}>{props.data.typeEcu}</Title>
      </Form.Item>
      <Form.Item
        label='BoreXStroke'
        wrapperCol={{ span: 12 }}
        labelCol={{ span: 6, offset: 6 }}
      >
        <Title level={5}>{props.data.boreXStroke}</Title>
      </Form.Item>
    </React.Fragment>
  );
};

export default PerformanceChartContainer;
