import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Button, Typography } from 'antd';

import SearchVehicle from '../../components/SearchVehicle';
import TuningInformation from '../../components/TuningInformation';
import TuningType from '../../components/TuningType';
import FileUpload from '../../components/FileUpload';
import PerformanceChartContainer from '../../components/PerformanceChartContainer';

import * as fileServiceActions from '../../actions/fileServiceActions';

const { Text } = Typography;

let openTime = new Date();
openTime.setHours(8, 0, 0, 0);
let closeTime = new Date();
closeTime.setHours(18, 0, 0, 0);
const currentDate = new Date().getTime();

const FileService = props => {
  const [file, setFile] = useState(null);

  const notifContent = (
    <Text>
      {currentDate < closeTime.getTime() && currentDate > openTime.getTime()
        ? 'Your file will be handled in 30 minutes'
        : 'Now we are closed?? Your file will be handled next bussiness day.'}
    </Text>
  );

  const handleSubmit = e => {
    const { selectedVehicle, tuningInfo, tuningType } = props;
    if (selectedVehicle && tuningInfo && tuningType && file) {
      props.sumbitFile(
        selectedVehicle,
        tuningInfo,
        tuningType,
        file,
        notifContent
      );
    } else {
      props.submitFail('All fields must be filled correctly!!');
    }
  };

  const onUploadFile = file => {
    setFile(file);
  };

  return (
    <React.Fragment>
      <SearchVehicle />
      {props.selectedVehicle ? (
        <PerformanceChartContainer data={props.selectedVehicle} />
      ) : null}
      <TuningInformation />
      <TuningType />
      <FileUpload onUpload={onUploadFile} />
      <Button
        size='large'
        type='primary'
        onClick={handleSubmit}
        loading={props.loading}
      >
        SUBMIT
      </Button>
      <Text style={{ marginLeft: '1vw' }} type='danger'>
        {props.message}
      </Text>
    </React.Fragment>
  );
};

const mapStateToProps = state => {
  return {
    loading: state.fileService.loading,
    user: state.auth.user,
    selectedVehicle: state.searchVehicle.selectedVehicle,
    tuningInfo: {
      masterSlave: state.tuningInfo.masterSlave,
      readmethodId: state.tuningInfo.readmethodId,
      hardwareNumber: state.tuningInfo.hardwareNumber,
      softwareNumber: state.tuningInfo.softwareNumber,
      description: state.tuningInfo.description
    },
    tuningType: state.tunings.tuningType,
    message: state.fileService.message
  };
};

const mapDispatchToProps = dispatch => {
  return {
    submitFail: message => dispatch(fileServiceActions.submitFail(message)),
    sumbitFile: (selectedVehicle, tuningInfo, tuningType, file, notifContent) =>
      dispatch(
        fileServiceActions.sumbitFile(
          selectedVehicle,
          tuningInfo,
          tuningType,
          file,
          notifContent
        )
      )
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(FileService);
