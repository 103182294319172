import * as registerActions from '../../actions/registerActions';

const defaultState = {
  message: '',
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = defaultState, action) => {
  switch (action.type) {
    case registerActions.REGISTER_FAIL:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};
