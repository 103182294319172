import React from 'react';
import { Col, Row } from 'antd';
import UserFiles from './UserFiles';
import Hours from './Hours';
import CurrentTime from './CurrentTime';

const Dashboard = () => {
  return (
    <div>
      <Row gutter={[12, 12]}>
        <Col span={12} xl={12} xs={24}>
          <UserFiles />
        </Col>
        <Col span={6} xl={6} xs={24}>
          <Hours />
        </Col>
        <Col span={6} xl={6} xs={24}>
          <CurrentTime />
        </Col>
      </Row>
    </div>
  );
};

export default Dashboard;
