import * as tuningInfoActions from '../../actions/tuningInfoActions';

const defaultState = {
  masterSlave: '',
  readmethodId: '',
  hardwareNumber: '',
  softwareNumber: '',
  description: ''
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = defaultState, action) => {
  switch (action.type) {
    case tuningInfoActions.SET_SETMASTERSLAVE:
      return { ...state, ...action.payload };
    case tuningInfoActions.SET_SETREADMETHOD:
      return { ...state, ...action.payload };
    case tuningInfoActions.SET_SETHADNWARENUMBER:
      return { ...state, ...action.payload };
    case tuningInfoActions.SET_SETSOFTWARENUMBER:
      return { ...state, ...action.payload };
    case tuningInfoActions.SET_DESCRIPTION:
      return { ...state, ...action.payload };
    case tuningInfoActions.CLEAR_TUNING_INFO:
      return { ...defaultState };
    default:
      return state;
  }
};
