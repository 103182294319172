import finalConfig from '../../config/config';
import { getRequest, deleteRequest } from '../client/client';
import { refreshTokenService } from './commonService';

export const getUserNotifications = async () => {
  const url = `${finalConfig.NOTIFICATIONS}`;
  let result;
  result = await getRequest(url);
  if (result.status === 401) {
    await refreshTokenService();
    result = await getRequest(url);
  }
  return result;
};

export const handleNotifSeen = async id => {
  const url = `${finalConfig.NOTIFICATIONS}/${id}`;
  let result;
  result = await deleteRequest(url);
  if (result.status === 401) {
    await refreshTokenService();
    result = await deleteRequest(url);
  }
  return result;
};
