import finalConfig from '../../config/config';
import {
  getRequest,
  postRequest,
  deleteRequest,
  putRequest,
} from '../client/client';
import { refreshTokenService } from './commonService';

export const list = async () => {
  const url = `${finalConfig.VEHICLES}`;
  let result;
  result = await getRequest(url);
  if (result) {
    if (result.status === 401) {
      await refreshTokenService();
      result = await getRequest(url);
    }
    return result;
  }
  return { status: 500 };
};

export const insert = async vehicle => {
  const url = `${finalConfig.VEHICLES}`;
  let result;
  result = await postRequest(url, vehicle);
  if (result) {
    if (result.status === 401) {
      await refreshTokenService();
      result = await postRequest(url, vehicle);
    }
    return result;
  }
  return { status: 500 };
};

export const update = async (vehicle, id) => {
  const url = `${finalConfig.VEHICLES}/${id}`;
  let result;
  result = await putRequest(url, vehicle);
  if (result) {
    if (result.status === 401) {
      await refreshTokenService();
      result = await putRequest(url, vehicle);
    }
    return result;
  }
  return { status: 500 };
};

export const remove = async ids => {
  const url = `${finalConfig.VEHICLES}`;
  let result;
  result = await deleteRequest(url, ids);
  if (result) {
    if (result.status === 401) {
      await refreshTokenService();
      result = await deleteRequest(url, ids);
    }
    return result;
  }
  return { status: 500 };
};

export const getVehicleSearch = async data => {
  const url = `${finalConfig.VEHICLE_SEARCH}`;
  let result;
  result = await postRequest(url, data);
  if (result) {
    if (result.status === 401) {
      await refreshTokenService();
      result = await postRequest(url, data);
    }
    return result;
  }
  return { status: 500 };
};

export const getSelectedVehicle = async id => {
  const url = `${finalConfig.VEHICLE_SELECTED}`;
  const data = { id };
  let result;
  result = await postRequest(url, data);
  if (result) {
    if (result.status === 401) {
      await refreshTokenService();
      result = await postRequest(url, data);
    }
    return result;
  }
  return { status: 500 };
};
