import * as overviewActions from '../../actions/overviewActions';

const defaultState = {
  overview: null,
  err: null
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = defaultState, action) => {
  switch (action.type) {
    case overviewActions.OVERVIEW_SUCCESS:
      return { ...state, ...action.payload };
    case overviewActions.OVERVIEW_FAIL:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};
