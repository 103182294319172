/* eslint-disable import/no-anonymous-default-export */
import * as vehicleListActions from '../../actions/vehicleListActions';
import * as addVehicleActions from '../../actions/addVehicleActions';

const defaultState = {
  vehicles: [],
  selectedRowKeys: [],
  selectedVehicle: null,
  errMessage: null,
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case vehicleListActions.GET_VEHICLES_SUCCESS:
      return { ...state, ...action.payload };
    case vehicleListActions.DELETE_VEHICLE_SUCCESS:
      const { selectedRowKeys } = action.payload;

      selectedRowKeys.map(key => {
        state.vehicles.splice(
          state.vehicles.findIndex(item => item.id === key),
          1
        );
        return key;
      });

      return { ...state, vehicles: [...state.vehicles] };
    case vehicleListActions.SET_SELECTED_VEHICLE:
      return { ...state, ...action.payload };
    case addVehicleActions.ADD_VEHICLE_FAIL:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};
