import { Row } from 'antd';

import LoginForm from './LoginForm';

const Login = () => {
  return (
    <Row
      type='flex'
      justify='center'
      align='middle'
      style={{ marginTop: '20%' }}
    >
      <LoginForm />
    </Row>
  );
};

export default Login;
