import { Select } from 'antd';
import React from 'react';

const { Option } = Select;

const AssignRole = ({ roles, record, assignRole }) => {
    return <Select style={{ width: '100%' }} onChange={(value) => assignRole(record.id, value)} placeholder="Assign">
        {roles.map(item =>
            <Option key={item.id} value={item.id}>
                {item.name}
            </Option>)}
    </Select>;
};

export default AssignRole;