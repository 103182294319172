import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Card, Typography, Button } from 'antd';
import { UnorderedListOutlined } from '@ant-design/icons';

import CommonTable from '../../components/CommonTable';

import { formattedColumns } from '../../utils/commonUtils';
import { userManagementColumns } from '../../constants';

import * as userActions from '../../actions/userActions';

const { Text } = Typography;

const UserManagement = ({
  users,
  getUsers,
  getMappers,
  onDetailsClick,
  type
}) => {
  useEffect(() => {
    if (type) {
      getMappers();
    } else {
      getUsers();
    }
  }, []);

  const renderColumnActions = () => {
    return {
      render: (text, record) => (
        <Button type='primary' onClick={() => onDetailsClick(record)}>
          Details
        </Button>
      )
    };
  };
  return (
    <Card
      title={
        <Text strong>
          <UnorderedListOutlined /> List Users{' '}
          <Text type='success'>{`${users.length} ${
            type ? 'mappers' : 'users'
          }`}</Text>
        </Text>
      }
      style={{ minHeight: '77vh' }}
    >
      <CommonTable
        dataSource={users}
        columns={formattedColumns(
          type
            ? userManagementColumns
            : userManagementColumns.filter(f => f.dataIndex !== 'priority'),
          {
            ...renderColumnActions()
          }
        )}
        rowSelection={null}
      />
    </Card>
  );
};

const mapStateToProps = state => {
  return {
    users: state.user.users,
    roles: state.user.roles
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getMappers: () => dispatch(userActions.getMappers()),
    getUsers: () => dispatch(userActions.getUsers()),
    setDiscount: (userId, discountAmount) =>
      dispatch(userActions.setDiscount(userId, discountAmount)),
    handleRefund: (id, refundAmount) =>
      dispatch(userActions.handleRefund(id, refundAmount)),
    onDetailsClick: record => dispatch(userActions.onDetailsClick(record))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UserManagement);
