import { connect } from 'react-redux';
import { Form, Input, Button, Select, InputNumber, Typography } from 'antd';

import * as registerActions from '../../actions/registerActions';

const { Option } = Select;
const { TextArea } = Input;
const { Text } = Typography;

const layout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 20 }
};
const tailLayout = {
  wrapperCol: { offset: 4, span: 16 }
};

const prefixSelector = (
  <Form.Item name='prefix' noStyle>
    <Select style={{ width: 70 }}>
      <Option value='90'>+90</Option>
      <Option value='387'>+387</Option>
    </Select>
  </Form.Item>
);

const RegisterForm = props => {
  const onFinish = values => {
    let { prefix, confirm, ...user } = values;
    props.register(user);
  };

  return (
    <Form onFinish={onFinish} {...layout} style={{ width: '50vw' }}>
      <Form.Item
        label='Ad'
        name='name'
        rules={[
          {
            required: true
          }
        ]}
      >
        <Input placeholder='Ad' />
      </Form.Item>
      <Form.Item
        label='Soyad'
        name='surname'
        rules={[
          {
            required: true
          }
        ]}
      >
        <Input placeholder='Soyad' />
      </Form.Item>
      <Form.Item
        label='Vatandaşlık Numarası'
        name='citizenshipNumber'
        rules={[
          {
            required: true
          }
        ]}
      >
        <Input placeholder='Vatandaşlık Numarası' />
      </Form.Item>
      <Form.Item
        label='E-mail'
        name='email'
        rules={[
          {
            required: true,
            message: 'Please input your e-mail!'
          },
          { type: 'email', message: 'E-mail is not valid' }
        ]}
      >
        <Input placeholder='E-mail' />
      </Form.Item>
      <Form.Item
        name='phoneNumber'
        label='Tel No'
        rules={[{ required: true, message: 'Please input your phone number!' }]}
      >
        <Input addonBefore={prefixSelector} style={{ width: '100%' }} />
      </Form.Item>
      <Form.Item
        label='Adres'
        name='address'
        rules={[
          {
            required: true,
            message: 'Please input your address!'
          }
        ]}
      >
        <TextArea placeholder='Adres' />
      </Form.Item>
      <Form.Item
        label='Ülke'
        name='country'
        rules={[
          {
            required: true,
            message: 'Please input your country'
          }
        ]}
      >
        <Input placeholder='Ülke' />
      </Form.Item>
      <Form.Item
        label='Şehir'
        name='city'
        rules={[
          {
            required: true,
            message: 'Please input your city!'
          }
        ]}
      >
        <Input placeholder='Şehir' />
      </Form.Item>
      <Form.Item
        label='Posta Kodu'
        name='zipCode'
        rules={[
          {
            required: true,
            message: 'Please input your zip code!'
          }
        ]}
      >
        <InputNumber placeholder='Posta Kodu' />
      </Form.Item>
      <Form.Item
        label='Şifre'
        name='password'
        rules={[
          {
            required: true,
            message: 'Please input your password!'
          }
        ]}
      >
        <Input.Password placeholder='Şifre' />
      </Form.Item>
      <Form.Item
        name='confirm'
        label='Şifre Tekrar'
        dependencies={['password']}
        hasFeedback
        rules={[
          {
            required: true,
            message: 'Please confirm your password!'
          },
          ({ getFieldValue }) => ({
            validator(rule, value) {
              if (!value || getFieldValue('password') === value) {
                return Promise.resolve();
              }

              return Promise.reject(
                'The two passwords that you entered do not match!'
              );
            }
          })
        ]}
      >
        <Input.Password />
      </Form.Item>
      <Form.Item {...tailLayout}>
        <Button type='primary' htmlType='submit'>
          Kayıt Ol
        </Button>
      </Form.Item>
      {props.message.length > 0 ? (
        <Form.Item>
          <Text type='danger'>{props.message}</Text>
        </Form.Item>
      ) : null}
    </Form>
  );
};

const mapStateToPorops = state => {
  return {
    message: state.register.message
  };
};

const mapDispatchToProps = dispatch => {
  return {
    register: params => dispatch(registerActions.register(params))
  };
};

export default connect(mapStateToPorops, mapDispatchToProps)(RegisterForm);
