import React from 'react';
import { Button, Col, Row } from 'antd';

const ShortListItem = ({ file, goToFile }) => {
  return (
    <Row gutter={[24, 24]}>
      <Col sm={24} xl={6} style={{ width: '100%' }}>
        {file.vehicle.name}
      </Col>
      <Col sm={24} xl={6} style={{ width: '100%' }}>
        {file.vehicle.model}
      </Col>
      <Col sm={24} xl={6} style={{ width: '100%' }}>
        {file.vehicle.year}
      </Col>
      <Col sm={24} xl={6} style={{ width: '100%' }}>
        <Button
          block
          type='primary'
          style={{ whiteSpace: 'normal', height: 'auto', marginBottom: '10px' }}
          onClick={() => goToFile(file)}
        >
          Go to File
        </Button>
      </Col>
    </Row>
  );
};

export default ShortListItem;
