import React from 'react';
import { connect } from 'react-redux';
import { Card, Typography, Form, Input, Button } from 'antd';
import { UserOutlined } from '@ant-design/icons';

import * as authActions from '../../actions/authActions';

const { Text } = Typography;

const UserProfile = ({
  user,
  updateUserInfo,
  updatePassMessage,
  updatePassLoading,
  changePassword
}) => {
  const usernameChange = value => {
    updateUserInfo({ name: value });
  };
  const userSurnameChange = value => {
    updateUserInfo({ surname: value });
  };
  const userEmailChange = value => {
    updateUserInfo({ email: value });
  };
  const userPhoneChange = value => {
    updateUserInfo({ phoneNumber: value });
  };
  const onFinish = values => {
    const { confirm, ...value } = values;
    changePassword(value);
  };

  return (
    <Card
      title={
        <Text strong>
          <UserOutlined /> User Profile
        </Text>
      }
      style={{ minHeight: '77vh' }}
    >
      <Form wrapperCol={{ span: 18 }} labelCol={{ span: 3 }}>
        <Form.Item label='Name'>
          <Text
            style={{ width: '100%' }}
            editable={{ onChange: usernameChange }}
          >
            {user.name}
          </Text>
        </Form.Item>
        <Form.Item label='Surname'>
          <Text editable={{ onChange: userSurnameChange }}>{user.surname}</Text>
        </Form.Item>
        <Form.Item label='Email'>
          <Text editable={{ onChange: userEmailChange }}>{user.email}</Text>
        </Form.Item>
        <Form.Item label='Phone Number'>
          <Text editable={{ onChange: userPhoneChange }}>
            {user.phoneNumber}
          </Text>
        </Form.Item>
      </Form>
      <Form
        onFinish={onFinish}
        wrapperCol={{ span: 18 }}
        labelCol={{ span: 3 }}
      >
        <Form.Item
          label='Old Password'
          name='oldPassword'
          rules={[
            {
              required: true,
              message: 'Please input your password!'
            }
          ]}
        >
          <Input.Password placeholder='Old Password' />
        </Form.Item>
        <Form.Item
          label='Change Password'
          name='password'
          rules={[
            {
              required: true,
              message: 'Please input your password!'
            }
          ]}
        >
          <Input.Password placeholder='New Password' />
        </Form.Item>
        <Form.Item
          name='confirm'
          label='Re-enter Password'
          dependencies={['password']}
          hasFeedback
          rules={[
            {
              required: true,
              message: 'Please confirm your password!'
            },
            ({ getFieldValue }) => ({
              validator(rule, value) {
                if (!value || getFieldValue('password') === value) {
                  return Promise.resolve();
                }

                return Promise.reject(
                  'The two passwords that you entered do not match!'
                );
              }
            })
          ]}
        >
          <Input.Password placeholder='Re-enter Password' />
        </Form.Item>
        <Form.Item wrapperCol={{ offset: 3, span: 18 }}>
          <Button type='primary' htmlType='submit' loading={updatePassLoading}>
            Submit
          </Button>
        </Form.Item>
        {updatePassMessage ? (
          <Form.Item wrapperCol={{ offset: 3, span: 18 }}>
            <Text type='danger'>{updatePassMessage}</Text>
          </Form.Item>
        ) : null}
      </Form>
    </Card>
  );
};

const mapStateToProps = state => {
  return {
    user: state.auth.user,
    updatePassMessage: state.auth.updatePassMessage,
    updatePassLoading: state.auth.updatePassLoading
  };
};

const mapDispatchToProps = dispatch => {
  return {
    updateUserInfo: data => dispatch(authActions.updateUserInfo(data)),
    changePassword: values => dispatch(authActions.changePassword(values))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UserProfile);
