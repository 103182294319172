import * as searchVehicleActions from '../../actions/searchVehicleActions';

const defaultState = {
  selectedVehicle: null,
  vehicleNames: [],
  models: [],
  years: [],
  engineTypes: [],
  motorTypes: [],
  allVehicles: []
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = defaultState, action) => {
  switch (action.type) {
    case searchVehicleActions.GET_VEHICLE_NAMES:
      return { ...state, ...action.payload };
    case searchVehicleActions.GET_VEHICLE_MODELS:
      return { ...state, ...action.payload };
    case searchVehicleActions.GET_VEHICLE_YEARS:
      return { ...state, ...action.payload };
    case searchVehicleActions.GET_VEHICLE_ENGINE_TYPES:
      return { ...state, ...action.payload };
    case searchVehicleActions.GET_VEHICLE_MOTOR_TYPES:
      return { ...state, ...action.payload };
    case searchVehicleActions.SET_SELECTED:
      return {
        ...state,
        selectedVehicle: { ...action.payload.selectedVehicle }
      };
    case searchVehicleActions.SET_ALL_VEHICLES:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};
