import * as creditsActions from '../../actions/creditsActions';

const defaultState = {
  credits: [],
  creditsForBuy: [],
  failMessage: '',
  discount: 0,
  checkoutFormContent: '',
  div: '',
  buyLoading: false,
  selectedCredit: null,
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = defaultState, action) => {
  switch (action.type) {
    case creditsActions.SET_CREDITS:
      return { ...state, ...action.payload };
    case creditsActions.SET_CREDITS_FOR_BUY:
      return { ...state, creditsForBuy: action.payload.credits };
    case creditsActions.BUY_FAILED:
      return { ...state, ...action.payload };
    case creditsActions.SET_USER_DISCOUNT:
      return { ...state, ...action.payload };
    case creditsActions.SET_PAYMENT_CONTENT:
      return { ...state, ...action.payload };
    case creditsActions.BUY_CREDIT_LOADING:
      return { ...state, buyLoading: action.payload.state };
    case creditsActions.SET_SELECTED_CREDIT:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};
