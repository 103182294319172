import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Layout, Menu } from 'antd';
import { isMobile } from 'react-device-detect';
import {
  contentManagerRoutes,
  fileServiceRoutes,
  fileServiceAdminRoutes,
  myCreditsRoutes,
  userManagementRoutes,
  userProfileRoutes,
  transactionsRoutes,
  dashboardRoutes
} from '../../accountRoutes';
import SubMenu from 'antd/lib/menu/SubMenu';

import * as sidebarActions from '../../actions/sidebarActions';

const { Sider } = Layout;

const SideBar = ({ user, selectedMenuKey, setSelectedMenuKey }) => {
  const [collapsed, setCollapsed] = useState(false);

  const onCollapse = collapsed => {
    setCollapsed(collapsed);
  };

  const renderMenuItems = () => {
    let routes = [];
    if (user.role !== '') {
      if (user.role === 'user') {
        routes = [
          dashboardRoutes.filter(f => f.isMenuItem === true),
          fileServiceRoutes.filter(f => f.isMenuItem === true),
          myCreditsRoutes.filter(f => f.isMenuItem === true),
          transactionsRoutes.filter(f => f.isMenuItem === true),
          userProfileRoutes.filter(f => f.isMenuItem === true)
        ];
      } else if (user.role === 'admin') {
        routes = [
          dashboardRoutes.filter(f => f.isMenuItem === true),
          contentManagerRoutes.filter(f => f.isMenuItem === true),
          fileServiceAdminRoutes.filter(f => f.isMenuItem === true),
          transactionsRoutes.filter(f => f.isMenuItem === true),
          userProfileRoutes.filter(f => f.isMenuItem === true)
        ];
      } else if (user.role === 'mapper') {
        routes = [
          dashboardRoutes.filter(f => f.isMenuItem === true),
          fileServiceAdminRoutes.filter(f => f.isMenuItem === true),
          transactionsRoutes.filter(f => f.isMenuItem === true),
          userProfileRoutes.filter(f => f.isMenuItem === true),
          myCreditsRoutes.filter(f => f.isMenuItem === true)
        ];
      } else if (user.role === 'sysAdmin') {
        routes = [
          dashboardRoutes.filter(f => f.isMenuItem === true),
          contentManagerRoutes.filter(f => f.isMenuItem === true),
          fileServiceAdminRoutes.filter(f => f.isMenuItem === true),
          userManagementRoutes.filter(f => f.isMenuItem === true),
          transactionsRoutes.filter(f => f.isMenuItem === true),
          userProfileRoutes.filter(f => f.isMenuItem === true)
        ];
      }
      const routesSubMenu = routes.map(item => (
        <SubMenu
          key={item[0].parentMenuName}
          title={
            <span>
              {item[0].subMenuIcon}
              <span>{item[0].parentMenuName}</span>
            </span>
          }
          defaultOpenKeys={[item[0].key]}
        >
          {item.map(item => {
            return (
              <Menu.Item key={item.key}>
                {item.icon}
                <span>{item.name}</span>
              </Menu.Item>
            );
          })}
        </SubMenu>
      ));

      return routesSubMenu;
    } else {
      return null;
    }
  };

  const handleMenuItemCLick = e => {
    setSelectedMenuKey(e.key);
  };

  return (
    <Sider
      breakpoint='lg'
      collapsible={isMobile ? false : true}
      collapsed={collapsed}
      onCollapse={onCollapse}
      width='15vw'
      style={{ minHeight: '100vh' }}
    >
      <div className='logo' />
      <Menu
        selectedKeys={[selectedMenuKey]}
        defaultOpenKeys={[
          'Dashboard',
          'ContentManagement',
          'FileService',
          'MyCredits',
          'UserManagement',
          'Transactions',
          'UserProfile'
        ]}
        theme='dark'
        defaultSelectedKeys={[selectedMenuKey]}
        mode='inline'
        onClick={handleMenuItemCLick}
      >
        {renderMenuItems()}
      </Menu>
    </Sider>
  );
};

const mapStateToProps = state => {
  return {
    user: state.auth.user,
    selectedMenuKey: state.sidebar.selectedMenuKey
  };
};

const mapDispatchToProps = dispatch => {
  return {
    setSelectedMenuKey: key => dispatch(sidebarActions.setSelectedMenuKey(key))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SideBar);
