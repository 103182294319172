import * as userActions from '../../actions/userActions';

const defaultState = {
  transactions: [],
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = defaultState, action) => {
  switch (action.type) {
    case userActions.SET_TRANSACTIONS:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};
