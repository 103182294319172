import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Card, Typography } from 'antd';
import { WalletOutlined } from '@ant-design/icons';

import CommonTable from '../../components/CommonTable';

import { formattedColumns } from '../../utils/commonUtils';
import { transactionsColumns } from '../../constants';

import * as userActions from '../../actions/userActions';

const { Text } = Typography;

const Transactions = ({
  user,
  selectedUser,
  transactions,
  getTransactions
}) => {
  useEffect(() => {
    async function loadTransactions() {
      getTransactions(user, selectedUser);
    }
    loadTransactions();
  }, []);

  return (
    <Card
      title={
        <Text strong>
          <WalletOutlined /> Transactions
        </Text>
      }
      style={{ minHeight: '77vh' }}
    >
      <CommonTable
        dataSource={transactions}
        columns={formattedColumns(transactionsColumns)}
        rowSelection={null}
      />
    </Card>
  );
};

const mapStateToProps = state => {
  return {
    selectedUser: state.user.selectedUser,
    user: state.auth.user,
    transactions: state.transactions.transactions
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getTransactions: (user, selectedUser) =>
      dispatch(userActions.getTransactions(user, selectedUser)),
    handleRefund: (id, refundAmount) =>
      dispatch(userActions.handleRefund(id, refundAmount))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Transactions);
