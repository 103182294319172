export const SET_SETMASTERSLAVE = 'SET_SETMASTERSLAVE';
export const SET_SETREADMETHOD = 'SET_SETREADMETHOD';
export const SET_SETHADNWARENUMBER = 'SET_SETHADNWARENUMBER';
export const SET_SETSOFTWARENUMBER = 'SET_SETSOFTWARENUMBER';
export const SET_DESCRIPTION = 'SET_DESCRIPTION';
export const CLEAR_TUNING_INFO = 'CLEAR_TUNING_INFO';

export const setMasterSlave = masterSlave => {
  return { type: SET_SETMASTERSLAVE, payload: { masterSlave } };
};
export const setReadmethod = readmethodId => {
  return { type: SET_SETREADMETHOD, payload: { readmethodId } };
};
export const setHadnwareNumber = hardwareNumber => {
  return { type: SET_SETHADNWARENUMBER, payload: { hardwareNumber } };
};
export const setSoftwareNumber = softwareNumber => {
  return { type: SET_SETSOFTWARENUMBER, payload: { softwareNumber } };
};
export const setDescription = description => {
  return { type: SET_DESCRIPTION, payload: { description } };
};

export const clearTuningInfo = () => {
  return { type: CLEAR_TUNING_INFO };
};
