import * as fileService from '../api/service/fileService';
import { logout } from './authActions';
import { setSelectedMenuKey } from './sidebarActions';

export const SET_USER_FILES = 'SET_USER_FILES';
export const SET_SELECTED_FILE = 'SET_SELECTED_FILE';
export const SET_USER_COMMENTS = 'SET_USER_COMMENTS';
export const ADD_COMMENT = 'ADD_COMMENT';
export const GET_HANDLERS_SUCCESS = 'GET_HANDLERS_SUCCESS';
export const GET_HANDLERS_FAIL = 'GET_HANDLERS_FAIL';
export const ASSIGN_FILE_SUCCESS = 'ASSIGN_FILE_SUCCESS';
export const ASSIGN_FILE_FAIL = 'ASSIGN_FILE_FAIL';
export const UPLOAD_TUNED_FILE_SUCCESS = 'UPLOAD_TUNED_FILE_SUCCESS';
export const UPLOAD_TUNED_FILE_FAIL = 'UPLOAD_TUNED_FILE_FAIL';
export const SET_LOADING_STATUS = 'SET_LOADING_STATUS';
export const FILE_CLOSE_SUCCESS = 'FILE_CLOSE_SUCCESS';
export const FILE_CLOSE_FAIL = 'FILE_CLOSE_FAIL';
export const CLOSE_FILE_LOADING = 'CLOSE_FILE_LOADING';
export const GET_LATEST_FILE_SUCCESS = 'GET_LATEST_FILE_SUCCESS';
export const GET_LATEST_FILES_FAIL = 'GET_LATEST_FILES_FAIL';

export const setUserFiles = files => {
  return { type: SET_USER_FILES, payload: { files } };
};

export const setSelectedFile = file => {
  return { type: SET_SELECTED_FILE, payload: { file } };
};

export const setComments = comments => {
  return { type: SET_USER_COMMENTS, payload: { comments } };
};

export const addComment = comment => {
  return { type: ADD_COMMENT, payload: { comment } };
};

export const getHandlersSuccess = handlers => {
  return { type: GET_HANDLERS_SUCCESS, payload: { handlers } };
};

export const getHandlersfail = msg => {
  return { type: GET_HANDLERS_FAIL, payload: { msg } };
};

export const assignFileSuccess = file => {
  return { type: ASSIGN_FILE_SUCCESS, payload: { file } };
};

export const assignFileFail = msg => {
  return { type: ASSIGN_FILE_FAIL, payload: { msg } };
};

export const uploadTunedFileSuccess = file => {
  return { type: UPLOAD_TUNED_FILE_SUCCESS, payload: { file } };
};

export const uploadTunedFileFail = msg => {
  return { type: UPLOAD_TUNED_FILE_FAIL, payload: { msg } };
};

export const setLoadingStatus = status => {
  return { type: SET_LOADING_STATUS, payload: { status } };
};

export const fileClosedSuccess = () => {
  return { type: FILE_CLOSE_SUCCESS };
};

export const fileClosedFail = msg => {
  return { type: FILE_CLOSE_FAIL, payload: { msg } };
};

export const closeFileLoading = status => {
  return { type: CLOSE_FILE_LOADING, payload: { status } };
};

export const getLatestFilesSuccess = latestFiles => {
  return { type: GET_LATEST_FILE_SUCCESS, payload: { latestFiles } };
};

export const getLatestFilesFail = () => {
  return { type: GET_LATEST_FILES_FAIL };
};

export const getAllFiles = () => {
  return async dispatch => {
    const result = await fileService.listAll();
    if (result.status < 400) {
      dispatch(setUserFiles(result.data));
    } else if (result.status === 401) {
      dispatch(logout());
    }
  };
};

export const getUserFiles = currentUserId => {
  return async dispatch => {
    const result = await fileService.listAll();
    if (result.status < 400) {
      dispatch(
        setUserFiles(
          result.data.filter(
            f => f.userId === currentUserId || f.handlerId === currentUserId
          )
        )
      );
    } else if (result.status === 401) {
      dispatch(logout());
    }
  };
};

export const handleDownload = record => {
  return async dispatch => {
    const result = await fileService.download(record.fileName);
    if (result.status === 401) {
      dispatch(logout());
    } else if (result.status < 400) {
      const url = window.URL.createObjectURL(new Blob([result.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', record.fileName);
      document.body.appendChild(link);
      link.click();
    }
  };
};

export const handleDownloadTuned = record => {
  return async dispatch => {
    const result = await fileService.download(record.tunedFileName);
    if (result.status === 401) {
      dispatch(logout());
    } else if (result.status < 400) {
      const url = window.URL.createObjectURL(new Blob([result.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', record.tunedFileName);
      document.body.appendChild(link);
      link.click();
    }
  };
};

export const getComments = id => {
  return async dispatch => {
    const result = await fileService.getUserComments(id);
    if (result.status < 400) {
      dispatch(setComments(result.data));
    } else if (result.status === 401) {
      dispatch(logout());
    }
  };
};

export const handleSupport = record => {
  return dispatch => {
    dispatch(setSelectedFile(record));
    dispatch(getComments(record.id));
    dispatch(setSelectedMenuKey('fileSupport'));
  };
};

export const sendComment = (values, fileServiceId) => {
  return async dispatch => {
    const result = await fileService.sendComment({ ...values, fileServiceId });
    if (result.status < 400) {
      dispatch(addComment(result.data));
    } else if (result.status === 200) {
      dispatch(logout());
    }
  };
};

export const getAllHandlers = () => {
  return async dispatch => {
    try {
      const result = await fileService.getAllHandlers();
      if (result.status === 401) {
        dispatch(logout());
      } else if (result.status < 400) {
        dispatch(getHandlersSuccess(result.data));
      } else {
        dispatch(getHandlersfail(result.data.message));
      }
    } catch (error) {
      dispatch(getHandlersfail(error));
    }
  };
};

export const assignFile = (handlerId, fileServiceId) => {
  return async dispatch => {
    try {
      const result = await fileService.assignFile(handlerId, fileServiceId);
      if (result.status === 401) {
        dispatch(logout());
      } else if (result.status < 400) {
        dispatch(assignFileSuccess(result.data.file));
        dispatch(getHandlersSuccess(result.data.handlers));
      } else {
        dispatch(assignFileFail(result.data.message));
      }
    } catch (error) {
      dispatch(assignFileFail(error));
    }
  };
};

export const uploadTunedFile = (id, file) => {
  return async dispatch => {
    try {
      dispatch(setLoadingStatus(true));
      const result = await fileService.uploadTunedFile(id, file);
      if (result.status === 401) {
        dispatch(logout());
      } else if (result.status < 400) {
        dispatch(uploadTunedFileSuccess(result.data));
      } else {
        dispatch(uploadTunedFileFail(result.data.message));
      }
    } catch (error) {
      dispatch(uploadTunedFileFail(error));
    } finally {
      dispatch(setLoadingStatus(false));
    }
  };
};

export const closeFile = fileId => {
  return async dispatch => {
    try {
      dispatch(closeFileLoading(true));
      const result = await fileService.closeFile(fileId);
      if (result.status === 401) {
        dispatch(logout());
      } else if (result.status < 400) {
        dispatch(fileClosedSuccess());
        dispatch(setSelectedMenuKey('fileSupport'));
      } else {
        dispatch(fileClosedFail(result.data.message));
      }
    } catch (error) {
      dispatch(fileClosedFail(error));
    } finally {
      dispatch(closeFileLoading(false));
    }
  };
};

export const getLatestFiles = () => {
  return async dispatch => {
    try {
      const result = await fileService.getLatestFiles();
      if (result.status < 400) {
        dispatch(getLatestFilesSuccess(result.data));
      } else {
        dispatch(getLatestFilesFail());
      }
    } catch (error) {
      dispatch(getLatestFilesFail());
    }
  };
};
