import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import {
  Button,
  Card,
  Col,
  Input,
  InputNumber,
  Row,
  Typography,
  Form,
  Collapse
} from 'antd';
import { UnorderedListOutlined } from '@ant-design/icons';

import Transactions from '../Transactions';
import FileList from '../FileList';

import AssignRole from '../../components/AssignRole';
import ChangeUserStatus from '../../components/ChangeUserStatus';

import * as userActions from '../../actions/userActions';
import ChangeMapperPriority from '../../components/ChangeMapperPriority';

const { Text, Title } = Typography;
const { Panel } = Collapse;

const layout = {
  labelCol: { span: 3 },
  wrapperCol: { span: 18 }
};

const UserDetails = ({
  selectedUser,
  roles,
  message,
  isErr,
  setDiscount,
  handleRefund,
  getAllRoles,
  handleClaim,
  assignRole,
  changeStatus,
  setSelectedUser,
  handleChangeMapperPriority
}) => {
  useEffect(() => {
    getAllRoles();
    return () => setSelectedUser(null);
  }, [setSelectedUser]);

  const handleSubmitClick = values => {
    setDiscount({ userId: selectedUser.id, ...values });
  };

  const onRefundClick = values => {
    handleRefund({ id: selectedUser.id, ...values });
  };

  const onClaimClick = values => {
    handleClaim({ id: selectedUser.id, ...values });
  };

  const onChangeMapperPriority = value => {
    handleChangeMapperPriority({ id: selectedUser.id, priority: value });
  };

  return (
    <Card
      title={
        <Text strong>
          <UnorderedListOutlined /> User Mangement Detaıls
        </Text>
      }
      style={{ minHeight: '77vh' }}
    >
      <Input.Group compact>
        <Form.Item label='Name' style={{ width: '25%' }}>
          <Text>{selectedUser.name}</Text>
        </Form.Item>
        <Form.Item label='Surname' style={{ width: '25%' }}>
          <Text>{selectedUser.surname}</Text>
        </Form.Item>
        <Form.Item label='Email' style={{ width: '25%' }}>
          <Text>{selectedUser.email}</Text>
        </Form.Item>
        <Form.Item label='Status' style={{ width: '25%' }}>
          <Text>{selectedUser.status}</Text>
        </Form.Item>
      </Input.Group>
      <Input.Group compact>
        <Form.Item label='Role' style={{ width: '25%' }}>
          <Text>{selectedUser.role.name}</Text>
        </Form.Item>
        <Form.Item label='Credit' style={{ width: '25%' }}>
          <Text>{selectedUser.credit.credit}</Text>
        </Form.Item>
        <Form.Item label='Discount' style={{ width: '25%' }}>
          <Text>{selectedUser.discount.discount}</Text>
        </Form.Item>
        {selectedUser.role.id === 4 ? (
          <Form.Item label='Priority' style={{ width: '25%' }}>
            <Text
              type={selectedUser.priority === 'PRIOR' ? 'success' : 'warning'}
            >
              {selectedUser.priority}
            </Text>
          </Form.Item>
        ) : null}
      </Input.Group>
      <Title strong level={4} style={{ marginBottom: '2vh' }}>
        Operations
      </Title>
      <Row>
        <Col span={12}>
          <Form {...layout} name='setDiscount' onFinish={handleSubmitClick}>
            <Form.Item
              name='discountAmount'
              label='Discount'
              rules={[
                {
                  type: 'number',
                  min: 1,
                  max: 99,
                  required: true,
                  message: 'Discount should be between 1 to 99'
                },
                {
                  required: true,
                  message: 'Discount is Required'
                }
              ]}
            >
              <InputNumber
                placeholder='Discount Amount (%)'
                style={{ width: '100%' }}
              />
            </Form.Item>
            <Form.Item
              wrapperCol={{
                offset: 3,
                span: 18
              }}
            >
              <Button type='primary' htmlType='submit'>
                Submit
              </Button>
              {message ? (
                <Text
                  style={{ marginLeft: '1vw' }}
                  type={isErr ? 'danger' : 'success'}
                >
                  {message}
                </Text>
              ) : null}
            </Form.Item>
          </Form>
        </Col>
        <Col span={12}>
          <Form {...layout} name='setRefund' onFinish={onRefundClick}>
            <Form.Item
              name='refundAmount'
              label='Refund'
              rules={[
                {
                  type: 'number',
                  min: 1,
                  required: true,
                  message: 'Discount should be greater than 1'
                },
                {
                  required: true,
                  message: 'Refund is Required'
                }
              ]}
            >
              <InputNumber placeholder='Refund' style={{ width: '100%' }} />
            </Form.Item>
            <Form.Item
              name='description'
              label='Description'
              rules={[
                {
                  required: true,
                  message: 'Description is Required'
                }
              ]}
            >
              <Input placeholder='Description' />
            </Form.Item>
            <Form.Item
              wrapperCol={{
                offset: 3,
                span: 18
              }}
            >
              <Button type='primary' htmlType='submit'>
                Submit
              </Button>
              {message ? (
                <Text
                  style={{ marginLeft: '1vw' }}
                  type={isErr ? 'danger' : 'success'}
                >
                  {message}
                </Text>
              ) : null}
            </Form.Item>
          </Form>
        </Col>
        <Col span={12}>
          <Form {...layout} name='setClaim' onFinish={onClaimClick}>
            <Form.Item
              name='claimAmount'
              label='Claim'
              rules={[
                {
                  type: 'number',
                  min: 1,
                  required: true,
                  message: 'Claim should be greater than 1'
                },
                {
                  required: true,
                  message: 'Claim is Required'
                }
              ]}
            >
              <InputNumber placeholder='Claim' style={{ width: '100%' }} />
            </Form.Item>
            <Form.Item
              name='description'
              label='Description'
              rules={[
                {
                  required: true,
                  message: 'Description is Required'
                }
              ]}
            >
              <Input placeholder='Description' />
            </Form.Item>
            <Form.Item
              wrapperCol={{
                offset: 3,
                span: 18
              }}
            >
              <Button type='primary' htmlType='submit'>
                Submit
              </Button>
              {message ? (
                <Text
                  style={{ marginLeft: '1vw' }}
                  type={isErr ? 'danger' : 'success'}
                >
                  {message}
                </Text>
              ) : null}
            </Form.Item>
          </Form>
        </Col>
        <Col span={12}>
          <Row gutter={[8, 8]}>
            <Col span={3}>
              <Text>Assign Role</Text>
            </Col>
            <Col span={18}>
              <AssignRole
                roles={roles}
                record={selectedUser}
                assignRole={(userId, roleId) => assignRole(userId, roleId)}
              />
            </Col>
          </Row>
          <Row gutter={[8, 8]}>
            <Col span={3}>
              <Text>Change Status</Text>
            </Col>
            <Col span={18}>
              <ChangeUserStatus
                changeStatus={status => changeStatus(selectedUser.id, status)}
              />
            </Col>
          </Row>
        </Col>
        {selectedUser.role.id === 4 ? (
          <Col span={12}>
            <Row gutter={[8, 8]}>
              <Col span={3}>
                <Text>Priority</Text>
              </Col>
              <Col span={18}>
                <ChangeMapperPriority
                  onChangeMapperPriority={value =>
                    onChangeMapperPriority(value)
                  }
                />
              </Col>
            </Row>
          </Col>
        ) : null}
      </Row>
      <Collapse>
        <Panel header='Transactions' key='transactions'>
          <Transactions />
        </Panel>
        <Panel header='Files' key='files'>
          <FileList isDetailPage currentUserId={selectedUser.id} />
        </Panel>
      </Collapse>
    </Card>
  );
};

const mapStateToPorops = state => {
  return {
    selectedUser: state.user.selectedUser,
    roles: state.user.roles,
    message: state.user.message,
    isErr: state.user.isErr
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getAllRoles: () => dispatch(userActions.getAllRoles()),
    setDiscount: data => dispatch(userActions.setDiscount(data)),
    handleRefund: data => dispatch(userActions.handleRefund(data)),
    handleClaim: data => dispatch(userActions.handleClaim(data)),
    assignRole: (userId, fileServiceId) =>
      dispatch(userActions.assignRole(userId, fileServiceId)),
    changeStatus: (userId, status) =>
      dispatch(userActions.changeStatus(userId, status)),
    setSelectedUser: user => dispatch(userActions.setSelectedUser(user)),
    handleChangeMapperPriority: data =>
      dispatch(userActions.handleChangeMapperPriority(data))
  };
};

export default connect(mapStateToPorops, mapDispatchToProps)(UserDetails);
