import React from 'react';
import { Card, Typography, List, Comment } from 'antd';
import { CommentOutlined } from '@ant-design/icons';

const { Text } = Typography;

const CommentList = props => {
  return (
    <Card
      style={{ marginBottom: '2vh' }}
      title={
        <Text strong>
          <CommentOutlined /> Comment List
        </Text>
      }
    >
      <List
        className='comment-list'
        header={`${props.comments.length} replies`}
        itemLayout='horizontal'
        dataSource={props.comments}
        renderItem={item => (
          <li>
            <Comment
              author={`${item.user.name} ${item.user.surname}`}
              avatar='https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png'
              content={
                <React.Fragment>
                  <div>
                    <Text strong>{item.title}</Text>
                  </div>
                  <div>{item.comment}</div>
                </React.Fragment>
              }
              datetime={new Date(item.createdAt).toLocaleString()}
            />
          </li>
        )}
      />
    </Card>
  );
};

export default CommentList;
