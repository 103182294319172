import React from 'react';
import { Table, Button, Input } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';

class CommonTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      searchText: '',
      searchedColumn: ''
    };
  }

  getColumnSearchProps = (dataIndex, key) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={node => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            this.handleSearch(selectedKeys, confirm, dataIndex)
          }
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type='primary'
          onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined />}
          size='small'
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button
          onClick={() => this.handleReset(clearFilters)}
          size='small'
          style={{ width: 90 }}
        >
          Reset
        </Button>
      </div>
    ),
    filterIcon: filtered => (
      <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) => {
      if (typeof record[dataIndex] === 'object') {
        return record[dataIndex][key]
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase());
      }
      return record[dataIndex]
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase());
    },
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: (text, record) => {
      let item;
      let tmpText;
      if (typeof text === 'object' && text !== null) {
        tmpText = text[key];
      } else {
        tmpText = text;
      }
      const selectedColumn = this.props.columns.find(
        f => f.dataIndex === dataIndex
      );
      if (this.state.searchedColumn === dataIndex) {
        console.log(this.props.columns);
        item = (
          <Highlighter
            highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
            searchWords={[this.state.searchText]}
            autoEscape
            textToHighlight={
              selectedColumn.render
                ? selectedColumn.render(tmpText.toString(), record)
                : tmpText.toString()
            }
          />
        );
      } else {
        item = selectedColumn.render
          ? selectedColumn.render(tmpText, record)
          : tmpText;
      }
      return item;
    }
  });

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex
    });
  };

  handleReset = clearFilters => {
    clearFilters();
    this.setState({ searchText: '' });
  };

  render() {
    const { columns, dataSource, rowSelection } = this.props;
    const formattedColumns = columns.map(col => {
      if (col.search) {
        return { ...col, ...this.getColumnSearchProps(col.dataIndex, col.key) };
      }
      return {
        ...col
      };
    });
    return (
      <Table
        pagination={{ hideOnSinglePage: true }}
        columns={formattedColumns}
        dataSource={dataSource}
        rowSelection={rowSelection}
      />
    );
  }
}

export default CommonTable;
