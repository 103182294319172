import React from 'react';
import { Layout } from 'antd';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import AccountContent from '../../components/AccountContent';
import SideBar from '../../components/SideBar';

const Home = () => {
  let { path } = useRouteMatch();

  return (
    <Layout>
      <SideBar />
      <Switch>
        <Route exact path={path} component={AccountContent} />
      </Switch>
    </Layout>
  );
};

export default Home;
