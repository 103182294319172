import React from 'react';
import { connect } from 'react-redux';
import { Card, Typography, Button } from 'antd';
import { EditOutlined, UnorderedListOutlined } from '@ant-design/icons';

import CommonTable from '../../components/CommonTable';

import { formattedColumns } from '../../utils/commonUtils';
import { vehicleColumns } from '../../constants';

import * as vehicleListActions from '../../actions/vehicleListActions';

const { Text } = Typography;

class VehicleList extends React.Component {
  constructor(props) {
    super(props);
    this.state = { disabled: true };
  }
  componentDidMount() {
    this.props.getVehicles();
  }

  rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      this.setState(() => {
        if (selectedRowKeys.length < 1) {
          return { disabled: true };
        } else {
          return { disabled: false };
        }
      });
      this.setState({ selectedRowKeys });
    },
    getCheckboxProps: record => ({
      disabled: record.name === 'Disabled User', // Column configuration not to be checked
      name: record.name
    })
  };

  handleDelete = () => {
    this.props.handleDelete(this.state.selectedRowKeys);
  };
  renderColumnActions = () => {
    return {
      render: (text, record) => (
        <Button
          record={record}
          type='primary'
          block
          icon={<EditOutlined />}
          onClick={() => this.props.handleEdit(record)}
        >
          Edit
        </Button>
      )
    };
  };

  render() {
    return (
      <Card
        title={
          <Text strong>
            <UnorderedListOutlined /> List Vehicle
          </Text>
        }
        style={{ minHeight: '77vh' }}
      >
        <CommonTable
          dataSource={this.props.vehicles}
          columns={formattedColumns(vehicleColumns, {
            ...this.renderColumnActions()
          })}
          rowSelection={this.rowSelection}
        />
        <Button
          style={{ marginTop: '1vh' }}
          type='primary'
          disabled={this.state.disabled}
          onClick={this.handleDelete}
        >
          Delete
        </Button>
      </Card>
    );
  }
}

const mapStateToProps = state => {
  return {
    vehicles: state.listVehicle.vehicles
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getVehicles: () => dispatch(vehicleListActions.getVehicles()),
    handleDelete: selectedRowKeys =>
      dispatch(vehicleListActions.handleDelete(selectedRowKeys)),
    handleEdit: record => dispatch(vehicleListActions.handleEdit(record))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(VehicleList);
