import { useEffect } from 'react';
import { connect } from 'react-redux';

const PaymentPage = ({ checkoutFormContent, div }) => {
  useEffect(() => {
    const script = document.createElement('script');
    script.async = true;
    const formattedScript = checkoutFormContent.substring(
      31,
      checkoutFormContent.length - 9
    );
    script.innerHTML = formattedScript;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, [checkoutFormContent]);
  return <div dangerouslySetInnerHTML={{ __html: div }}></div>;
};

const mapStateToProps = state => {
  return {
    checkoutFormContent: state.credits.checkoutFormContent,
    div: state.credits.div,
  };
};

const mapDispatchToProps = dispatch => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(PaymentPage);
